import React from "react";

function ServiceItems() {
  return (
    <section className="section-drak-gray pt-5 pb-5">
      <div className="container">
        <div
          className="row section-title-top mb-5 pt-5 justify-content-center"
          data-aos="fade-down"
        >
          <div className="col-lg-12 text-center">
            <h2>
              <span className="highlight-orange">
                Custom Software Development
              </span>{" "}
              Services
            </h2>
            <p className="color-7">
              Doodle Inc. is providing efficient custom software development
              services for over a decade. No matter how unique your requirement
              is, we got you covered. With our next-generation technologies, we
              will build, redesign, and integrate your software solutions
              dedicating your business needs. Our elite team can help your
              business to flourish in this transformative market.
            </p>
          </div>
        </div>
        <div className="row ml-0 mr-0 services-boxs mb-5">
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
          <div className="box-contents">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Enterprise Solutions</span>Optimize your business by
              utilizing our enterprise solutions. Our enterprise software
              development services will cover all your business functions. From
              simple inventory, HR, CRM, accounting to complex e-commerce, or
              anything you need. Our innovative software solutions can take your
              enterprise to the next level.
            </p>
            <span className="single-top-border"></span>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
          <div className="box-contents">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Software Product Development</span>Doodle Inc. helps you to
              bloom your idea into a robust product. Analyzation, building,
              designing, and releasing a final product to market, every part of
              the development process will be focusing on your requirement. We
              can ship your ideas to market and make it a success.
            </p>
            <span className="single-top-border"></span>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
          <div className="box-contents">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Software Modernization</span>In this era of technology, it's
              high time to modernize your legacy applications. Maximize your
              business flexibility and minimize cost by modernizing your old
              software. Doodle Inc. can lower your operation cost, risk factors,
              and present you with next-generation software solutions.
            </p>
            <span className="single-top-border"></span>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
          <div className="box-contents">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Custom Web Application Development</span>Doodle Inc. helps
              you to create web applications that will serve your business
              goals. Elegant design, clean code, the quality architecture of our
              experts will give you amazing web applications. We aim to provide
              the best user experience and client satisfaction.
            </p>
            <span className="single-top-border"></span>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
          <div className="box-contents">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Software Testing & Quality Assurance</span>Ensuring the
              success of your product is our task. We have vast experience of
              testing and quality assurance. Our experts will rigorously analyze
              your business and ensure your product matches the requirement.
              Eliminate defects and imperfections, ship a quality product into
              the market with Doodle Inc.
            </p>
            <span className="single-top-border"></span>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
          <div className="box-contents">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Software as a Service-SaaS</span>Doodle Inc. can build your
              SaaS products and ensure you a chunk of benefit. Our SaaS products
              will be containing your business requirements. Productize your
              idea with Doodle Inc.
            </p>
            <span className="single-top-border"></span>
            </div>
          </div>
        </div>
      </div>
  </section>
  );
}
export default ServiceItems;
