const homeReducerDefaultState = {
    main:[],cat:[],blog:[],totalcat:[]
};

export default (state = homeReducerDefaultState, action) => {
    //console.log('asdfas',action,state);
    switch (action.type) {
        case 'HOMEPAGE':
            return {
                // ...state,
                main: action.homeData
            }
        case 'CATDATA':
        return {
            // ...state,
            cat: action.catData
        }
        case 'BlOGDATA':
        return {
            // ...state,
            blog: action.blogData
        }
        case 'BlOGDETAILS':
        return {
            // ...state,
            blogdetails: action.blogData
        }
        case 'BlOGMETATAG':
        return {
            ...state,
            blogmetatag: action.blogData
        }
        case 'BlOGCATEGORY':
        return {
            ...state,
            blogcategory: action.blogData
        }
        case 'TOTALCATDATA':
        return {
            // ...state,
            totalcat: action.sumCat
        }
        default:
            return state;    
    }
}
