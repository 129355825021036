import React, { Fragment } from "react";
import { connect } from "react-redux";
import SideVideo from "./video/SideVideo";
import HeadlineSlider from "./slider/HeadlineSlider";
import MenuSlider from "./slider/MenuSlider";
import HomeLeftSlider from "./slider/HomeLeftSlider";
import { GetHomeEn, GetHomeBn } from "./../actions/homeAction";
import AOS from "aos";
import Newsletter from "./newsletter/Newsletter";
import Footer from "./Footer";
import UsesTechnologies from "./common/UsesTechnologies";

class Technology extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoName: "",
      beans: [
        {
          title: "",
          tech: "",
        },
      ],
    };
  }
  componentDidMount = () => {
    // var element = document.getElementById("blogPage");
    // element.classList.remove("active");
    document.title = "Technology"
    this.props.GetHomeEn().then((res) => {
      //console.log(res[0].emp_name);
    });
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  };
  render() {
    return (
      <div>
        {/*-Banner section-*/}
        <section
          className="banner"
          style={{
            background: "url(images/services-banner.png) no-repeat bottom",
            backgroundSize: "cover",
          }}
        >
          <div className="services-banner-container">
            <div className="banner-title-section row">
              <div className="col-lg-12 text-center">
                <div className="text-wrapper">
                  <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">
                    Technologies
                  </h1>
                </div>
                <p className="text-white">
                 Home / Technologies
                </p>
              </div>
            </div>
            {/* <a className="mt-5 arrow-down mb-5" href="#makingtheworl">
            <svg width={20} height={37} viewBox="0 0 20 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1.63028C5.40498 1.63028 1.66667 5.28699 1.66667 9.78168V27.2186C1.66667 31.7133 5.40498 35.37 10 35.37C14.595 35.37 18.3333 31.7133 18.3333 27.2186V9.78168C18.3333 5.28699 14.595 1.63028 10 1.63028ZM10 0C15.5229 0 20 4.37941 20 9.78168V27.2186C20 32.6208 15.5229 37.0003 10 37.0003C4.47715 37.0003 0 32.6208 0 27.2186V9.78168C0 4.37941 4.47715 0 10 0Z" fill="white" />
              <path d="M10 15.3106C13.1218 15.3106 15.6525 12.8352 15.6525 9.78153C15.6525 6.7279 13.1218 4.25244 10 4.25244C6.87824 4.25244 4.34753 6.7279 4.34753 9.78153C4.34753 12.8352 6.87824 15.3106 10 15.3106Z" fill="white" />
            </svg>
          </a> */}
        </div>
      </section>
        
      
      <section className="center-section pt-3 pb-3 section-black">
        <div className="container">
          <div className="row pt-3 pb-3 text-center">
            <div className="col-lg-5 ml-auto">
              <div className="award-text">
                <span className="award-type">Award</span>{" "}
                <span className="award-and">&</span> Certifications
              </div>
              <div className="iso-icon">
                <img src="images/ISO_icon01.png" alt="..." />
              </div>
              <div className="iso-icon">
                <img src="images/ISO_icon02.png" alt="..." />
              </div>
            </div>
            <div className="col-lg-5 mobile-hide-only mr-auto">
              <div className="counter-item">
                <img src="images/circle-img01.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_01">9</span>
                    <span className="c_text_01">YEARS</span>IN OPERATION
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">100s</span>
                    <span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">4.9/5</span>
                    <span className="c_text_01">CUSTOMERS</span>RATING
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section className="section-white pt-5 pb-5">
          <div className="container">
            <div
              className="row section-title-top pt-5 justify-content-center"
              data-aos="fade-down"
            >
              <div className="col-lg-12 text-center">
                <p className="color-7">
                  Doodle harnesses the advanced technologies aiming to lower
                  your production cost while improving productivity. Our
                  utilization of advanced technologies are made in accordance
                  with your business
                </p>
              </div>
            </div>
          </div>
        </section>
        <UsesTechnologies />

        <section className="section-white addskill box-shadow pb-5">
          <div className="container">
            <div
              className="row section-title-top mb-5 pt-5"
              data-aos="fade-down"
            >
              <div className="col-lg-12 text-left">
                <h2>Frontend</h2>
                <p className="color-7">
                  Modernize the appearance of your application using JavaScript
                  technologies while using HTML and CSS to improve philosophy
                  and strengthen your customer experience.
                </p>
                <div className="tags_btn mb-4 mt-4">
                  <button type="button" className="btn btn-light tag-btn">
                    Angular
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    React
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    Vue
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    JavaScript
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    JQuery
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    HTML5
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    CSS3
                  </button>
                  {/* <button type="button" className="btn btn-light tag-btn">
                    Bootstrap
                  </button> */}
                </div>
                <ul
                  className="list-group row ml-0 mr-0 d-list-img-1 mid-img-block list-group-horizontal aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Angular.png" alt="Angular" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/React.png" alt="React" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/vue.png" alt="vue" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/javascript.png" alt="javascript" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/JQuery.png" alt="JQuery" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/HTML5.png" alt="HTML5" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/CSS3.png" alt="CSS3" />
                  </li>
                  {/* <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Bootstrap.png" alt="Bootstrap" />
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section-white addskill box-shadow pb-5">
          <div className="container">
            <div
              className="row section-title-top mb-5 pt-5"
              data-aos="fade-down"
            >
              <div className="col-lg-12 text-left">
                <h2>Database</h2>
                <p className="color-7">
                  Combine data from various complex data sources and administer
                  end-to-end data integration to predict important and
                  actionable insights.
                </p>
                <div className="tags_btn mb-4 mt-4">
                  <button type="button" className="btn btn-light tag-btn">
                    MongoDB
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    DynamoDB
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    MariaDB
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    MySQL
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    PostgreSQL
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    Aurora
                  </button>
                  {/* <button type="button" className="btn btn-light tag-btn">
                    Amazon
                  </button> */}
                  {/* <button type="button" className="btn btn-light tag-btn">
                    RDS
                  </button> */}
                </div>
                <ul
                  className="list-group row ml-0 mr-0 d-list-img-1 mid-img-block list-group-horizontal aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/MongoDB.png" alt="MongoDB" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/DynamoDB.png" alt="DynamoDB" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/MariaDB.png" alt="MariaDB" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/MySQL.png" alt="MySQL" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/PostgreSQL.png" alt="PostgreSQL" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/aoura.png" alt="php" />
                  </li>
                  {/* <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Amazon.png" alt="Amazon" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Amazon RDS.png" alt="Amazon RDS" />
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section-white addskill box-shadow pb-5">
          <div className="container">
            <div
              className="row section-title-top mb-5 pt-5"
              data-aos="fade-down"
            >
              <div className="col-lg-12 text-left">
                <h2>Mobile</h2>
                <p className="color-7">
                  Convert web-applications into groundbreaking mobile
                  applications by enabling mobility across multiple platforms
                  like iOS, Android, and Windows.
                </p>
                <div className="tags_btn mb-4 mt-4">
                  {/* <button type="button" className="btn btn-light tag-btn">
                    iOS
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    Android
                  </button> */}
                  <button type="button" className="btn btn-light tag-btn">
                  React Native
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Swift
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Kotlin
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Objective C
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Ionic
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Apache Cordova
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Flutter
                  </button>
                  {/* <button type="button" className="btn btn-light tag-btn">
                    Ionic
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    Apache Cordova
                  </button> */}
                  {/* <button type="button" className="btn btn-light tag-btn">
                    Cloud and DevOps
                  </button> */}
                </div>
                <ul
                  className="list-group row ml-0 mr-0 d-list-img-1 mid-img-block list-group-horizontal aos-init aos-animate"
                  data-aos="fade-up"
                >
                  {/* <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/iOS.png" alt="iOS" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Android.png" alt="Android" />
                  </li> */}
                 
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/reactnative.jpg" alt="HTML5" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/swift.png" alt="Swift" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Kotlin.png" alt="Kotlin" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/objectiveC.png" alt="Objective C" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Ionic.png" alt="Ionic" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/cordova.png" alt="React" />
                  </li>
                  {/* <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Firebase.png" alt="Firebase" />
                  </li> */}                 
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Flutter.png" alt="Flutter" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section-white addskill box-shadow pb-5">
          <div className="container">
            <div
              className="row section-title-top mb-5 pt-5"
              data-aos="fade-down"
            >
              <div className="col-lg-12 text-left">
                <h2>Cloud & DevOps</h2>
                <p className="color-7">
                  Accelerate your deliveries using flexible and cloud-based
                  technology. Access your application anytime-anywhere with
                  transparency.
                </p>
                <div className="tags_btn mb-4 mt-4">
                  <button type="button" className="btn btn-light tag-btn">
                  Amazon Web Services (AWS)
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Google Cloud
                  </button>
                  {/* <button type="button" className="btn btn-light tag-btn">
                  Docker
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Kubernetes
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Puppet
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Vagrant
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  solarwinds
                  </button> */}
                </div>
                <ul
                  className="list-group row ml-0 mr-0 d-list-img-1 mid-img-block list-group-horizontal aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/aws.png" alt="js" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/googlecloud.png" alt="php" />
                  </li>
                  {/* <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/docker.png" alt="python" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/varagant.png" alt="C#" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/solarwinds.png" alt="java" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/Kubernetes.png" alt="php" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/Puppet.png" alt="python" />
                  </li> */}
                  {/* <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/pngfuel.png" alt="C#" />
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section-white addskill box-shadow pb-5">
          <div className="container">
            <div
              className="row section-title-top mb-5 pt-5"
              data-aos="fade-down"
            >
              <div className="col-lg-12 text-left">
                <h2>Testing</h2>
                <p className="color-7">
                  Implement Doodle’s systematic testing methodologies for your
                  complex applications and ensure smooth and bug-free
                  performance.
                </p>
                <div className="tags_btn mb-4 mt-4">
                  <button type="button" className="btn btn-light tag-btn">
                    Selenium
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    Jenkins
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    GitHub
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    NewRelic
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    Apache JMeter
                  </button>
                  {/* <button type="button" className="btn btn-light tag-btn">
                    Laravel
                  </button> */}
                </div>
                <ul
                  className="list-group row ml-0 mr-0 d-list-img-1 mid-img-block list-group-horizontal aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Selenium.png" alt="Selenium" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Jenkins.png" alt="Jenkins" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/GitHub.png" alt="GitHub" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/NewRelic.png" alt="NewRelic" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Apache_JMeter.png" alt="Apache JMeter" />
                  </li>
                  {/* <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Laravel.png" alt="Laravel" />
                  </li> */}
              
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section-white addskill box-shadow pb-5">
          <div className="container">
            <div
              className="row section-title-top mb-5 pt-5"
              data-aos="fade-down"
            >
              <div className="col-lg-12 text-left">
                <h2>Others</h2>
                <p className="color-7">
                  Redefine and improve your business models using advanced
                  technology offerings.
                </p>
                <div className="tags_btn mb-4 mt-4">
                  <button type="button" className="btn btn-light tag-btn">
                    SharePoint
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                    Microsoft BizTalk
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Dialogflow
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Firebase
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Redis
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  WebRTC
                  </button>
                  <button type="button" className="btn btn-light tag-btn">
                  Bootstrap
                  </button>
                </div>
                <ul
                  className="list-group row ml-0 mr-0 d-list-img-1 mid-img-block list-group-horizontal aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/SharePoint.png" alt="SharePoint" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Microsoft_BizTalk.png" alt="Microsoft BizTalk" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Dialogflow.png" alt="Microsoft BizTalk" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/firebase.png" alt="Microsoft BizTalk" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/redis.png" alt="Microsoft BizTalk" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/WebRTC.png" alt="Microsoft BizTalk" />
                  </li>
                  <li className="list-group-item text-center pt-3 pb-3">
                    <img src="images/technologies/Bootstrap.jpg" alt="Microsoft BizTalk" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-white addskill box-shadow pb-5"
          style={{
            background:
              "url(images/1.png) no-repeat center right",
          }}
        >
          <div className="container">
            <div
              className="row section-title-top mb-5 pt-5"
              data-aos="fade-down"
            >
              <div className="col-lg-5 text-left">
                <h2>Global Presence</h2>
                <p className="color-7">
                  Doodle Inc. undertakes a wide range of international missions
                  and R&D projects. We are well-known to our customers as a
                  provider of advanced digital solutions in different parts of
                  the world. Most of our clients are from North America and
                  Europe whom we have been helping with a dedicated offshore
                  team, custom-web development, and full-fledged cloud solution.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*----------Footer---------*/}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Technology);
