import { GET_ADS } from './typeNames';


// Subscribers //

export const saveSubscriberInfoType = payload => ({
  type: 'SAVE_SUBSCRIBERS',
  payload
});


// Advertisement //

export const getAdsType = payload => {
  
  return {
    type: GET_ADS,
    payload
  }
};
