import React from 'react';
import logo from './logo.svg';
import configureStore from './store/configureStore';
import {Provider} from 'react-redux';
// import './App.css';
import Home from './components/Home'
import Nav from './components/Nav'
import { CookiesProvider } from "react-cookie";

var pathname = window.document.location.pathname;
const store = configureStore();
// store.dispatch(pathname);

function App() {
  return (
    <CookiesProvider>
      <div className="App">
        <Provider store={store}>
        <Nav/>
        </Provider>
      </div>
    </CookiesProvider>
  );
}

export default App;
