import React, { Component, Fragment } from 'react'
import Newsletter from './newsletter/Newsletter';
export class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <Fragment>
                <footer>
                    <section className="full-width-footer pt-5 pb-5 center-section section--fade-black">
                        <div className="container">
                            <div className="row pt-5 justify-content-center">
                                <div className="col-12">
                                    <div className="list-group text-center">
                                        <h2 className="text-white mb-3">Newsletter</h2>
                                        <p className="color-b font-weight-normal mb-4">Subscribe to our Newsletter to stay up to date with the latest news and trends.<br />Sign Up today and get access to all our knowledge resources for free.
                    </p>

                                        <Newsletter />
{/* 
                                        <div className="border-bottom mb-5" /> */}
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className="row footer-nav">
                                        <div className="col-lg-3 col-12 mb-4">
                                            <ul className="list-group">
                                                <h4 className="text-white font-weight-semibold">Dedicated Service</h4>
                                                <li><a href="dedicated-offshore-team.html" className="list-group-item">Dedicated Offshore team</a></li>
                                                <li><a href="#" className="list-group-item">Remote Management  Practices</a></li>
                                                <li><a href="#" className="list-group-item">Service level agreement (SLA)</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-12 mb-4">
                                            <ul className="list-group">
                                                <h4 className="text-white font-weight-semibold">Expert Solutions</h4>
                                                <li><a href="#" className="list-group-item">Cloud/devOps management</a></li>
                                                <li><a href="#" className="list-group-item">Custom E-commerce solution</a></li>
                                                <li><a href="#" className="list-group-item">Custom Portal Development</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-2 col-12 mb-5">
                                            <ul className="list-group">
                                                <h4 className="text-white font-weight-semibold">Corporate</h4>
                                                <li><a href="#" className="list-group-item">About Doodle</a></li>
                                                <li><a href="responsible-contribution.html" className="list-group-item">Responsible contributions</a></li>
                                                <li><a href="contact.html" className="list-group-item">Carrier Opportunity</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-12 mb-lg-0 mb-5">
                                            <ul className="list-group col-lg-6 col-12 pl-lg-3 pl-0 float-left">
                                                <h4 className="text-white font-weight-semibold">Resources</h4>
                                                <li><a href="/blog" className="list-group-item">Blog</a></li>
                                                <li><a href="/Portfolio" className="list-group-item">Portfolio</a></li>
                                                <li><a href="doodle-case-study.html" className="list-group-item">Case Studies</a></li>
                                            </ul>
                                            <ul className="list-group col-lg-6 mt-lg-0 mt-5 col-12 float-right">
                                                <h4 className="text-white font-weight-semibold">Support</h4>
                                                <li><a href="contact.html" className="list-group-item">Contact</a></li>
                                                <li><a href="#" className="list-group-item">Get Free Consultancy</a></li>
                                            </ul>
                                        </div>
                                        <div className="border-bottom display-block mt-5" />
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="border-bottom col-12 display-block mb-5" />
                            </div>
                            <div className="row desktop-footer">
                                <div className="list-group col pl-3">
                                    <p className="copyright color-b mt-1 mb-0">© 2020 Copyright Doodlei Inc. All rights reserved. <br></br>A sister concern of Rigel Group </p>
                                </div>
                                <div className="list-group col text-center">
                                    <div className="media">
                                        <a className="ml-auto mr-auto" href="/"><img width={140} src="/images/new-logo-doodle-white-text.png" /></a>
                                    </div>
                                </div>
                                <div className="col pl-0 social-icons text-right">
                                    <a href="https://www.facebook.com/doodlei"><i className="fab fa-facebook-f" /></a>
                                    <a href="https://twitter.com/doodleiinc"><i className="fab fa-twitter" /></a>
                                    <a href="https://www.linkedin.com/company/doodleinc"><i className="fab fa-linkedin" /></a>
                                    {/* <a ><i className="fab fa-instagram" /></a>
                                    <a ><i className="fab fa-youtube" /></a> */}
                                </div>
                            </div>
                            <div className="row mobile-footer">
                                <div className="list-group col-12 pl-1 text-center">
                                    <div className="media">
                                        <a className="ml-auto mr-auto mb-4" href="/"><img width={140} src="/images/new-logo-doodle-white-text.png" /></a>
                                    </div>
                                </div>
                                <div className="col-12 pl-0 mb-3 social-icons text-center">
                                    <a href="https://www.facebook.com/doodlei"><i className="fab fa-facebook-f" /></a>
                                    <a href="https://twitter.com/doodleiinc"><i className="fab fa-twitter" /></a>
                                    <a href="https://www.linkedin.com/company/doodleinc"><i className="fab fa-linkedin" /></a>

                                    {/* <a ><i className="fab fa-instagram" /></a>
                                    <a ><i className="fab fa-youtube" /></a> */}
                                </div>
                                <div className="list-group col-12">
                                    <p className="copyright text-center color-b mt-1 mb-0">A sister concern of Rigel Group" under the copyright text.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </footer>
            </Fragment>
        )
    }
}

export default Footer
