import React, { useEffect, useState } from "react";
import AOS from "aos";
import Footer from "./../Footer";
import Axios from "axios";
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

function TellUsAbout() {

  const {register, handleSubmit, errors } = useForm();
  console.log(errors);


  const [contacts, setUser] = useState({
    fullname: "",
    phone:"",
    email:"",
    company:"",
    message:"",
  });


  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [uploadDone, setUploadDone] = useState(false);

  const {fullname, phone, email, company, message} = contacts;
  const onInputChange = e =>{
    console.log(e.target.value);

    setUser({...contacts,[e.target.name]: e.target.value})
  }



  // const onSubmit = async (data) =>{
  //   console.log(data);

  //   await Axios.post("http://localhost:3003/contacts", contacts )
  //               .then(function (response) {
  //                 console.log(response);
  //                 toast('Your Inquery is Submitted!', {
  //                   position: "top-right",
  //                   autoClose: 5000,
  //                   hideProgressBar: false,
  //                   closeOnClick: true,
  //                   pauseOnHover: true,
  //                   draggable: true,
  //                   progress: undefined,
  //                   }
                    
  //                 );
                  
  //                 contacts.fullname="";
  //                 contacts.phone="";
  //                 contacts.email="";
  //                 contacts.company="";
  //                 contacts.message="";
  //               })
  //               .catch(function (error) {
  //                 console.log(error);
  //                 toast('Error ! Something went wrong!!', {
  //                   position: "top-right",
  //                   autoClose: 5000,
  //                   hideProgressBar: false,
  //                   closeOnClick: true,
  //                   pauseOnHover: true,
  //                   draggable: true,
  //                   progress: undefined,
  //                   }
                    
  //                 );
  //               });



  //               setLoading(true);

  //               setTimeout(
  //                 () => setLoading(false) , 
  //                 3000
  //               );
                
  // } 

 const onSubmit =(e)=>{
  toast("Wait Few Moments !");
  setLoading(true);
  const formData = new FormData()
  formData.append('fullname', contacts.fullname);
  formData.append('phone', contacts.phone);
  formData.append('email', contacts.email);
  formData.append('company', contacts.company);
  formData.append('message', contacts.message);
  formData.append('cvfile', contacts.cvfile);
    axios.post('https://www.node.liker.com:5000/api/sendmail', formData)
    .then(res=>{

      if(res.data == 'Success'){
        setUser({
          fullname: "",
          phone:"",
          email:"",
          company:"",
          message:""
        })
        setUploadDone(false)
        setLoading(false);
      }
    }).catch(()=>{
      console.log("message not sent")
    })

  }

  // function onSubmit(e) {
  //   e.preventDefault();

  //   emailjs.sendForm('gmail', 'template_56d7x9v', e.target, 'YOUR_USER_ID')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  // }


  useEffect(() => {
    document.title = `Contact Us`;
    AOS.init({});
  });

  const viewUpload = (e) => {
    setUser({...contacts,cvfile: e.target.files[0]})
    setUploadDone(true)
  }
  return (
    <section
      className="center-section section-black pt-0 pb-0 box-shadow"
      style={{ background: "url(images/contact-bg.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5 bg-black ex-pt">
            <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
              <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                <div className="award-text">
                  <span className="award-type">Award</span>{" "}
                  <span className="award-and">&</span> Certifications
                </div>
                <div className="iso-icon">
                  <img src="images/ISO_icon01.png" alt="..." />
                </div>
                <div className="iso-icon">
                  <img src="images/ISO_icon02.png" alt="..." />
                </div>
              </div>
              <div className="col-lg-12 mr-auto">
                <div className="counter-item">
                  <img src="images/circle-img01.png" alt="..." />
                  <div className="cn_01">
                    <p>
                      <span className="c_number_01">9</span>
                      <span className="c_text_01">YEARS</span>IN OPERATION
                    </p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p>
                      <span className="c_number_02">100s</span>
                      <span className="c_text_01">OF CUSTOMERS</span>
                      WORLDWIDE
                    </p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p>
                      <span className="c_number_02">4.9/5</span>
                      <span className="c_text_01">CUSTOMERS</span>RATING
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 bg-white ex-pt">
            <div
              className="row p-5 pl-lg-5 section-title-top"
              data-aos="fade-down"
            >
              <div className="col-lg-12 pl-lg-5 text-left">
                <h2>
                  Tell Us About{" "}
                  <span className="highlight-orange">Your Project</span>
                </h2>
              </div>
              <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                <form onSubmit={handleSubmit(onSubmit)} className="row">
                  <div className="form-group  mb-4 col-lg-6">
                    <label>Name</label>
                    <input type="text" name="fullname" value={contacts.fullname} onChange={e=>onInputChange(e)} className={classNames("form-control form-control-lg", {"is-invalid": errors.fullname})} placeholder="Your Full Name"
                       ref={register({
                        required:"this field is required",
                        
                      })}
                    />

                    {errors.fullname && (
                      <div className="invalid-feedback">
                          {errors.fullname.message}
                      </div>
                      )}
                  </div>
                  <div className="form-group mb-4 col-lg-6">
                    <label>E-mail</label>
                    <input type="email" name="email" value={contacts.email} onChange={e=>onInputChange(e)}  className={classNames("form-control form-control-lg", {"is-invalid": errors.email})} placeholder="example@provider.com"
                        ref={register({
                         required:"this field is required",
                         pattern:{
                           value:/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                           message: "invalid email !"
                         }
                       })}
                    />

                    {errors.fullname && (
                      <div className="invalid-feedback">
                          {errors.fullname.message}
                      </div>
                      )}
                  </div>
                  <div className="form-group mb-4 col-lg-6">
                    <label>Phone</label>
                    <input type="phone" name="phone" value={contacts.phone} onChange={e=>onInputChange(e)} className={classNames("form-control form-control-lg", {"is-invalid": errors.phone})} placeholder="+1 112 222 333"
                         ref={register({
                         required:"this field is required",
                         pattern:{
                           value:/\d+/,
                           message:"must be number",
                         },
                         minLength:{
                           value:11,
                           message:"min number 11 digits",
                         },
                         maxLength:{
                          value:14,
                          message:"max number 14 digits",
                         }
                       })}
                    />

                    {errors.phone && (
                      <div className="invalid-feedback">
                          {errors.phone.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-4 col-lg-6">
                    <label>Company</label>
                    <input type="text" name="company" value={contacts.company} onChange={e=>onInputChange(e)} className={classNames("form-control form-control-lg", {"is-invalid": errors.company})} placeholder="Ex: Pexel Inc."
                        ref={register({
                        required:"this field is required",
                        minLength:{
                          value:4,
                          message:"please enter minimum 4 charcters",
                          }
                      })}
                    />

                    {errors.company && (
                      <div className="invalid-feedback">
                          {errors.company.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-4 col-lg-12">
                    <label>Messages</label>
                    <textarea className={classNames("form-control ex-h", {"is-invalid": errors.message})} name="message"  placeholder="Hello..." value={contacts.message}  onChange={e=>onInputChange(e)}
                        ref={register({
                        required:"this field is required",
                        minLength:{
                          value:4,
                          message:"please enter minimum 4 charcters",
                          }
                      })}
                    ></textarea>
                     {errors.message && (
                      <div className="invalid-feedback">
                          {errors.message.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-4 col-lg-12">
                    <div className="custom-file">
                   
                      <input type="file" name="cvfile" onChange={viewUpload}
                                          onClick={(event) => {
                                            event.target.value = null;
                                            
                                          }}  className={classNames("custom-file-input")} id="customFile"/>
                     
                      <label className="custom-file-label" htmlFor="customFile">
                      {uploadDone ?
                      <></>
                      :
                          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 4.75V6.25C11 6.45833 10.9271 6.63542 10.7813 6.78125C10.6354 6.92708 10.4583 7 10.25 7H7V10.25C7 10.4583 6.92708 10.6354 6.78125 10.7812C6.63542 10.9271 6.45833 11 6.25 11H4.75C4.54167 11 4.36458 10.9271 4.21875 10.7812C4.07292 10.6354 4 10.4583 4 10.25V7H0.75C0.541667 7 0.364583 6.92708 0.21875 6.78125C0.0729167 6.63542 0 6.45833 0 6.25V4.75C0 4.54167 0.0729167 4.36458 0.21875 4.21875C0.364583 4.07292 0.541667 4 0.75 4H4V0.75C4 0.541666 4.07292 0.364583 4.21875 0.218749C4.36458 0.0729161 4.54167 -5.96046e-07 4.75 -5.96046e-07H6.25C6.45833 -5.96046e-07 6.63542 0.0729161 6.78125 0.218749C6.92708 0.364583 7 0.541666 7 0.75V4H10.25C10.4583 4 10.6354 4.07292 10.7813 4.21875C10.9271 4.36458 11 4.54167 11 4.75Z" fill="black"/>
                          </svg>
                       }
                        &nbsp; {uploadDone ? <p style={{color:"green",marginTop:"-18px"}}>Upload Done</p> : <>Add Attachment </>}
                      </label>
                    </div>
                  </div>

                  <div className="form-group col-lg-12">
                    <button type="submit" className="btn btn-primary btn-lg">
                      <span>Send Now</span>
                      <ToastContainer
                      position="top-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      />
                      {/* <ToastContainer
                      position="bottom-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      /> */}
                    </button>
                  </div>
                </form>
                {
                loading &&
                <div className="text-center" style={{position:"relative", marginTop:"-450px"}}>
                <Loader
                type="Puff"
                color="#00BFFF"
                height={300}
                width={300}
              >
              </Loader>
              </div>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TellUsAbout;
