import React, { useEffect, useState } from "react";
import AwardAndCertifications from "../common/AwardAndCertifications";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
function CaseStudies(props) {
  return (
    <div>
      <section
        className="banner"
        style={{
          background: "url(images/portfolio-bg-update.png) no-repeat bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">
                  Case Studies
                </h1>
              </div>
              <p className="text-white">
                Company / <span className="dark-text">Case Studies</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <AwardAndCertifications />
      <section className="center-section section-white pt-0">
        <div className="container">
          <div className="general-tab">
            <div className="col-md-12 pl-0">
              <ul
                className="nav p-0 nav-tabs justify-content-center text-wrapper p-wrapper"
                id="myTab"
                role="tablist"
              >
                <li className="p-0 nav-item">
                  <a
                    className="nav-link text-center active ex-pd"
                    id="all-tab"
                    data-toggle="tab"
                    href="#all"
                    role="tab"
                    aria-controls="all"
                    aria-selected="true"
                  >
                    All
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a
                    className="nav-link text-center ex-pd"
                    id="web-tab"
                    data-toggle="tab"
                    href="#web"
                    role="tab"
                    aria-controls="web"
                    aria-selected="true"
                  >
                    Web
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a
                    className="nav-link text-center ex-pd"
                    id="android-tab"
                    data-toggle="tab"
                    href="#android"
                    role="tab"
                    aria-controls="android"
                    aria-selected="true"
                  >
                    Android
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a
                    className="nav-link text-center ex-pd"
                    id="ios-tab"
                    data-toggle="tab"
                    href="#ios"
                    role="tab"
                    aria-controls="ios"
                    aria-selected="true"
                  >
                    IOS Apps
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a
                    className="nav-link text-center ex-pd"
                    id="cloud-tab"
                    data-toggle="tab"
                    href="#cloud"
                    role="tab"
                    aria-controls="cloud"
                    aria-selected="true"
                  >
                    Cloud
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a
                    className="nav-link text-center ex-pd"
                    id="e-commerce-tab"
                    data-toggle="tab"
                    href="#e-commerce"
                    role="tab"
                    aria-controls="e-commerce"
                    aria-selected="true"
                  >
                    E-Commerce
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="tab-content portfolio-tab-content"
              id="myTabContent"
            >
              <div
                className="tab-pane fade show text-left active"
                id="all"
                role="tabpanel"
                aria-labelledby="all-tab"
              >
                <div className="row m-0">
                  <div className="col-md-6 text-center pb-3 mb-5">
                    <div className="p-img-section">
                      <img src="images/portfolio-1.jpg" alt="" />
                      <div className="view-project">
                        <div
                          className="btn-content"
                          data-toggle="modal"
                          data-target="#portfolio1"
                        >
                          <svg
                            width="43"
                            height="43"
                            viewBox="0 0 43 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="21.5"
                              cy="21.5"
                              r="21.5"
                              fill="#FF8135"
                            />
                            <path
                              d="M21.5 4C11.8506 4 4 11.8506 4 21.5C4 31.1494 11.8506 39 21.5 39C31.1494 39 39 31.1494 39 21.5C39 11.8506 31.1493 4 21.5 4ZM30.25 23.6875H23.6875V30.25H19.3125V23.6875H12.75V19.3125H19.3125V12.75H23.6875V19.3125H30.25V23.6875Z"
                              fill="white"
                            />
                          </svg>
                          <h4 className="highlight-orange pt-2 font-weight-semibold">
                            View Project
                          </h4>
                        </div>
                      </div>
                    </div>
                    <h4 className="pt-3 font-weight-semibold mb-0">
                      Foodla - Food Delivery App Design
                    </h4>
                    <p className="highlight-orange">Web</p>
                    <button type="button" className="btn btn-light tag-btn">
                      PHP
                    </button>
                    <button type="button" className="btn btn-light tag-btn">
                      JavaScript
                    </button>
                    <button type="button" className="btn btn-light tag-btn">
                      Larvel
                    </button>
                  </div>
                  <div className="col-md-6 text-center pb-3 mb-5">
                    <div className="p-img-section">
                      <img src="images/portfolio-2.jpg" alt="" />
                      <div className="view-project">
                        <div
                          className="btn-content"
                          data-toggle="modal"
                          data-target="#portfolio1"
                        >
                          <svg
                            width="43"
                            height="43"
                            viewBox="0 0 43 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="21.5"
                              cy="21.5"
                              r="21.5"
                              fill="#FF8135"
                            />
                            <path
                              d="M21.5 4C11.8506 4 4 11.8506 4 21.5C4 31.1494 11.8506 39 21.5 39C31.1494 39 39 31.1494 39 21.5C39 11.8506 31.1493 4 21.5 4ZM30.25 23.6875H23.6875V30.25H19.3125V23.6875H12.75V19.3125H19.3125V12.75H23.6875V19.3125H30.25V23.6875Z"
                              fill="white"
                            />
                          </svg>
                          <h4 className="highlight-orange pt-2 font-weight-semibold">
                            View Project
                          </h4>
                        </div>
                      </div>
                    </div>
                    <h4 className="pt-3 font-weight-semibold mb-0">
                      Foodla - Food Delivery App Design
                    </h4>
                    <p className="highlight-orange">Web</p>
                    <button type="button" className="btn btn-light tag-btn">
                      PHP
                    </button>
                    <button type="button" className="btn btn-light tag-btn">
                      JavaScript
                    </button>
                    <button type="button" className="btn btn-light tag-btn">
                      Larvel
                    </button>
                  </div>
                  <div className="col-md-6 text-center pb-3 mb-5">
                    <div className="p-img-section">
                      <img src="images/portfolio-3.jpg" alt="" />
                      <div className="view-project">
                        <div
                          className="btn-content"
                          data-toggle="modal"
                          data-target="#portfolio1"
                        >
                          <svg
                            width="43"
                            height="43"
                            viewBox="0 0 43 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="21.5"
                              cy="21.5"
                              r="21.5"
                              fill="#FF8135"
                            />
                            <path
                              d="M21.5 4C11.8506 4 4 11.8506 4 21.5C4 31.1494 11.8506 39 21.5 39C31.1494 39 39 31.1494 39 21.5C39 11.8506 31.1493 4 21.5 4ZM30.25 23.6875H23.6875V30.25H19.3125V23.6875H12.75V19.3125H19.3125V12.75H23.6875V19.3125H30.25V23.6875Z"
                              fill="white"
                            />
                          </svg>
                          <h4 className="highlight-orange pt-2 font-weight-semibold">
                            View Project
                          </h4>
                        </div>
                      </div>
                    </div>
                    <h4 className="pt-3 font-weight-semibold mb-0">
                      Foodla - Food Delivery App Design
                    </h4>
                    <p className="highlight-orange">Web</p>
                    <button type="button" className="btn btn-light tag-btn">
                      PHP
                    </button>
                    <button type="button" className="btn btn-light tag-btn">
                      JavaScript
                    </button>
                    <button type="button" className="btn btn-light tag-btn">
                      Larvel
                    </button>
                  </div>
                  <div className="col-md-6 text-center pb-3 mb-5">
                    <div className="p-img-section">
                      <img src="images/portfolio-4.jpg" alt="" />
                      <div className="view-project">
                        <div
                          className="btn-content"
                          data-toggle="modal"
                          data-target="#portfolio1"
                        >
                          <svg
                            width="43"
                            height="43"
                            viewBox="0 0 43 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="21.5"
                              cy="21.5"
                              r="21.5"
                              fill="#FF8135"
                            />
                            <path
                              d="M21.5 4C11.8506 4 4 11.8506 4 21.5C4 31.1494 11.8506 39 21.5 39C31.1494 39 39 31.1494 39 21.5C39 11.8506 31.1493 4 21.5 4ZM30.25 23.6875H23.6875V30.25H19.3125V23.6875H12.75V19.3125H19.3125V12.75H23.6875V19.3125H30.25V23.6875Z"
                              fill="white"
                            />
                          </svg>
                          <h4 className="highlight-orange pt-2 font-weight-semibold">
                            View Project
                          </h4>
                        </div>
                      </div>
                    </div>
                    <h4 className="pt-3 font-weight-semibold mb-0">
                      Foodla - Food Delivery App Design
                    </h4>
                    <p className="highlight-orange">Web</p>
                    <button type="button" className="btn btn-light tag-btn">
                      PHP
                    </button>
                    <button type="button" className="btn btn-light tag-btn">
                      JavaScript
                    </button>
                    <button type="button" className="btn btn-light tag-btn">
                      Larvel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ToastContainer />
    </div>
  );
}

export default CaseStudies;
