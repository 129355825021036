import React, { useState, useEffect } from "react";
import AOS from "aos";
import ToolsAndTechnologies from "./../../common/ToolsAndTechnologies";
import TellUsAbout from "../../common/TellUsAbout";
import FAQ from "../../common/FAQ";
import RelatedBlogArticles from "../../common/RelatedBlogArticles";
import AwardAndCertifications from "../../common/AwardAndCertifications";
import Footer from "./../../Footer";
import ServiceItems from "../ServiceItems";
function CustomSoftware() {
  const initialState = {
    heading: "Custom Software Development Services",

    contents: [
      {
        content:
          "Doodle Inc. is providing efficient custom software development services for over a decade. No matter how unique your requirement is, we got you covered. With our next-generation technologies, we will build, redesign, and integrate your software solutions dedicating your business needs. Our elite team can help your business to flourish in this transformative market.",
      },
    ],
    serviceList: [
      {
        title: "Enterprise Solutions",
        content:
          "Optimize your business by utilizing our enterprise solutions. Our enterprise software development services will cover all your business functions. From simple inventory, HR, CRM, accounting to complex e-commerce, or anything you need. Our innovative software solutions can take your enterprise to the next level.",
      },
      {
        title: "Enterprise Solutions",
        content:
          "Optimize your business by utilizing our enterprise solutions. Our enterprise software development services will cover all your business functions. From simple inventory, HR, CRM, accounting to complex e-commerce, or anything you need. Our innovative software solutions can take your enterprise to the next level.",
      },
      {
        title: "Enterprise Solutions",
        content:
          "Optimize your business by utilizing our enterprise solutions. Our enterprise software development services will cover all your business functions. From simple inventory, HR, CRM, accounting to complex e-commerce, or anything you need. Our innovative software solutions can take your enterprise to the next level.",
      },
      {
        title: "Enterprise Solutions",
        content:
          "Optimize your business by utilizing our enterprise solutions. Our enterprise software development services will cover all your business functions. From simple inventory, HR, CRM, accounting to complex e-commerce, or anything you need. Our innovative software solutions can take your enterprise to the next level.",
      },
    ],
  };
  const [services, setServices] = useState(initialState);
  useEffect(() => {
    document.title = `Custom Software Development`;
    AOS.init({});
  });
  return (
    <div>
      <section
        className="banner"
        style={{
          background: "url(images/services-banner.png) no-repeat bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">
                  Custom Software Development
                </h1>
              </div>
              <p className="text-white">
                Services /{" "}
                <span className="dark-text">Custom Software Development</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <AwardAndCertifications />
      <ServiceItems />
      <section className="section-white pt-5 pb-5">
        <div className="container">
          <div className="row section-title-top mb-5 pt-5" data-aos="fade-down">
            <div className="col-lg-5 text-left">
              <h2>
                Emerging <span className="highlight-orange">Technologies</span>{" "}
                <br />
                for Custom Software Development
              </h2>
              <p className="color-7">
                Doodle Inc. has earned global recognition in custom software
                development. With a variety of modern technologies and
                approaches, we are serving our clients with dependable
                solutions.
              </p>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
              <div className="tech-item tech-color-1">
                <img src="/images/tech-test-img-01.png" alt="" />
                <p>
                  <span>Artificial Intelligence</span>AI-based algorithms &
                  automated operation for scalable solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
              <div className="tech-item tech-color-2">
                <img src="/images/tech-test-img-02.png" alt="" />
                <p>
                  <span>Internet of Things</span>IoT devices to make a
                  real-time, efficient, and robust infrastructure.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="tech-item tech-color-3">
                <img src="/images/tech-test-img-03.png" alt="" />
                <p>
                  <span>Cloud Computing</span>Flexible, scalable & integrated
                  business solution.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="tech-item tech-color-4">
                <img src="/images/tech-test-img-04.png" alt="" />
                <p>
                  <span>Big Data</span>Increased business intelligence and
                  dependable data analysis.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3 push-right">
              <div className="tech-item tech-color-5">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-05.png"
                  alt=""
                />
                <p>
                  <span>BlockChain</span>Secured data, automated key operations,
                  detailed database, reduces cost, and increases efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="tech-item tech-color-6">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-06.png"
                  alt=""
                />
                <p>
                  <span>Augmented Reality</span>Greater & interactive user
                  experience with increased efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="tech-item tech-color-7">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-07.png"
                  alt=""
                />
                <p>
                  <span>Virtual Reality</span>Simulated user experience that can
                  be applied in education, entertainment, business, and many
                  other sectors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="center-section section-white box-shadow">
        <div className="container">
          <div
            className="row section-title-top justify-content-center"
            data-aos="fade-down"
          >
            <div className="col-lg-12 text-center">
              <h2>
                Software Development{" "}
                <span className="highlight-orange">Life Cycle </span>(SDLC)
              </h2>
              <p className="color-7">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </div>
          </div>
          <div className="row mt-5 pt-4">
            <div className="col-lg-4 d-inline-table text-center">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        1. Planning <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      It all starts with collecting information from the
                      customers and getting into their core vision. So we set
                      the project goal, probable cost, and resources we’re going
                      to need.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. Defining Requirements{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Determining what the application is supposed to do and its
                      requirements. It also includes defining the resources
                      needed to develop the project and the technical dynamics
                      we may need to cover.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. Design <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The system and documents are arranged as per requirement
                      specifications. Such an arrangement helps to reach the
                      aimed system architecture and technology stack. Designers
                      start designing the user interface.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. Implementation/Building{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      This is where the core development starts. Developers
                      start to write code to build the whole system using chosen
                      language and methodologies. The designers continue working
                      on user interface and testers start analyzing the
                      requirements for their test plans.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFive">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5. Testing <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="headingFive"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      This phase is to find bugs in the code and fix them while
                      evaluating the overall quality of the software. The
                      testing team also evaluates the functionality of the
                      entire system.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingSix">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        6. Deployment <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSix"
                    className="collapse"
                    aria-labelledby="headingSix"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The application is made available to a specific set of
                      users and checked for issues. The basic ideas are sought
                      to be improved here.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingSeven">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        7. Review <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    aria-labelledby="headingSeven"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The product is ready. Possible bugs and imperfections are
                      reviewed. The aim is to make the software complete.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingEight">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        8. Launch <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseEight"
                    className="collapse"
                    aria-labelledby="headingEight"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The final product is released to the market. Users
                      starting to use the product.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingNine">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        9. Maintenance <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseNine"
                    className="collapse"
                    aria-labelledby="headingNine"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Maintenance is performed over time to change the system
                      architecture according to future needs. This may require
                      the addition of code or alteration of the existing code,
                      bug fixing, upgrade, enhancement, server maintenance, and
                      much more are done during the maintenance phase.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-lg-0 mt-sm-5 d-inline-table lifecycle text-center">
              <img src="/images/lifecycle.png" alt="" />
              <div className="step01">
                <span className="stepleft">Deploy</span>
                <span className="stepright">Test</span>
              </div>
              <div className="step02">
                <span className="stepleft">Review</span>
                <span className="stepright">Develop</span>
              </div>
              <div className="step03">
                <span className="stepleft">Plan</span>
                <span className="stepcenter">Design</span>
                <span className="stepright">Launch</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToolsAndTechnologies />
      <TellUsAbout />
      {/* <FAQ />
      <RelatedBlogArticles /> */}
      <Footer />
    </div>
  );
}
export default CustomSoftware;
