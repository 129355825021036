import React from 'react';
import axios from 'axios';


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['crossDomain'] = true;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


export const ajaxConfig = {
    // baseURL: window.api_path,
   
      'Access-Control-Allow-Origin': '*',
    //   'Device-Id': security_data.device_id,
    //   'Security-Token': security_data.security_token,
    //   'User-Id': window.getCurrentuserid(),
      'Content-Type': 'application/x-www-form-urlencoded'
    
  };

export {axios};

export const validateEmail = email => {
  const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

export const objectToQueryString = params => {
  return new URLSearchParams(params).toString();
};

/**
 * Get cookie
 * @param cookieName String
 * @param cookies Object
 */
export function getCookie(cookieName, cookies) {
  
  const biscuit = cookies.get(cookieName);
  
  return (biscuit === undefined) ? false : biscuit;
}

/**
 * Sets cookie
 * If same key exists then its just returns existing value
 * @param cookieName    String
 * @param cookies       Object
 * @param cookieValue   Any
 * @param maxAge        Int (in seconds)
 * @return {*}
 */
export function setCookie(cookieName, cookies, cookieValue, maxAge=86400) {
  
  let biscuit = getCookie(cookieName, cookies);
  
  // if cookie is not set
  if (!biscuit) {
    
    cookies.set(cookieName, cookieValue, {
      path: '/',
      maxAge: maxAge
    });
    
    return cookieValue;
    
  }
  
  return biscuit;
}
