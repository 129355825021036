import React, { useEffect } from "react";
import AOS from "aos";
import Banner from "./../common/Banner";
import AwardAndCertifications from "./../common/AwardAndCertifications";
import FAQ from "./../common/FAQ";
import ToolsAndTechnologies from "./../common/ToolsAndTechnologies";
import RelatedBlogArticles from "./../common/RelatedBlogArticles";
import TellUsAbout from "./../common/TellUsAbout";
import Footer from "./../Footer";

function ResponsibleContribution(props) {
  useEffect(() => {
    document.title = `Responsible Contribution`;
    AOS.init({});
  });
  return (
    <div>
       <section className="banner" style={{ background: "url(images/services-banner.png) no-repeat bottom",
    backgroundSize: "cover",}}>
      <div className="services-banner-container">
        <div className="banner-title-section row">
          <div className="col-lg-12 text-center">
            <div className="text-wrapper">
              <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">
                Responsible Contribution
              </h1>
            </div>
            <p className="text-white">
              Company /{" "}
              <span className="dark-text">Responsible Contribution</span>
            </p>
          </div>
        </div>
      </div>
  </section>
  <AwardAndCertifications />

  {/* White Section */}
    <section id="makingtheworl" class="center-section section-white">
      <div class="container">
        <div class="row m-0">
          <div class="col-md-9 pl-0" data-aos="fade-right">
            <h2 class="text-left mt-lg-0 mt-xl-5 mt-d-2 pt-3">We <span class="highlight-orange">Believe</span> in Impact</h2>
            <p class="text-left color-6 color-a">Our team loves exploring new ideas, technologies and tools of all kinds of industries. We have seen it all over the years and yet we are always curious. That is because we want everyone we reach to have that “wow” moment that makes them act all the time. </p>
            <p class="text-left color-6 color-a">We create amazing things that strike the perfect balance between quality and powerful technology. Our work reflects our values and heart both for the world and its people. Therefore we take the extra leap to make each one of our projects highly impactful for the society that can contribute to the greater good of the people around us. </p>
            <p class="text-left color-6 color-a">Our approach is ambitious and we are proud of it. It is because we make every effort to create value in our services that can positively affect the society by leveraging business, lifestyle and education. </p>
          </div>
          <div class="col-md-3 pr-0 pl-5" data-aos="fade-left">
            <img class="sub-sec-img-height" src="images/dashboard.png" alt=""/>
          </div>
        </div>
      </div>
    </section>



    {/* White Section */}
    <section class="center-section pt-5 section-white">
      <div class="container">
        <div class="row section-title-top justify-content-center">
            <div class="col-lg-12 text-center">
              <div class="highlight-black text-center mb-3 font-weight-semibold text-size-13">WITH US</div>
              <h2 data-aos="fade-down">Our Diligent Contributions Towards a <span class="highlight-orange">Smarter</span> World</h2>
              <p class="color-9" data-aos="fade-up">A Smarter World became the underlying framework of Doodle’s growth strategy right from the beginning. We strive to make a more productive, efficient and responsive world through a smarter use of technology. Therefore, we consider every project as a valuable asset which can not only be beneficial to its owners or users but also be a strong contributor to the community.</p>
            </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 mb-5 pl-0 pr-0 h-block2 h-block-left">
            <div class="media">
              <img src="images/blog-img02.jpg" class="mr-3" alt="..."/>
              <div class="media-body align-self-center pl-5" data-aos="fade-right">
                <h3 class="font-weight-semibold font-asap">An Improved<br/>Customer Experience</h3>
                <p class="color-6">Customer experience also known as CX is the entire sentiment of every interaction each customer has with a company. Doodle believes that with the right technology and process, every customer can be treated with a fantastic experience. </p>
                <p class="color-6">Over the years, our team has been engaging the right people, with an effective process and advanced technology to ensure a top-notch customer experience. Our primary objective is to help and solve the problem of our customers instead of just focusing on the bill.</p>
                <p class="color-6">Doodle encourages to establish a transparent and delightful experience for every customer who is trying to get help. Being one of the leading tech firms in the world, Doodle believes that aiding a customer is one of our key social accountabilities. </p>
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-5 mt-5 pl-0 pr-0 h-block2 h-block-left">
            <div class="media">
              <div class="media-body text-right align-self-center pr-5" data-aos="fade-right">
                <h3 class="font-weight-semibold font-asap">Help Businesses<br/> Around The World to Go Beyond</h3>
                <p class="color-6">Doodle believes that technology has no border and we are all part of the growing digital world irrespective of our nationality, race and culture. So we are not limited by demography, borders, liquidity and investment to extend our helping hand.</p>
                <p class="color-6">Our team loves connecting with enthusiasts all around the world and helping them achieve great feats by using our technological skills and resources that we have built over the years. We go beyond the conventional ways of partnership to facilitate growth for business worldwide.</p>
                <p class="color-6">Doodle wants to share its success and achievements with everyone who wants to make the world better, smarter and more intelligent. Our team does not hold back nor believes in restraining knowledge. We strive to give you the edge you need to transcend in what you do.</p>
              </div>
              <img src="images/blog-img02.jpg" class="align-self-center mr-3" alt="..."/>
            </div>
          </div>
          <div class="col-md-12 mt-5 pl-0 pr-0 h-block2 h-block-left">
            <div class="media">
              <img src="images/blog-img02.jpg" class="mr-3" alt="..."/>
              <div class="media-body align-self-center pl-5" data-aos="fade-right">
                <h3 class="font-weight-semibold font-asap">Reaching<br/>Communities Worldwide</h3>
                <p class="color-6">Doodle is continuously working to empower the surrounding by intricate and salutary technology. We do not believe in confining our services or be selective. Instead we love working on projects that reach out to different segments of people all around the world and extend our helping hand to experience the positive results of high-tech solutions. </p>
                <p class="color-6">We inspire communities to come closer with advanced tech solutions <a href="#">that can better their lives</a>. Our team rigorously works to connect with the central population from every part of the world and introduces latest ways to upgrade their business and lifestyle. </p>
                <p class="color-6">Our services are easily adaptable and tailored to the needs of our partners. Therefore, we are able to ameliorate more number of communities from various demographics by offering our experience as well as expertise on business and technology.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    {/* <!--White Section--> */}
    <section class="center-section section-white box-shadow">
      <div class="container">
        <div class="row section-title-top justify-content-center">
            <div class="col-lg-12 text-center">
              <h2 data-aos="fade-down">We Keep<br/>Ourselves <span class="highlight-orange">Socially</span> Responsible</h2>
              <p class="color-9" data-aos="fade-up">We aim to help the world to use technology at its fullest advantage. Our aim is to fill the gaps and introduce different types of technological advancements to the world which can positively impact their lives. </p>
            </div>
        </div>
        <div class="row mt-5 pb-5 col-box3">
          <div class="col-sm" data-aos="zoom-in-down">
            <div class="box-item hover-item text-center">
              <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46.7934 25.142H43.881V23.4916C43.881 22.6874 43.229 22.0354 42.4247 22.0354H30.7749C29.9707 22.0354 29.3187 22.6874 29.3187 23.4916V35.6315C29.3187 38.0404 31.2785 40.0002 33.6874 40.0002H39.5123C41.8351 40.0002 43.7394 38.178 43.8728 35.8882C47.1451 35.5508 49.7059 32.7779 49.7059 29.418V28.0545C49.7059 26.4485 48.3993 25.142 46.7934 25.142ZM46.7934 29.418C46.7934 31.1664 45.5378 32.6271 43.881 32.9457V28.0545H46.7934V29.418Z" fill="black" fill-opacity="0.2"/>
                <path d="M19.659 2.91245C20.462 2.91245 21.1152 2.25919 21.1152 1.45623C21.1152 0.653263 20.462 0 19.659 0C18.8561 0 18.2028 0.653263 18.2028 1.45623C18.2028 2.25919 18.8561 2.91245 19.659 2.91245Z" fill="black" fill-opacity="0.2"/>
                <path d="M39.3181 19.1229V1.45623C39.3181 0.652001 38.6661 0 37.8619 0H23.7773C23.9389 0.455799 24.0277 0.945673 24.0277 1.45623C24.0277 3.86512 22.0679 5.8249 19.6591 5.8249C17.2502 5.8249 15.2904 3.86512 15.2904 1.45623C15.2904 0.945673 15.3792 0.455799 15.5408 0H1.45623C0.652001 0 0 0.652001 0 1.45623V22.0399H26.6552C27.255 20.3426 28.8747 19.1229 30.7749 19.1229H39.3181Z" fill="black" fill-opacity="0.2"/>
                <path d="M26.4062 24.9521H0V27.6681C0 28.4723 0.652001 29.1243 1.45623 29.1243H26.4062V24.9521Z" fill="black" fill-opacity="0.2"/>
                <path d="M26.4062 32.0369H12.9118V34.2697H9.90123C9.09701 34.2697 8.44501 34.9217 8.44501 35.726C8.44501 36.5302 9.09701 37.1822 9.90123 37.1822H26.5742C26.4652 36.6821 26.4062 36.1636 26.4062 35.6313V32.0369Z" fill="black" fill-opacity="0.2"/>
              </svg>
              <p class="color-6 font-weight-normal">We want our<br/>actions to positively affect society</p>
            </div>
          </div>
          <div class="col-sm" data-aos="zoom-in-down">
            <div class="box-item hover-item hover-active text-center">
              <svg width="49" height="40" viewBox="0 0 49 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.4084 14.5928L24.3814 14.5408L24.3543 14.5928C24.1473 14.9907 23.7653 15.2682 23.3228 15.3421L23.265 15.3518L23.306 15.3935C23.6204 15.7135 23.7663 16.1625 23.7 16.6061L23.6913 16.664C23.9119 16.554 24.1126 16.4878 24.3813 16.4878C24.6542 16.4878 24.8554 16.5564 25.0712 16.664L25.0625 16.6061C24.9961 16.1625 25.142 15.7135 25.4565 15.3935L25.4976 15.3518L25.4398 15.3421C24.9975 15.2682 24.6155 14.9907 24.4084 14.5928Z" fill="black" fill-opacity="0.2"/>
                <path d="M37.7419 14.5928L37.7149 14.5408L37.6878 14.5928C37.4808 14.9907 37.0988 15.2682 36.6563 15.3421L36.5985 15.3518L36.6395 15.3935C36.9539 15.7135 37.0998 16.1625 37.0336 16.6061L37.0249 16.664C37.2455 16.554 37.4461 16.4878 37.7148 16.4878C37.9878 16.4878 38.1889 16.5564 38.4047 16.664L38.3961 16.6061C38.3297 16.1625 38.4756 15.7135 38.7901 15.3935L38.8311 15.3518L38.7733 15.3421C38.331 15.2682 37.949 14.9907 37.7419 14.5928Z" fill="black" fill-opacity="0.2"/>
                <path d="M11.0748 14.5928L11.0478 14.5408L11.0207 14.5928C10.8137 14.9907 10.4317 15.2682 9.98921 15.3421L9.9314 15.3518L9.97245 15.3935C10.2868 15.7135 10.4327 16.1625 10.3665 16.6061L10.3578 16.664C10.5784 16.554 10.779 16.4878 11.0477 16.4878C11.3207 16.4878 11.5218 16.5564 11.7376 16.664L11.729 16.6061C11.6626 16.1625 11.8085 15.7135 12.123 15.3935L12.164 15.3518L12.1062 15.3421C11.6639 15.2682 11.2819 14.9907 11.0748 14.5928Z" fill="black" fill-opacity="0.2"/>
                <path d="M44.4769 0H4.28578C1.9226 0 0 1.9226 0 4.28578V27.1433C0 29.5065 1.9226 31.4291 4.28578 31.4291H17.9024L23.1927 39.3645C23.7578 40.2122 25.0053 40.2115 25.57 39.3645L30.8603 31.4291H44.4769C46.8401 31.4291 48.7627 29.5065 48.7627 27.1433V4.28578C48.7627 1.9226 46.8401 0 44.4769 0ZM16.1258 15.3971L14.6601 16.8883L14.9693 18.9562C15.1399 20.0968 13.9527 20.9612 12.9189 20.446L11.0478 19.5129L9.17663 20.446C8.1447 20.9605 6.95545 20.0986 7.12621 18.9562L7.43536 16.8883L5.96981 15.3971C5.16151 14.5745 5.61381 13.1774 6.75297 12.9866L8.81529 12.6416L9.78064 10.7868C10.313 9.76397 11.7819 9.76235 12.3151 10.7868L13.2804 12.6416L15.3427 12.9866C16.4802 13.1772 16.9354 14.5733 16.1258 15.3971ZM29.4593 15.3971L27.9937 16.8883L28.3028 18.9562C28.4734 20.0968 27.2863 20.9612 26.2524 20.446L24.3814 19.5129L22.5102 20.446C21.4782 20.9605 20.289 20.0985 20.4598 18.9562L20.7689 16.8883L19.3033 15.3971C18.495 14.5745 18.9473 13.1774 20.0864 12.9866L22.1487 12.6416L23.1141 10.7868C23.6465 9.76397 25.1154 9.76235 25.6485 10.7868L26.6139 12.6416L28.6762 12.9866C29.8137 13.1772 30.269 14.5733 29.4593 15.3971ZM42.7929 15.3971L41.3272 16.8883L41.6364 18.9562C41.8084 20.1065 40.6119 20.9572 39.586 20.446L37.7149 19.5129L35.8437 20.446C34.8118 20.9605 33.6226 20.0986 33.7933 18.9562L34.1025 16.8883L32.6368 15.3971C31.8285 14.5745 32.2808 13.1774 33.42 12.9866L35.4823 12.6416L36.4476 10.7868C36.98 9.76397 38.4489 9.76235 38.9821 10.7868L39.9474 12.6416L42.0097 12.9866C43.1473 13.1772 43.6025 14.5733 42.7929 15.3971Z" fill="black" fill-opacity="0.2"/>
              </svg>
              <p class="color-6">We do our best<br/>to benefit our city and country</p>
            </div>
          </div>
          <div class="col-sm" data-aos="zoom-in-down">
            <div class="box-item hover-item text-center">
              <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.2591 40C27.9357 40 27.607 39.9095 27.3165 39.72L19.9252 34.9373C19.12 34.4156 18.8887 33.3391 19.4104 32.5321C19.9322 31.7269 21.0087 31.4956 21.8157 32.0173L29.207 36.8C30.0122 37.3217 30.2435 38.3982 29.7217 39.2052C29.3878 39.7199 28.8296 40 28.2591 40Z" fill="black" fill-opacity="0.2"/>
                <path d="M13.48 40C12.9096 40 12.3513 39.72 12.0191 39.2052C11.4974 38.3982 11.7287 37.3217 12.5339 36.8L19.9252 32.0174C20.7322 31.4939 21.8087 31.7269 22.3304 32.5322C22.8522 33.3391 22.6209 34.4156 21.8157 34.9374L14.4244 39.72C14.1304 39.9096 13.8035 40 13.48 40Z" fill="black" fill-opacity="0.2"/>
                <path d="M19.1304 31.3042V38.2607C19.1304 39.2207 19.9096 39.9999 20.8696 39.9999C21.8296 39.9999 22.6087 39.2207 22.6087 38.2607V31.3042H19.1304Z" fill="black" fill-opacity="0.2"/>
                <path d="M37.8261 9.56526H3.91305C3.61566 9.56526 3.3287 9.52178 3.04349 9.47656V26.5218C3.04349 28.2 4.4087 29.5653 6.08697 29.5653H35.6522C37.3304 29.5653 38.6957 28.2 38.6957 26.5218V9.47656C38.4104 9.52178 38.1235 9.56526 37.8261 9.56526ZM31.3426 15.0174L24.7026 23.0922C24.1409 23.7757 23.1548 23.9287 22.4122 23.447L18.5983 20.9722L12.9513 26.4644C12.6139 26.7931 12.1757 26.9566 11.7391 26.9566C11.2852 26.9566 10.8331 26.7809 10.4922 26.4296C9.82262 25.7409 9.83827 24.64 10.527 23.9705L17.167 17.5113C17.7461 16.9444 18.6417 16.8557 19.327 17.2992L23.0035 19.6835L28.6574 12.8087C29.2678 12.0679 30.3652 11.9618 31.1061 12.5705C31.847 13.1809 31.9531 14.2748 31.3426 15.0174Z" fill="black" fill-opacity="0.2"/>
                <path d="M37.8261 7.82609H3.91304C1.75478 7.82609 0 6.0713 0 3.91304C0 1.75478 1.75478 0 3.91304 0H37.8261C39.9843 0 41.7391 1.75478 41.7391 3.91304C41.7391 6.0713 39.9843 7.82609 37.8261 7.82609Z" fill="black" fill-opacity="0.2"/>
              </svg>
              <p class="color-6">We strive<br/>to make a meaningful difference</p>
            </div>
          </div>
        </div>
        <div class="row mt-5 text-block-2 res-sec">
          <div class="col-md-6 h-block h-block-left">
            <div class="p-5 h-block-text text-right" data-aos="fade-right">
              <h3 class="font-weight-semibold font-asap">Sustainable employer</h3>
              <p class="color-6 font-roboto font-weight-normal">At Doodle, we have a work environment where our team members can be creative and have the liberty to dream big. We take every word of our team members for granted and make them feel a part of a family where they have an important role to play. Hence, we have a low employee turnover as most of our team members are willing to remain a part of Doodle at present and even in future. </p>
            </div>
            <img src="images/blog-img05.jpg" alt=""/>
          </div>
          <div class="col-md-6 h-block h-block-right">
            <div class="p-5 h-block-text text-left" data-aos="fade-left">
              <h3 class="font-weight-semibold font-asap">Ethics and business integrity</h3>
              <p class="color-6 font-roboto font-weight-normal">Doodles success in the international market is a result of demonstrating highest level of integrity and ethics in every business interaction. We are committed to maintain the highest standard of ethical conduct in all our business activities. We ensure total compliance with laws and regulation international y. Our approach is transparent with no hidden terms and conditions related to any of our services or contracts.</p>
            </div>
            <img src="images/blog-img06.jpg" alt=""/>
          </div>
        </div>
      </div>
    </section>



    {/* <!--White Section with Shadow--> */}
    {/* <section class="center-section section-white box-shadow">
      <div class="container">
        <div class="row section-title-top justify-content-center">
          <div class="col-lg-10 text-center">
            <div class="highlight-black text-center mb-3 font-weight-semibold text-size-13" data-aos="fade-down">Working Culture</div>
            <h2 data-aos="fade-up">Initiatives <span class="highlight-orange">We Are Proud</span> of</h2>
            <p class="color-9" data-aos="fade-down">In volutpat felis faucibus odio sit velit eget lobortis platea. Arcu magna nullam sagittis urna, scelerisque fermentum, a. At ut ultrices urna, suscipit mattis. Nisl at scelerisque venenatis netus augue. Urna, lectus cras volutpat in.</p>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-4" data-aos="fade-right">
            <div class="fade-blue p-5">
              <h5 class="color-4 mt-3 font-weight-bold">Doodle Academy </h5>
              <p class="color-6 mt-3 mb-4">No matter how deep, complex, or futuristic your aims are, our team members enjoy learning about your requirements and always there to entertain your request. Doodle is a platform where.</p>
              <img class="w-100" src="images/daa01.png" alt=""/>
            </div>
          </div>
          <div class="col-lg-4" data-aos="fade-down">
            <div class="fade-green p-5">
              <h5 class="color-4 mt-3 font-weight-bold">Doodle Academy</h5>
              <p class="color-6 mt-3 mb-4">No matter how deep, complex, or futuristic your aims are, our team members enjoy learning about your requirements and always there to entertain your request. Doodle is a platform where.</p>
              <img class="w-100" src="images/daa01.png" alt=""/>
            </div>
          </div>
          <div class="col-lg-4" data-aos="fade-left">
            <div class="fade-orange p-5">
              <h5 class="color-4 mt-3 font-weight-bold">Doodle Academy</h5>
              <p class="color-6 mt-3 mb-4">No matter how deep, complex, or futuristic your aims are, our team members enjoy learning about your requirements and always there to entertain your request. Doodle is a platform where.</p>
              <img class="w-100" src="images/daa01.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section> */}








   
      <Footer />
    </div>
  );
}

export default ResponsibleContribution;
