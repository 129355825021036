import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SideVideo from './video/SideVideo'
import HeadlineSlider from './slider/HeadlineSlider'
import MenuSlider from './slider/MenuSlider'
import HomeLeftSlider from './slider/HomeLeftSlider'
import { GetHomeEn, GetHomeBn } from './../actions/homeAction';
import AOS from 'aos';
import Footer from './Footer';


class Portfolio extends React.Component {
  componentDidMount = () => {
    var element = document.getElementById("blogPage");
    element.classList.remove("active");
    this.props.GetHomeEn().then(res => {
      console.log(res);
    })
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  }
  render() {
    return (
      <div>
        {/*Background image section*/}
        <section className="center-section pt-0 pb-0 section-white">
          <div className="bg-image-section" style={{ background: 'url(images/portfolio-bg.png) no-repeat top center', backgroundSize: 'cover' }}>
            <div className="img-overly1 pt-5 pb-5 center-section">
              <div className="container pt-5 pb-5">
                <div className="row justify-content-center pt-4 pb-4">
                  <div className="col-lg-9 pt-4 pb-4 text-center">
                    <div className="text-wrapper p-wrapper">
                      <h1 className="text-white text-center">Portfolios and Case Studies</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section section-white pt-0">
          <div className="container">
            <div className="general-tab">
              <div className="col-md-12 pl-0">
                <ul className="nav p-0 nav-tabs justify-content-center text-wrapper p-wrapper" id="myTab" role="tablist">
                  <li className="p-0 nav-item">
                    <a className="nav-link text-center active ex-pd" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
                      All
                    </a>
                  </li>
                  <li className="p-0 nav-item">
                    <a className="nav-link text-center ex-pd" id="web-tab" data-toggle="tab" href="#web" role="tab" aria-controls="web" aria-selected="true">
                      Web
                    </a>
                  </li>
                  <li className="p-0 nav-item">
                    <a className="nav-link text-center ex-pd" id="android-tab" data-toggle="tab" href="#android" role="tab" aria-controls="android" aria-selected="true">
                      Android
                    </a>
                  </li>
                  <li className="p-0 nav-item">
                    <a className="nav-link text-center ex-pd" id="ios-tab" data-toggle="tab" href="#ios" role="tab" aria-controls="ios" aria-selected="true">
                      IOS Apps
                    </a>
                  </li>
                  <li className="p-0 nav-item">
                    <a className="nav-link text-center ex-pd" id="cloud-tab" data-toggle="tab" href="#cloud" role="tab" aria-controls="cloud" aria-selected="true">
                      Cloud
                    </a>
                  </li>
                  <li className="p-0 nav-item">
                    <a className="nav-link text-center ex-pd" id="e-commerce-tab" data-toggle="tab" href="#e-commerce" role="tab" aria-controls="e-commerce" aria-selected="true">
                      E-Commerce
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content portfolio-tab-content" id="myTabContent">
                <div className="tab-pane fade show text-left active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className="row m-0">
                    <div className="col-md-6 text-center pb-3 mb-5">
                      <div className="p-img-section">
                        <img src="images/portfolio-1.jpg" alt="" />
                        <div className="view-project">
                          <div className="btn-content" data-toggle="modal" data-target="#portfolio1">
                            <svg width={43} height={43} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="21.5" cy="21.5" r="21.5" fill="#FF8135" />
                              <path d="M21.5 4C11.8506 4 4 11.8506 4 21.5C4 31.1494 11.8506 39 21.5 39C31.1494 39 39 31.1494 39 21.5C39 11.8506 31.1493 4 21.5 4ZM30.25 23.6875H23.6875V30.25H19.3125V23.6875H12.75V19.3125H19.3125V12.75H23.6875V19.3125H30.25V23.6875Z" fill="white" />
                            </svg>
                            <h4 className="highlight-orange pt-2 font-weight-semibold">View Project</h4>
                          </div>
                        </div>
                      </div>
                      {/* Modal */}
                      <div className="modal fade extra-backdrop" id="portfolio1" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="portfolio1Label" aria-hidden="true">
                        <div className="modal-dialog custom-w-p-modal">
                          <div className="modal-content">
                            <div className="modal-header pb-0">
                              <h3 className="modal-title text-white" id="portfolio1Label">Foodla - Food Delivery App Design</h3>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.1083 14.0145C17.1083 13.6657 16.9688 13.317 16.7176 13.0658L12.6161 8.96429L16.7176 4.86272C16.9688 4.61161 17.1083 4.26283 17.1083 3.91406C17.1083 3.56529 16.9688 3.21652 16.7176 2.9654L14.8203 1.06808C14.5692 0.816963 14.2204 0.677454 13.8717 0.677454C13.5229 0.677454 13.1741 0.816963 12.923 1.06808L8.82143 5.16964L4.71987 1.06808C4.46875 0.816963 4.11998 0.677454 3.77121 0.677454C3.42243 0.677454 3.07366 0.816963 2.82254 1.06808L0.925223 2.9654C0.674107 3.21652 0.534598 3.56529 0.534598 3.91406C0.534598 4.26283 0.674107 4.61161 0.925223 4.86272L5.02679 8.96429L0.925223 13.0658C0.674107 13.317 0.534598 13.6657 0.534598 14.0145C0.534598 14.3633 0.674107 14.7121 0.925223 14.9632L2.82254 16.8605C3.07366 17.1116 3.42243 17.2511 3.77121 17.2511C4.11998 17.2511 4.46875 17.1116 4.71987 16.8605L8.82143 12.7589L12.923 16.8605C13.1741 17.1116 13.5229 17.2511 13.8717 17.2511C14.2204 17.2511 14.5692 17.1116 14.8203 16.8605L16.7176 14.9632C16.9688 14.7121 17.1083 14.3633 17.1083 14.0145Z" fill="white" />
                                </svg>
                              </button>
                            </div>
                            <div className="modal-body pt-1">
                              <div className="row">
                                <div className="col-10">
                                  <div className="p-top-caption">
                                    <p className="highlight-orange text-left float-left d-inline">WordPress</p>
                                    <a className="text-white text-right float-right d-inline" href="#">
                                      Visit Site
                                      <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.2858 8.14264H11.7143C11.6308 8.14264 11.5623 8.16938 11.509 8.2231C11.4553 8.27652 11.4286 8.34497 11.4286 8.42835V11.2855C11.4286 11.6783 11.2888 12.0146 11.009 12.2944C10.7293 12.5741 10.3929 12.7139 10.0001 12.7139H2.57138C2.17853 12.7139 1.84219 12.5741 1.56239 12.2944C1.28266 12.0146 1.1428 11.6784 1.1428 11.2855V3.85689C1.1428 3.46404 1.28262 3.12783 1.56239 2.84806C1.84219 2.56827 2.17853 2.42845 2.57138 2.42845H8.85703C8.9405 2.42845 9.00899 2.40158 9.06249 2.34807C9.116 2.2945 9.14274 2.22611 9.14274 2.14271V1.5712C9.14274 1.48795 9.116 1.41943 9.06249 1.36595C9.00899 1.31238 8.9405 1.28564 8.85703 1.28564H2.57138C1.86311 1.28564 1.25742 1.53714 0.754364 2.0401C0.251496 2.54303 0 3.14872 0 3.85699V11.2856C0 11.9938 0.251496 12.5996 0.754395 13.1024C1.25745 13.6054 1.86314 13.857 2.57141 13.857H10.0001C10.7083 13.857 11.314 13.6054 11.817 13.1024C12.3201 12.5996 12.5716 11.9939 12.5716 11.2856V8.42857C12.5716 8.3451 12.5448 8.27661 12.4911 8.2231C12.4375 8.16938 12.369 8.14264 12.2858 8.14264Z" fill="white" />
                                        <path d="M15.8303 0.31269C15.7172 0.199576 15.5831 0.143066 15.4284 0.143066H10.857C10.7023 0.143066 10.5683 0.199576 10.4553 0.31269C10.3421 0.425741 10.2855 0.559682 10.2855 0.714451C10.2855 0.86922 10.3421 1.00319 10.4553 1.11631L12.0266 2.6877L6.20528 8.50916C6.14568 8.56867 6.11597 8.63716 6.11597 8.71441C6.11597 8.79193 6.14577 8.86039 6.20528 8.9199L7.22315 9.93764C7.28266 9.99715 7.35115 10.0268 7.42852 10.0268C7.50589 10.0268 7.57441 9.99718 7.63389 9.93764L13.4553 4.11627L15.0267 5.68767C15.1398 5.80078 15.2737 5.85735 15.4285 5.85735C15.5833 5.85735 15.7173 5.80078 15.8304 5.68767C15.9435 5.57459 16 5.44068 16 5.28591V0.714451C16 0.559589 15.9433 0.425741 15.8303 0.31269Z" fill="white" />
                                      </svg>
                                    </a>
                                  </div>
                                </div>
                                <div className="col-10">
                                  <div className="p-min-content pl-5 pb-1 pr-5 pt-5">
                                    <p className="pt-3">Tempor sed id vitae arcu nunc senectus nec, ut. At turpis malesuada elementum pellentesque. Eget nunc, est imperdiet ultricies <br />ridiculus in cursus id consectetur. Gravida enim, est sem id tristique orci, sit. Id a suspendisse cursus a vel vitae consequat. Sit non et<br /> diam lacus, sed eget dignissim. Velit, a in lectus mauris tristique ac sed. Viverra posuere id orci ut congue feugiat.</p>
                                    <p className="highlight-orange font-weight-semibold pt-3">Major Technologies</p>
                                    <button type="button" className="btn btn-light tag-btn">PHP</button>
                                    <button type="button" className="btn btn-light tag-btn">JavaScript</button>
                                    <button type="button" className="btn btn-light tag-btn">Larvel</button>
                                    <button type="button" className="btn btn-light tag-btn">PHP</button>
                                    <button type="button" className="btn btn-light tag-btn">JavaScript</button>
                                    <button type="button" className="btn btn-light tag-btn">Larvel</button>
                                  </div>
                                  <div className="details-img">
                                    <img src="images/portfolio-details-01.png" alt="" />
                                    <img src="images/portfolio-details-02.png" alt="" />
                                    <img src="images/portfolio-details-03.png" alt="" />
                                    <img style={{ marginTop: '-1px' }} src="images/portfolio-details-04.png" alt="" />
                                    <img src="images/portfolio-details-05.png" alt="" />
                                    <img src="images/portfolio-details-06.png" alt="" />
                                  </div>
                                </div>
                                <div className="col-2 right-sidebar text-left pt-3">
                                  <p className="highlight-orange mb-0">Client</p>
                                  <p className="text-white">Facebook Inc</p>
                                  <p className="highlight-orange mt-5 mb-0">Project Type</p>
                                  <p className="text-white">Expert Slotuion</p>
                                  <p className="highlight-orange mt-5 mb-0">Services</p>
                                  <p className="text-white mb-0"><span className="p-bullet-point" />UI</p>
                                  <p className="text-white mb-0"><span className="p-bullet-point" />Full Stack Development</p>
                                  <p className="text-white mb-0"><span className="p-bullet-point" />IOS App</p>
                                  <p className="highlight-orange mt-5 mb-2">Resources Used</p>
                                  <p className="text-white mb-2"><span className="p-count-point">1</span>Designer</p>
                                  <p className="text-white mb-2"><span className="p-count-point">2</span>Developer</p>
                                  <p className="text-white mb-2"><span className="p-count-point">3</span>QA</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h4 className="pt-3 font-weight-semibold mb-0">Foodla - Food Delivery App Design</h4>
                      <p className="highlight-orange">Web</p>
                      <button type="button" className="btn btn-light tag-btn">PHP</button>
                      <button type="button" className="btn btn-light tag-btn">JavaScript</button>
                      <button type="button" className="btn btn-light tag-btn">Larvel</button>
                    </div>
                    <div className="col-md-6 text-center pb-3 mb-5">
                      <div className="p-img-section">
                        <img src="images/portfolio-2.jpg" alt="" />
                        <div className="view-project">
                          <div className="btn-content" data-toggle="modal" data-target="#portfolio1">
                            <svg width={43} height={43} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="21.5" cy="21.5" r="21.5" fill="#FF8135" />
                              <path d="M21.5 4C11.8506 4 4 11.8506 4 21.5C4 31.1494 11.8506 39 21.5 39C31.1494 39 39 31.1494 39 21.5C39 11.8506 31.1493 4 21.5 4ZM30.25 23.6875H23.6875V30.25H19.3125V23.6875H12.75V19.3125H19.3125V12.75H23.6875V19.3125H30.25V23.6875Z" fill="white" />
                            </svg>
                            <h4 className="highlight-orange pt-2 font-weight-semibold">View Project</h4>
                          </div>
                        </div>
                      </div>
                      <h4 className="pt-3 font-weight-semibold mb-0">Foodla - Food Delivery App Design</h4>
                      <p className="highlight-orange">Web</p>
                      <button type="button" className="btn btn-light tag-btn">PHP</button>
                      <button type="button" className="btn btn-light tag-btn">JavaScript</button>
                      <button type="button" className="btn btn-light tag-btn">Larvel</button>
                    </div>
                    <div className="col-md-6 text-center pb-3 mb-5">
                      <div className="p-img-section">
                        <img src="images/portfolio-3.jpg" alt="" />
                        <div className="view-project">
                          <div className="btn-content" data-toggle="modal" data-target="#portfolio1">
                            <svg width={43} height={43} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="21.5" cy="21.5" r="21.5" fill="#FF8135" />
                              <path d="M21.5 4C11.8506 4 4 11.8506 4 21.5C4 31.1494 11.8506 39 21.5 39C31.1494 39 39 31.1494 39 21.5C39 11.8506 31.1493 4 21.5 4ZM30.25 23.6875H23.6875V30.25H19.3125V23.6875H12.75V19.3125H19.3125V12.75H23.6875V19.3125H30.25V23.6875Z" fill="white" />
                            </svg>
                            <h4 className="highlight-orange pt-2 font-weight-semibold">View Project</h4>
                          </div>
                        </div>
                      </div>
                      <h4 className="pt-3 font-weight-semibold mb-0">Foodla - Food Delivery App Design</h4>
                      <p className="highlight-orange">Web</p>
                      <button type="button" className="btn btn-light tag-btn">PHP</button>
                      <button type="button" className="btn btn-light tag-btn">JavaScript</button>
                      <button type="button" className="btn btn-light tag-btn">Larvel</button>
                    </div>
                    <div className="col-md-6 text-center  pb-3 mb-5">
                      <div className="p-img-section">
                        <img src="images/portfolio-4.jpg" alt="" />
                        <div className="view-project">
                          <div className="btn-content" data-toggle="modal" data-target="#portfolio1">
                            <svg width={43} height={43} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="21.5" cy="21.5" r="21.5" fill="#FF8135" />
                              <path d="M21.5 4C11.8506 4 4 11.8506 4 21.5C4 31.1494 11.8506 39 21.5 39C31.1494 39 39 31.1494 39 21.5C39 11.8506 31.1493 4 21.5 4ZM30.25 23.6875H23.6875V30.25H19.3125V23.6875H12.75V19.3125H19.3125V12.75H23.6875V19.3125H30.25V23.6875Z" fill="white" />
                            </svg>
                            <h4 className="highlight-orange pt-2 font-weight-semibold">View Project</h4>
                          </div>
                        </div>
                      </div>
                      <h4 className="pt-3 font-weight-semibold mb-0">Foodla - Food Delivery App Design</h4>
                      <p className="highlight-orange">Web</p>
                      <button type="button" className="btn btn-light tag-btn">PHP</button>
                      <button type="button" className="btn btn-light tag-btn">JavaScript</button>
                      <button type="button" className="btn btn-light tag-btn">Larvel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*----------Footer---------*/}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Portfolio)