import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SideVideo from './video/SideVideo'
import HeadlineSlider from './slider/HeadlineSlider'
import MenuSlider from './slider/MenuSlider'
import HomeLeftSlider from './slider/HomeLeftSlider'
import { GetHomeEn, GetHomeBn } from './../actions/homeAction';
import AOS from 'aos';
import Newsletter from './newsletter/Newsletter';
import Footer from './Footer';



class Engagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoName: ''
    }
  }
  componentDidMount = () => {
    // var element = document.getElementById("blogPage");
    // element.classList.remove("active");

    this.props.GetHomeEn().then(res => {
      //console.log(res[0].emp_name);

    })
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  }
  render() {
    return (
      <div>
      {/*-Banner section-*/}
      <section className="banner" style={{ background: 'url(images/services-banner.png) no-repeat bottom', backgroundSize: 'cover' }}>
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">Engagement Models</h1>
              </div>
              <p className="text-white pl-lg-0 pl-4 pr-lg-0 pr-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br/> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
            </div>
          </div>
          {/* <a className="mt-5 arrow-down mb-5" href="#makingtheworl">
            <svg width={20} height={37} viewBox="0 0 20 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1.63028C5.40498 1.63028 1.66667 5.28699 1.66667 9.78168V27.2186C1.66667 31.7133 5.40498 35.37 10 35.37C14.595 35.37 18.3333 31.7133 18.3333 27.2186V9.78168C18.3333 5.28699 14.595 1.63028 10 1.63028ZM10 0C15.5229 0 20 4.37941 20 9.78168V27.2186C20 32.6208 15.5229 37.0003 10 37.0003C4.47715 37.0003 0 32.6208 0 27.2186V9.78168C0 4.37941 4.47715 0 10 0Z" fill="white" />
              <path d="M10 15.3106C13.1218 15.3106 15.6525 12.8352 15.6525 9.78153C15.6525 6.7279 13.1218 4.25244 10 4.25244C6.87824 4.25244 4.34753 6.7279 4.34753 9.78153C4.34753 12.8352 6.87824 15.3106 10 15.3106Z" fill="white" />
            </svg>
          </a> */}
        </div>
      </section>
        
        
      <section className="center-section pt-3 pb-3 section-black">
          <div className="container">
            <div className="row pt-3 pb-3 text-center">
              <div className="col-lg-5 ml-auto">
                <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                
              </div>
              <div className="col-lg-5 mobile-hide-only mr-auto">
                <div className="counter-item">
                  <img src="images/circle-img01.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      
      <section className="section-gray pt-5 pb-5">
        <div className="container">
          <div className="row section-title-top pt-5 justify-content-center" data-aos="fade-down">
            <div className="col-lg-12 text-center">
              <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's<br/> standard dummy text ever since the 1500s</p>
            </div>
          </div>
          <div className="row pt-5 pb-5 justify-content-center">
            <div className="col-12 col-lg-4 text-center">
              <span className="box-block-count"><img className="serviceimg-normal" src="images/services-test-img.png" /></span>
              <h3 className="mt-3 color-4 line-height-29 mb-4">Project-Based <br/>Engagement</h3>
              <p className="mt-3 color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
standard dummy text ever since the 1500s</p>
            </div>
            <div className="col-12 col-lg-4 text-center">
              <span className="box-block-count"><img className="serviceimg-normal" src="images/services-test-img.png" /></span>
              <h3 className="mt-3 color-4 line-height-29 mb-4">Dedicated <br/>Development Team</h3>
              <p className="mt-3 color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
standard dummy text ever since the 1500s</p>
            </div>
            <div className="col-12 col-lg-4 text-center">
              <span className="box-block-count"><img className="serviceimg-normal" src="images/services-test-img.png" /></span>
              <h3 className="mt-3 color-4 line-height-29 mb-4">SLA-Based <br/>Approach</h3>
              <p className="mt-3 color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
standard dummy text ever since the 1500s</p>
            </div>
          </div>
        </div>
      </section>



      
      <section className="center-section pt-0 section-white">
        <div className="container">
          <div className="row section-title-top pt-5 justify-content-center" data-aos="fade-down">
            <div className="col-lg-12 text-center">
              <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's<br/> standard dummy text ever since the 1500s</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mb-5 mb-lg-0 pl-sm-3 pl-lg-0 pr-lg-0 pr-sm-3 h-block1 h-block1-left">
              <img src="images/corporate.jpg" alt="" />
              <div className="pr-5 mt-4 mt-lg-0 float-left h-block1-text text-left" data-aos="fade-left">
                <span className="box-block-count font-size-50">01.</span>
                <h3 className="font-weight-semibold mt-3 mb-3 font-asap">Fixed Price</h3>
                <p className="color-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
            <div className="col-md-12 mt-lg-0 mb-5 mb-lg-0 pl-sm-3 pl-lg-0 pr-lg-0 pr-sm-3 h-block1 h-block1-right">
              <img src="images/corporate1.jpg" alt="" />
              <div className="pl-5 mt-4 mt-lg-0 float-right h-block1-text text-right" data-aos="fade-right">
                <span className="box-block-count font-size-50">02.</span>
                <h3 className="font-weight-semibold mt-3 mb-3 font-asap">Time and Material</h3>
                <p className="color-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
            <div className="col-md-12 pl-sm-3 pl-lg-0 pr-lg-0 pr-sm-3 h-block1 h-block1-left">
              <img src="images/corporate2.jpg" alt="" />
              <div className="pr-5 mt-4 mt-lg-0 float-left h-block1-text text-left" data-aos="fade-left">
                <span className="box-block-count font-size-50">03.</span>
                <h3 className="font-weight-semibold mt-3 mb-3 font-asap">Milestone Billing</h3>
                <p className="color-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
          </div>
        </div>
        <span className="effect-bg-top-left" />
      </section>


      
      <section className="center-section section-black pt-0 pb-0 box-shadow"  style={{ background: 'url(images/contact-bg.png)' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 bg-black ex-pt">
              <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
                <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                  <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon01.png" alt="..." />
                  </div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon02.png" alt="..." />
                  </div>
                </div>
                <div className="col-lg-12 mr-auto">
                  <div className="counter-item">
                    <img src="images/circle-img01.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 bg-white ex-pt">
              <div className="row p-5 pl-lg-5 section-title-top" data-aos="fade-down">
                <div className="col-lg-12 pl-lg-5 text-left">
                  <h2>Tell Us About <span className="highlight-orange">Your Project</span></h2>
                </div>
                <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                  <form className="row">
                    <div className="form-group  mb-4 col-lg-6">
                      <label>Name</label>
                      <input type="text" className="form-control form-control-lg" placeholder="John Smith" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>E-mail</label>
                      <input type="email" className="form-control form-control-lg" placeholder="example@provider.com" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Phone</label>
                      <input type="phone" className="form-control form-control-lg" placeholder="+1 112 222 333" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Company</label>
                      <input type="text" className="form-control form-control-lg" placeholder="Ex: Pexel Inc." />
                    </div>
                    <div className="form-group mb-4 col-lg-12">
                      <label>Messages...</label>
                      <textarea className="form-control" placeholder="Hello..."></textarea>
                    </div>
                    <div className="form-group col-lg-12">
                      <button type="submit" className="btn btn-primary btn-lg"><span>Send Now</span></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*----------Footer---------*/}
      <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Engagement)
