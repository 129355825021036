import React from "react";

function AwardAndCertifications() {
  return (
    <section className="center-section pt-3 pb-3 section-black">
      <div className="container">
        <div className="row pt-3 pb-3 text-center">
          <div className="col-lg-5 ml-auto">
            <div className="award-text">
              <span className="award-type">Award</span>{" "}
              <span className="award-and">&</span> Certifications
            </div>
            <div className="iso-icon">
              <img src="images/ISO_icon01.png" alt="..." />
            </div>
            <div className="iso-icon">
              <img src="images/ISO_icon02.png" alt="..." />
            </div>
          </div>
          <div className="col-lg-5 mobile-hide-only mr-auto">
            <div className="counter-item">
              <img src="images/circle-img01.png" alt="..." />
              <div className="cn_01">
                <p>
                  <span className="c_number_01">9</span>
                  <span className="c_text_01">YEARS</span>IN OPERATION
                </p>
              </div>
            </div>
            <div className="counter-item">
              <img src="images/circle-img02.png" alt="..." />
              <div className="cn_01">
                <p>
                  <span className="c_number_02">100s</span>
                  <span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE
                </p>
              </div>
            </div>
            <div className="counter-item">
              <img src="images/circle-img02.png" alt="..." />
              <div className="cn_01">
                <p>
                  <span className="c_number_02">4.9/5</span>
                  <span className="c_text_01">CUSTOMERS</span>RATING
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AwardAndCertifications;
