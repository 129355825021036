import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SideVideo from './video/SideVideo'
import HeadlineSlider from './slider/HeadlineSlider'
import MenuSlider from './slider/MenuSlider'
import HomeLeftSlider from './slider/HomeLeftSlider'
import { GetHomeEn, GetHomeBn } from './../actions/homeAction';
import AOS from 'aos';
import Footer from './Footer';


class DedicatedOffshoreTeam extends React.Component {
  componentDidMount = () => {
    var element = document.getElementById("blogPage");
    element.classList.remove("active");
    this.props.GetHomeEn().then(res => {
      console.log(res);
    })
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  }
  render() {
    return (
      <div>
        {/*-Banner section-*/}
        <section className="banner" style={{ background: 'url(images/first-banner-01.jpg) no-repeat bottom', backgroundSize: 'cover' }}>
          <div className="img-overly">
            <div className="container-lg">
              <div className="banner-title-section doft-ptb">
                <div className="text-wrapper p-wrapper">
                  <h1 className="text-center text-white">Enabling you to do more<br />with our offshore dedicated team</h1>
                </div>
                <div className="list-group list-group-horizontal-lg justify-content-center text-wrapper">
                  <p className="list-group-item custom-ts-01">Web</p>
                  <span className="bullet-point" />
                  <p className="list-group-item custom-ts-01">Mobile</p>
                  <span className="bullet-point" />
                  <p className="list-group-item custom-ts-01">Cloud</p>
                </div>
              </div>
              <div className="banner-solution-section text-wrapper">
                <h4 className="text-center text-white">Bringing in Effective Solution for Those Who are -</h4>
                <div className="row">
                  <div className="col-sm">
                    <div className="box-item text-center text-white">
                      <img src="images/watch.png" alt="" />
                      <p>Experiencing the lack of specific in-house expertise</p>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="box-item text-center text-white">
                      <img src="images/settings.png" alt="" />
                      <p>Unable to find suitable local talent in time</p>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="box-item text-center text-white">
                      <img src="images/watch.png" alt="" />
                      <p>In need of some extra resource for growth purpose</p>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="box-item text-center text-white">
                      <img src="images/settings.png" alt="" />
                      <p>Looking to minimise operational expenditure</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Highlight Section*/}
        <section className="highlight-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-auto" data-aos="fade-right">
                <h4 className="text-white">What is an ‘Offshore Dedicated Team’?</h4>
              </div>
              <div className="col-md-auto" data-aos="fade-left">
                <span className="v-separator" />
                <button type="button" className="btn btn-outline-light btn-lg" data-toggle="collapse" data-target="#OffshoreDedicatedTeam" aria-expanded="false" aria-controls="OffshoreDedicatedTeam">
                  Expand
                  <svg className="bi bi-chevron-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path stroke="white" strokeWidth={1} fillRule="evenodd" d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
              <div className="collapse" id="OffshoreDedicatedTeam">
                <div className="card card-body">
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section section-white">
          <div className="container">
            <div className="row section-title-top justify-content-center" data-aos="fade-down">
              <div className="col-lg-10">
                <h2 className="text-center">How Does <span className="highlight-orange">it Work?</span></h2>
                <p className="text-center">With Doodle, recruiting offshore teams is more flexible. With our years of experience, we've developed a process that will make every step of your development journey, from hiring to getting the job done, a lot easier, faster, transparent and efficient.</p>
              </div>
            </div>
            <div className="row icon-with-tab white-tab" data-aos="fade-up">
              <ul className="nav col-12 p-0 nav-tabs" id="myTab" role="tablist">
                <li className="col-md p-0 nav-item">
                  <a className="nav-link text-center active" id="Discovery-tab" data-toggle="tab" href="#Discovery" role="tab" aria-controls="Discovery" aria-selected="true">
                    <svg width={81} height={65} viewBox="0 0 81 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M76.0435 40.8616H71.3105V38.1795C71.3105 36.8701 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.8701 47.6455 38.1795V57.9004C47.6455 61.813 50.8324 64.9999 54.745 64.9999H64.211C67.9816 64.9999 71.0739 62.0339 71.2947 58.3106C76.6115 57.7584 80.7765 53.262 80.7765 47.8033V45.5946C80.7765 42.9757 78.6467 40.8616 76.0435 40.8616ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0254 71.3105 53.5302V45.5946H76.0435V47.8033Z" fill="#FF8235" />
                      <path d="M31.9477 4.733C33.2572 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2572 0 31.9477 0C30.6383 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6383 4.733 31.9477 4.733Z" fill="black" fillOpacity="0.2" />
                      <path d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 40.5465H0V44.964C0 46.2734 1.05704 47.3305 2.3665 47.3305H42.9125V40.5465Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 52.063H20.9829V55.6916H16.0921C14.7827 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7827 60.4246 16.0921 60.4246H43.1807C43.0071 59.6042 42.9125 58.7681 42.9125 57.9004V52.063Z" fill="black" fillOpacity="0.2" />
                    </svg>
                    <p>Discovery &amp;<br /> Project Understandin</p>
                  </a>
                </li>
                <li className="col-md p-0 nav-item">
                  <a className="nav-link text-center" id="Discovery-tab" data-toggle="tab" href="#Discovery" role="tab" aria-controls="Discovery" aria-selected="true">
                    <svg width={81} height={65} viewBox="0 0 81 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M76.0435 40.8616H71.3105V38.1795C71.3105 36.8701 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.8701 47.6455 38.1795V57.9004C47.6455 61.813 50.8324 64.9999 54.745 64.9999H64.211C67.9816 64.9999 71.0739 62.0339 71.2947 58.3106C76.6115 57.7584 80.7765 53.262 80.7765 47.8033V45.5946C80.7765 42.9757 78.6467 40.8616 76.0435 40.8616ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0254 71.3105 53.5302V45.5946H76.0435V47.8033Z" fill="#FF8235" />
                      <path d="M31.9477 4.733C33.2572 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2572 0 31.9477 0C30.6383 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6383 4.733 31.9477 4.733Z" fill="black" fillOpacity="0.2" />
                      <path d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 40.5465H0V44.964C0 46.2734 1.05704 47.3305 2.3665 47.3305H42.9125V40.5465Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 52.063H20.9829V55.6916H16.0921C14.7827 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7827 60.4246 16.0921 60.4246H43.1807C43.0071 59.6042 42.9125 58.7681 42.9125 57.9004V52.063Z" fill="black" fillOpacity="0.2" />
                    </svg>
                    <p>Resource<br /> unification &amp; quotation</p>
                  </a>
                </li>
                <li className="col-md p-0 nav-item">
                  <a className="nav-link text-center" id="Discovery-tab" data-toggle="tab" href="#Discovery" role="tab" aria-controls="Discovery" aria-selected="true">
                    <svg width={81} height={65} viewBox="0 0 81 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M76.0435 40.8616H71.3105V38.1795C71.3105 36.8701 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.8701 47.6455 38.1795V57.9004C47.6455 61.813 50.8324 64.9999 54.745 64.9999H64.211C67.9816 64.9999 71.0739 62.0339 71.2947 58.3106C76.6115 57.7584 80.7765 53.262 80.7765 47.8033V45.5946C80.7765 42.9757 78.6467 40.8616 76.0435 40.8616ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0254 71.3105 53.5302V45.5946H76.0435V47.8033Z" fill="#FF8235" />
                      <path d="M31.9477 4.733C33.2572 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2572 0 31.9477 0C30.6383 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6383 4.733 31.9477 4.733Z" fill="black" fillOpacity="0.2" />
                      <path d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 40.5465H0V44.964C0 46.2734 1.05704 47.3305 2.3665 47.3305H42.9125V40.5465Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 52.063H20.9829V55.6916H16.0921C14.7827 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7827 60.4246 16.0921 60.4246H43.1807C43.0071 59.6042 42.9125 58.7681 42.9125 57.9004V52.063Z" fill="black" fillOpacity="0.2" />
                    </svg>
                    <p>Setup &amp;<br /> meet your team</p>
                  </a>
                </li>
                <li className="col-md p-0 nav-item">
                  <a className="nav-link text-center" id="Discovery-tab" data-toggle="tab" href="#Discovery" role="tab" aria-controls="Discovery" aria-selected="true">
                    <svg width={81} height={65} viewBox="0 0 81 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M76.0435 40.8616H71.3105V38.1795C71.3105 36.8701 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.8701 47.6455 38.1795V57.9004C47.6455 61.813 50.8324 64.9999 54.745 64.9999H64.211C67.9816 64.9999 71.0739 62.0339 71.2947 58.3106C76.6115 57.7584 80.7765 53.262 80.7765 47.8033V45.5946C80.7765 42.9757 78.6467 40.8616 76.0435 40.8616ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0254 71.3105 53.5302V45.5946H76.0435V47.8033Z" fill="#FF8235" />
                      <path d="M31.9477 4.733C33.2572 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2572 0 31.9477 0C30.6383 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6383 4.733 31.9477 4.733Z" fill="black" fillOpacity="0.2" />
                      <path d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 40.5465H0V44.964C0 46.2734 1.05704 47.3305 2.3665 47.3305H42.9125V40.5465Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 52.063H20.9829V55.6916H16.0921C14.7827 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7827 60.4246 16.0921 60.4246H43.1807C43.0071 59.6042 42.9125 58.7681 42.9125 57.9004V52.063Z" fill="black" fillOpacity="0.2" />
                    </svg>
                    <p>Execution &amp;<br /> timeless monitoring</p>
                  </a>
                </li>
                <li className="col-md p-0 nav-item">
                  <a className="nav-link text-center" id="Discovery-tab" data-toggle="tab" href="#Discovery" role="tab" aria-controls="Discovery" aria-selected="true">
                    <svg width={81} height={65} viewBox="0 0 81 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M76.0435 40.8616H71.3105V38.1795C71.3105 36.8701 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.8701 47.6455 38.1795V57.9004C47.6455 61.813 50.8324 64.9999 54.745 64.9999H64.211C67.9816 64.9999 71.0739 62.0339 71.2947 58.3106C76.6115 57.7584 80.7765 53.262 80.7765 47.8033V45.5946C80.7765 42.9757 78.6467 40.8616 76.0435 40.8616ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0254 71.3105 53.5302V45.5946H76.0435V47.8033Z" fill="#FF8235" />
                      <path d="M31.9477 4.733C33.2572 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2572 0 31.9477 0C30.6383 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6383 4.733 31.9477 4.733Z" fill="black" fillOpacity="0.2" />
                      <path d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 40.5465H0V44.964C0 46.2734 1.05704 47.3305 2.3665 47.3305H42.9125V40.5465Z" fill="black" fillOpacity="0.2" />
                      <path d="M42.9125 52.063H20.9829V55.6916H16.0921C14.7827 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7827 60.4246 16.0921 60.4246H43.1807C43.0071 59.6042 42.9125 58.7681 42.9125 57.9004V52.063Z" fill="black" fillOpacity="0.2" />
                    </svg>
                    <p>Delivery &amp;<br /> continual maintenance</p>
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent1">
                <div className="tab-pane fade show text-center active" id="Discovery" role="tabpanel" aria-labelledby="Discovery-tab">Understanding your vision means a lot to us. So, we start with getting the sense of the project, its dynamics as well as technological requirements s and most importantly your demands and expectations to perfectly outline the structure, specifications and probable duration of your project.  We start with getting the sense of the project, its dynamics as well as technological requirement</div>
              </div>
            </div>
          </div>
          <span className="effect-bg-top-left" />
        </section>
        {/*Black Section*/}
        <section className="center-section section-black">
          <div className="container">
            <div className="row pb-5">
              <div className="col-6 mt-4 pr-lg-5" data-aos="fade-right">
                <h2 className="text-left text-white">Here You get <br />The <span className="highlight-orange">Complete Authority</span> <br />to Manage Your Team</h2>
                <p className="text-left color-a">With us you are getting a truly dedicated team of experts which is<br /> exclusively yours with no other clients or priorities, so transparency<br /> stands at its best.</p>
              </div>
              <div className="col-6 pl-lg-5">
                <div className="row">
                  <div className="col-6 border-right border-bottom" data-aos="fade-right">
                    <div className="box-item text-center pt-0">
                      <img src="images/watch.png" alt="" />
                      <p>Manage the work schedule of your team</p>
                    </div>
                  </div>
                  <div className="col-6 border-bottom" data-aos="fade-left">
                    <div className="box-item text-center pt-0">
                      <img src="images/settings.png" alt="" />
                      <p>Keep your team as long as you need</p>
                    </div>
                  </div>
                  <div className="col-6 border-right" data-aos="fade-right">
                    <div className="box-item text-center pb-0">
                      <img src="images/settings.png" alt="" />
                      <p>Monitor daily work progress of each person</p>
                    </div>
                  </div>
                  <div className="col-6" data-aos="fade-left">
                    <div className="box-item text-center pb-0">
                      <img src="images/watch.png" alt="" />
                      <p>Make any changes anytime as required</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Background image section*/}
            <div className="bg-image-section mt-5" style={{ background: 'url(images/2nd-banner.jpg) no-repeat center', backgroundSize: 'cover' }}>
              <div className="img-overly center-section">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-9 text-center" data-aos="fade-up">
                      {/* <div class="highlight-orange">VIRTUAL MANAGEMENT PRACTICES <span class="highlight-rh-border-orange"></span></div> */}
                      <h3 className="text-white">We follow the best practices of virtual management to enable you to manage a team here as you manage an in-house team.</h3>
                      <a href="#" className="btn btn-primary btn-lg" role="button" aria-pressed="true"><span>Get a tour</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section section-white pb-0">
          <div className="container">
            <div className="row section-title-top justify-content-center">
              <div className="col">
                <h2 className="text-left">Whom You can <span className="highlight-orange">Hire?</span></h2>
                <p className="text-left">From our skilled pool of dedicated talents can build your team by only hiring in positions solely<br /> required to get the job done at its maximum efficiency.</p>
              </div>
            </div>
          </div>
          <span className="effect-bg-top-right" />
        </section>
        {/*White Section*/}
        <section className="center-section section-white pt-0 pb-0">
          <div className="general-tab doft-sec">
            <div className="col-md-7 offset-md-4">
              <ul className="nav p-0 nav-tabs" id="myTab" role="tablist" data-aos="fade-up">
                <li className="p-0 nav-item">
                  <a className="nav-link text-center" id="uiux-tab" data-toggle="tab" href="#uiux" role="tab" aria-controls="uiux" aria-selected="true">
                    UI/UX<br />Designer
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a className="nav-link text-center" id="uiux-tab" data-toggle="tab" href="#uiux" role="tab" aria-controls="uiux" aria-selected="true">
                    Front End<br />Developer
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a className="nav-link text-center active" id="uiux-tab" data-toggle="tab" href="#uiux" role="tab" aria-controls="uiux" aria-selected="true">
                    Backend<br />Developer
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a className="nav-link text-center" id="uiux-tab" data-toggle="tab" href="#uiux" role="tab" aria-controls="uiux" aria-selected="true">
                    Mobile<br />Technology
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a className="nav-link text-center" id="uiux-tab" data-toggle="tab" href="#uiux" role="tab" aria-controls="uiux" aria-selected="true">
                    Cloud<br />/DevOps
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a className="nav-link text-center" id="uiux-tab" data-toggle="tab" href="#uiux" role="tab" aria-controls="uiux" aria-selected="true">
                    QA<br />Expert
                  </a>
                </li>
                <li className="p-0 nav-item">
                  <a className="nav-link text-center" id="uiux-tab" data-toggle="tab" href="#uiux" role="tab" aria-controls="uiux" aria-selected="true">
                    SEO<br />Expert
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show text-left active" id="uiux" role="tabpanel" aria-labelledby="uiux-tab">
                <div className="row m-0">
                  <div className="col-md-4" data-aos="fade-right">
                    <img className="tab-img-height" src="images/dashboard.png" alt="" />
                  </div>
                  <div className="col-lg-7 pl-d-3 col-xl-6 pl-5" data-aos="fade-left">
                    <p className="color-6">Penatibus tortor sociis a non, fermentum. Erat pharetra adipiscing egestas scelerisque at massa, mi nunc. Arcu lacus elementum vitae facilisi curabitur habitasse phasellus semper ultricies. Mauris aliquam nisi diam morbi semper nulla dignissim feugiat morbi. Amet amet elementum purus</p>
                    <ul className="list-group list-group-horizontal row row-cols-3">
                      <li className="list-group-item col">
                        <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width={35} height={35} rx={10} fill="#F2F2F2" />
                          <g clipPath="url(#clip0)">
                            <path d="M10.983 18.2792C10.8308 18.4565 10.7567 18.6823 10.7748 18.9156C10.7929 19.1489 10.9002 19.3613 11.0781 19.5129L15.3965 23.2148C15.5569 23.3519 15.7559 23.4254 15.9647 23.4254C15.9904 23.4254 16.0166 23.4242 16.0429 23.4219C16.2791 23.4009 16.492 23.2883 16.6425 23.1057L24.0287 14.1159C24.1769 13.9351 24.2463 13.7076 24.2235 13.4749C24.2002 13.2421 24.0888 13.0321 23.9079 12.8834L22.5558 11.7727C22.183 11.467 21.6312 11.5207 21.3244 11.8934L15.6398 18.8124L13.3554 16.8553C12.9891 16.5426 12.4361 16.584 12.1217 16.9504L10.983 18.2792Z" fill="#222222" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect x="10.5002" y="10.5" width={14} height={14} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Certified Developer
                      </li>
                      <li className="list-group-item col">
                        <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width={35} height={35} rx={10} fill="#F2F2F2" />
                          <g clipPath="url(#clip0)">
                            <path d="M10.983 18.2792C10.8308 18.4565 10.7567 18.6823 10.7748 18.9156C10.7929 19.1489 10.9002 19.3613 11.0781 19.5129L15.3965 23.2148C15.5569 23.3519 15.7559 23.4254 15.9647 23.4254C15.9904 23.4254 16.0166 23.4242 16.0429 23.4219C16.2791 23.4009 16.492 23.2883 16.6425 23.1057L24.0287 14.1159C24.1769 13.9351 24.2463 13.7076 24.2235 13.4749C24.2002 13.2421 24.0888 13.0321 23.9079 12.8834L22.5558 11.7727C22.183 11.467 21.6312 11.5207 21.3244 11.8934L15.6398 18.8124L13.3554 16.8553C12.9891 16.5426 12.4361 16.584 12.1217 16.9504L10.983 18.2792Z" fill="#222222" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect x="10.5002" y="10.5" width={14} height={14} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Senior Developer
                      </li>
                      <li className="list-group-item col">
                        <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width={35} height={35} rx={10} fill="#F2F2F2" />
                          <g clipPath="url(#clip0)">
                            <path d="M10.983 18.2792C10.8308 18.4565 10.7567 18.6823 10.7748 18.9156C10.7929 19.1489 10.9002 19.3613 11.0781 19.5129L15.3965 23.2148C15.5569 23.3519 15.7559 23.4254 15.9647 23.4254C15.9904 23.4254 16.0166 23.4242 16.0429 23.4219C16.2791 23.4009 16.492 23.2883 16.6425 23.1057L24.0287 14.1159C24.1769 13.9351 24.2463 13.7076 24.2235 13.4749C24.2002 13.2421 24.0888 13.0321 23.9079 12.8834L22.5558 11.7727C22.183 11.467 21.6312 11.5207 21.3244 11.8934L15.6398 18.8124L13.3554 16.8553C12.9891 16.5426 12.4361 16.584 12.1217 16.9504L10.983 18.2792Z" fill="#222222" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect x="10.5002" y="10.5" width={14} height={14} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Mid level Developer
                      </li>
                      <li className="list-group-item col">
                        <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width={35} height={35} rx={10} fill="#F2F2F2" />
                          <g clipPath="url(#clip0)">
                            <path d="M10.983 18.2792C10.8308 18.4565 10.7567 18.6823 10.7748 18.9156C10.7929 19.1489 10.9002 19.3613 11.0781 19.5129L15.3965 23.2148C15.5569 23.3519 15.7559 23.4254 15.9647 23.4254C15.9904 23.4254 16.0166 23.4242 16.0429 23.4219C16.2791 23.4009 16.492 23.2883 16.6425 23.1057L24.0287 14.1159C24.1769 13.9351 24.2463 13.7076 24.2235 13.4749C24.2002 13.2421 24.0888 13.0321 23.9079 12.8834L22.5558 11.7727C22.183 11.467 21.6312 11.5207 21.3244 11.8934L15.6398 18.8124L13.3554 16.8553C12.9891 16.5426 12.4361 16.584 12.1217 16.9504L10.983 18.2792Z" fill="#222222" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect x="10.5002" y="10.5" width={14} height={14} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Junior Developer
                      </li>
                      <li className="list-group-item col">
                        <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width={35} height={35} rx={10} fill="#F2F2F2" />
                          <g clipPath="url(#clip0)">
                            <path d="M10.983 18.2792C10.8308 18.4565 10.7567 18.6823 10.7748 18.9156C10.7929 19.1489 10.9002 19.3613 11.0781 19.5129L15.3965 23.2148C15.5569 23.3519 15.7559 23.4254 15.9647 23.4254C15.9904 23.4254 16.0166 23.4242 16.0429 23.4219C16.2791 23.4009 16.492 23.2883 16.6425 23.1057L24.0287 14.1159C24.1769 13.9351 24.2463 13.7076 24.2235 13.4749C24.2002 13.2421 24.0888 13.0321 23.9079 12.8834L22.5558 11.7727C22.183 11.467 21.6312 11.5207 21.3244 11.8934L15.6398 18.8124L13.3554 16.8553C12.9891 16.5426 12.4361 16.584 12.1217 16.9504L10.983 18.2792Z" fill="#222222" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect x="10.5002" y="10.5" width={14} height={14} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Intern Devloper
                      </li>
                    </ul>
                    <h4 className="text-left">Technologies Our Backend Developers are Master of</h4>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item list-gap5">
                        <img src="images/java.png" alt="Java" />
                        <p className="name-caption">Java</p>
                      </li>
                      <li className="list-group-item list-gap5">
                        <img src="images/laravel.png" alt="Java" />
                        <p className="name-caption">Laravel</p>
                      </li>
                      <li className="list-group-item list-gap5">
                        <img src="images/java.png" alt="Java" />
                        <p className="name-caption">Java</p>
                      </li>
                      <li className="list-group-item list-gap5">
                        <img src="images/laravel.png" alt="Java" />
                        <p className="name-caption">Laravel</p>
                      </li>
                      <li className="list-group-item list-gap5">
                        <img src="images/java.png" alt="Java" />
                        <p className="name-caption">Java</p>
                      </li>
                      <li className="list-group-item list-gap5">
                        <img src="images/laravel.png" alt="Java" />
                        <p className="name-caption">Laravel</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section section-white pt-0">
          <div className="container">
            <hr />
            <div className="row justify-content-center" data-aos="fade-down">
              <div className="col-10">
                <h3 className="text-center mt-3 pt-5 pb-2 text-size-28">Experts at no cost!</h3>
                <p className="text-center">We offer you not only the experts on demand but also our team of specialist to co-operated you to manage these people for you so you get the best out of your acquired resource. How much do we charge for that? What if we say none! Consider it as a gesture of commitment towards partnership.</p>
              </div>
            </div>
            <div className="row mt-5 pt-2 justify-content-center">
              <div className="col-6 pl-md-0" data-aos="fade-right">
                <span className="box-border-icon">
                  <img src="images/watch.png" alt="" />
                </span>
                <div className="border p-5">
                  <h3 className="font-weight-semibold font-asap">Project co-ordinator</h3>
                  <p className="mb-0">We will assign a project co-ordinator having a sound sense of business analysis and technical consultancy. He will assist you with outlining the technical requirements while guiding your acquired team to get their technical output balanced with your vision and organizational objectives. This position will also act on your behalf by taking care of all the major communication and doing whatever it takes to get your tasks done on time.</p>
                </div>
              </div>
              <div className="col-6 pr-md-0" data-aos="fade-left">
                <span className="box-border-icon right59">
                  <img src="images/watch.png" alt="" />
                </span>
                <div className="border p-5">
                  <h3 className="font-weight-semibold font-asap">Project Manager</h3>
                  <p className="mb-0">We will also assign a technical project manager specialised in Technical Architecture to create a technical outline based on your requirements unless you are okay with doing it yourself. This position will also be responsible for day-to-day updates and technical quality of outputs so you don’t have to guide every individual developer yet you will have results delivered just on time, fully documented, tested and deployed!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section with Shadow*/}
        <section className="center-section section-white box-shadow">
          <div className="container">
            <div className="row section-title-top justify-content-center">
              <div className="col-lg-8 text-center">
                <div className="highlight-black text-center mb-3 font-weight-semibold text-size-13">Recruitment Philosophy</div>
                <h2 data-aos="fade-down">We Diligently <span className="highlight-orange">Recruit The Right Heads</span><br /> so, You can Build a Perfect Team </h2>
                <p className="color-7" data-aos="fade-up">At Doodle, when it comes to recruitment, in addition to the general attributes, we also look forward to scanning for the X factors of candidates so our we can provide our clients with the best team that is capable and worth retaining.</p>
              </div>
            </div>
            <div className="row mt-5 pb-5 col-box3">
              <div className="col-sm" data-aos="zoom-in-down">
                <div className="box-item hover-item text-center">
                  <img src="images/settings.png" alt="" />
                  <p className="mt-2"><strong className="color-4 text-size-18">Knowledge &amp; expertise</strong></p>
                  <p className="color-6">Decades of experience in delivering dynamic and challenging projects. Expert in complex and custom web application development on multiple platforms. </p>
                </div>
              </div>
              <div className="col-sm" data-aos="zoom-in-down">
                <div className="box-item hover-item hover-active text-center">
                  <img src="images/watch.png" alt="" />
                  <p className="mt-2"><strong className="color-4 text-size-18">Development approach</strong></p>
                  <p className="color-6">Eminent Agile Methodology approach throughout the development process. Ensuring flawless execution of the product design and on-time delivery of applications.</p>
                </div>
              </div>
              <div className="col-sm" data-aos="zoom-in-down">
                <div className="box-item hover-item text-center">
                  <img src="images/settings.png" alt="" />
                  <p className="mt-2"><strong className="color-4 text-size-18">Enthusiastic mindset</strong></p>
                  <p className="color-6">
                    Prioritising creativity in every step of development. Developers enjoy solving complex challenges and love to experience new horizons and scopes in their work.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 pl-0 pr-0 h-block h-block-left">
                <img src="images/blog-img01.jpg" alt="" />
                <div className="p-6 h-block-text text-left" data-aos="fade-left">
                  <h3 className="font-weight-semibold font-asap">We Piece<br /> together the best for you</h3>
                  <p className="color-6">You leave all the hard things for Doodle. We track, find, and get the best hands for you. We carefully nurse and encourage our employees to become a passionate juggler of libraries, frameworks, languages, and emerging technologies so they can always keep you ahead of your competition.</p>
                </div>
              </div>
              <div className="col-md-12 pl-0 pr-0 h-block h-block-right">
                <img src="images/blog-img02.jpg" alt="" />
                <div className="p-6 h-block-text text-right" data-aos="fade-right">
                  <h3 className="font-weight-semibold font-asap">Team players<br /> are always on our fond list</h3>
                  <p className="color-6">We source team players, not just employees. Starting from hiring to nursing a member, we follow a course to make sure that we select the right person and provide the right guidance, so each of them can be an effective team performer not just on paper but for real.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Transparent Section*/}
        <section className="center-section pb-0 section-transparent">
          <div className="container">
            <div className="row section-title-top justify-content-center">
              <div className="col-lg-7" data-aos="fade-down">
                <h2 className="text-center">We Ensure a <span className="highlight-orange">Proper Work Environment</span> that Brings Better Result</h2>
              </div>
              <div className="col-lg-9" data-aos="fade-up">
                <p className="text-center">Only having a right team isn’t enough if they are not given with a proper environment to work. We believe it affects motivation and productivity of employees, so at doodle, we do whatever it takes to keep our place full of life.</p>
              </div>
            </div>
            <div className="row mt-5 mb-3 pb-5 col-box5" data-aos="fade-down">
              <div className="col-sm">
                <div className="box-item text-center">
                  <img src="images/watch.png" alt="" />
                  <p className="mt-2">Happiness first approach</p>
                </div>
              </div>
              <div className="col-sm">
                <div className="box-item text-center">
                  <img src="images/settings.png" alt="" />
                  <p className="mt-2">Fair employee policy</p>
                </div>
              </div>
              <div className="col-sm">
                <div className="box-item text-center">
                  <img src="images/watch.png" alt="" />
                  <p className="mt-2">Positive synergy</p>
                </div>
              </div>
              <div className="col-sm">
                <div className="box-item text-center">
                  <img src="images/settings.png" alt="" />
                  <p className="mt-2">Clear communication</p>
                </div>
              </div>
              <div className="col-sm">
                <div className="box-item text-center">
                  <img src="images/watch.png" alt="" />
                  <p className="mt-2">Healthy lifestyle practice</p>
                </div>
              </div>
            </div>
          </div>
          <span className="effect-bg-top-left" />
        </section>
        {/*Transparent Section*/}
        <section className="center-section pt-0 section-transparent" data-aos="fade-up">
          <div className="justify-content-center">
            <div className="loop owl-carousel owl-theme">
              <div className="item">
                <img src="images/work-slide3.jpg" />
              </div>
              <div className="item">
                <img src="images/work-slide4.jpg" />
              </div>
              <div className="item">
                <img src="images/work-slide5.jpg" />
              </div>
              <div className="item">
                <img src="images/work-slide2.jpg" />
              </div>
              <div className="item">
                <img src="images/work-slide1.jpg" />
              </div>
              <div className="item">
                <img src="images/work-slide2.jpg" />
              </div>
            </div>
          </div>
          <span className="effect-bg-top-left" />
        </section>
        {/*Black Section*/}
        <section className="center-section section-black">
          <div className="container">
            <div className="row">
              <div className="col-12 mt-4 mb-4">
                <h2 className="text-center text-white" data-aos="fade-down">We will Write This <span className="highlight-orange">Title Later</span></h2>
                <p className="text-center color-a" data-aos="fade-up">At Doodle, we thrive to enable you with our world-class resources while taking the responsibility of<br /> any failures and giving you the credit for the successes.</p>
              </div>
              <div className="col-12 mt-2">
                <div className="row">
                  <div className="col-6">
                    <div className="blog-imgs">
                      <img src="images/blog-img03.jpg" alt="" />
                    </div>
                    <div className="blog-texts" data-aos="fade-down">
                      <h3 className="text-center">With us, you’re<br /> secured to the fullest extent</h3>
                      <p className="text-center mb-0">Our company policy covers all short of measures that concern the security of your product regarding performance, control or ownership. We not only provide you with the source code but also we will give you full control over all relevant security features so you can monitor and check everything by yourself anytime. And most importantly we are promised to protect your complete right to the product and its ownership.</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="blog-imgs">
                      <img src="images/blog-img04.jpg" alt="" />
                    </div>
                    <div className="blog-texts" data-aos="fade-down">
                      <h3 className="text-center">We become<br /> your reliable tech partner</h3>
                      <p className="text-center mb-0">User experience, code quality, accessibility, security, regulatory compliance, and data issues — all of those matter to each of our employees while designing and executing a solution to make sure you get a flawless end product that is perfectly fit your business and its domain. They also keep an eye on the ever-changing environment of tech trends and keep themselves updated so that you would never fall behind your competition.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section section-white">
          <div className="container">
            <div className="row">
              <div className="col-12 p-0 mt-4 mb-4">
                <h2 className="text-left" data-aos="fade-left">How a <span className="highlight-orange">Truly Dedicated</span> Team Can Be<br />
                  a Lifeline to Your Progress?</h2>
              </div>
              <div className="col-12 mt-2">
                <div className="row">
                  <div className="col-6 pl-lg-0" data-aos="flip-down">
                    <div className="white-block">
                      <span className="block-count">01</span>
                      <h5 className="font-weight-bold">Letting you focus<br /> on your core business</h5>
                      <p className="color-6">This way our team will pick up your requirements and handle every relevant necessity starting from simple designs to the most complex development needs, so you can let your core management focus on the most important goals, objectives, and tasks that your business faces.</p>
                    </div>
                  </div>
                  <div className="col-6 pr-lg-0" data-aos="flip-down">
                    <div className="white-block">
                      <span className="block-count">02</span>
                      <h5 className="font-weight-bold">Getting you the<br /> access to specialised talents</h5>
                      <p className="color-6">When you are hiring for a business it can be a challenge to find good people with required skills, expertises and experience to accomplish a certain job at a reasonable rate. You can be free from the hassle of in-house hiring while we’re up for getting you the access to the experts in required fields.</p>
                    </div>
                  </div>
                  <div className="col-4 mt-4 pl-lg-0" data-aos="flip-down">
                    <div className="white-block">
                      <span className="block-count">03</span>
                      <h5 className="font-weight-bold">Helping you<br /> by improving efficiency</h5>
                      <p className="color-6">It’ll allow you to increase efficiency by giving works that can’t be accomplished in-house in a reasonable means to those who are specialized and have the tools to do it right and quick. It may also assist with the team competence development while creating the opportunity to share expert knowledge.</p>
                    </div>
                  </div>
                  <div className="col-4 mt-4" data-aos="flip-down">
                    <div className="white-block">
                      <span className="block-count">04</span>
                      <h5 className="font-weight-bold">Giving more rational<br /> scopes when to scale up</h5>
                      <p className="color-6">Our contract policy makes it easy for you to scale your business when it needs additional people and downsize it when it doesn’t, without any effect on operation or the output of remaining team. Since you’re hiring resources on a monthly basis, you can quickly scale up and down to suit your current conditions.</p>
                    </div>
                  </div>
                  <div className="col-4 mt-4 pr-lg-0" data-aos="flip-down">
                    <div className="white-block">
                      <span className="block-count">05</span>
                      <h5 className="font-weight-bold">Saving you money<br /> while mitigating risks</h5>
                      <p className="color-6">You can let us take care of your teams’ holidays, insurance policies, medical bills, vacations and yet assure you quality and reliability. This way you can also avoid the costs regarding in-house resources and relevant financial leverage on scaling up your team by paying a simple monthly invoice, as and when you need extra support.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className="effect-bg-top-left" />
        </section>
        {/*White Section*/}
        <section className="center-section pt-0 section-white">
          <div className="container">
            <div className="row">
              <div className="col-12 p-0 mb-4">
                <h2 className="text-center" data-aos="fade-down">Still Confused?</h2>
                <p className="text-center" data-aos="fade-up">let’s evaluate different hiring options against key aspects that one should<br /> consider beofore starting a project.</p>
              </div>
              <div className="col-md-10 offset-md-1 mt-2" data-aos="fade-up">
                <div className="row">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><h4 className="font-weight-bold color-4">RESOURCE</h4></th>
                        <th scope="col"><h4 className="font-weight-bold text-white text-center">DOODLE</h4></th>
                        <th scope="col"><h4 className="font-weight-bold color-4 text-center">IN-HOUSE</h4></th>
                        <th scope="col"><h4 className="font-weight-bold color-4 text-center">FREELANCER</h4></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>TIME TO GET RIGHT DEVELOPERS
                        </td><td>2-3 DAYS</td>
                        <td className="text-center">&lt; 2 WEEKS</td>
                        <td className="text-center">&lt; 1 WEEK</td>
                      </tr>
                      <tr>
                        <td>COST OF TRAINING &amp; BENEFITS
                        </td><td>NO COST</td>
                        <td className="text-center">COSTLY</td>
                        <td className="text-center">NO COST</td>
                      </tr>
                      <tr>
                        <td>TIME TO START A PROJECT
                        </td><td>2-3 DAYS</td>
                        <td className="text-center">&lt; 2 WEEKS</td>
                        <td className="text-center">&lt; 1 WEEK</td>
                      </tr>
                      <tr>
                        <td>TIME TO SCALE SIZE OF TEAM
                        </td><td>2-7 DAYS</td>
                        <td className="text-center">3 - 12 WEEKS</td>
                        <td className="text-center">1 - 4 WEEKS</td>
                      </tr>
                      <tr>
                        <td>PRICING (monthly average)
                        </td><td>1.5X</td>
                        <td className="text-center">3X</td>
                        <td className="text-center">1X</td>
                      </tr>
                      <tr>
                        <td>RISK OF PROJECT FAILURE
                        </td><td>EXTREAMLY LOW</td>
                        <td className="text-center">LOW</td>
                        <td className="text-center">VERY HIGHT</td>
                      </tr>
                      <tr>
                        <td>DEDICATED RESOURCES
                        </td><td>YES</td>
                        <td className="text-center">YES</td>
                        <td className="text-center">NOT ALWAYS</td>
                      </tr>
                      <tr>
                        <td>AGILE DEVELOPMENT METHODOLOGY
                        </td><td>YES</td>
                        <td className="text-center">MAYBE</td>
                        <td className="text-center">NO</td>
                      </tr>
                      <tr>
                        <td>QUALITY GUARANTEE
                        </td><td>YES</td>
                        <td className="text-center">YES</td>
                        <td className="text-center">NO</td>
                      </tr>
                      <tr>
                        <td>IMPACT DUE TO TURNOVER
                        </td><td>NONE</td>
                        <td className="text-center">HIGH</td>
                        <td className="text-center">HIGH</td>
                      </tr>
                      <tr>
                        <td>STRUCTURED TRAINING PROGRAMS
                        </td><td>YES</td>
                        <td className="text-center">SOME</td>
                        <td className="text-center">NO</td>
                      </tr>
                      <tr>
                        <td>COMMUNICATIONS
                        </td><td>SEAMLESS</td>
                        <td className="text-center">SEAMLESS</td>
                        <td className="text-center">UNCERTAIN</td>
                      </tr>
                      <tr>
                        <td>TERMINATION COSTS
                        </td><td>NONE</td>
                        <td className="text-center">HIGH</td>
                        <td className="text-center">NONE</td>
                      </tr>
                      <tr>
                        <td>ASSURED WORK
                        </td><td>40hrs/WEEK</td>
                        <td className="text-center">40hrs/WEEK</td>
                        <td className="text-center">NO GUARAANTY</td>
                      </tr>
                      <tr>
                        <td>COST OF TOOLS AND ENVIRONMENT
                        </td><td>NONE</td>
                        <td className="text-center">HIGH</td>
                        <td className="text-center">NONE</td>
                      </tr>
                      <tr>
                        <td>
                        </td><td />
                        <td className="text-center" />
                        <td className="text-center" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Background image section*/}
        <section className="center-section pt-0 pb-0 section-white">
          <div className="bg-image-section" style={{ background: 'url(images/3rd-banner.jpg) no-repeat top center', backgroundSize: 'cover' }}>
            <div className="img-overly pt-5 pb-5 center-section">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-9 pt-4 pb-4 text-center">
                    {/* <div class="highlight-orange">VIRTUAL MANAGEMENT PRACTICES <span class="highlight-rh-border-orange"></span></div> */}
                    <h2 className="text-white text-center" data-aos="fade-down">Compact Service Level Agreement<br /> (SLA) To Protect Your Rights</h2>
                    <h4 className="text-white text-center font-weight-normal font-asap color-d mb-4 pb-2" data-aos="fade-up">Prior to start, we ensure a flawless agreement where it makes clear what you will get and how<br /> so that there could no complication arise regarding our commitment and your expectation<br /> while protecting every right you deserve</h4>
                    <a href="#" className="btn btn-primary btn-lg" role="button" aria-pressed="true"><span>Learn More</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section pb-4 section-white">
          <div className="container">
            <div className="row section-title-top justify-content-center">
              <div className="col-lg-8 text-center">
                <div className="highlight-black text-center mb-3 font-weight-semibold text-size-13" data-aos="fade-down">TESTIMONIALS</div>
                <h2 data-aos="fade-up">Our Partnership<br /> Goes <span className="highlight-orange">Beyond Technology </span></h2>
              </div>
            </div>
          </div>
          <span className="effect-bg-top-right" />
        </section>
        {/*Transparent Section*/}
        <section className="center-section pt-0 pb-0 section-transparent" data-aos="fade-up">
          <div className="justify-content-center">
            <div className="testimonials owl-carousel owl-theme">
              <div className="item mlr-m row justify-content-center">
                <div className="col-md-6">
                  <span className="quotes">
                    <svg width={115} height={115} viewBox="0 0 115 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M104.219 57.5H86.25V43.125C86.25 35.1963 92.6963 28.75 100.625 28.75H102.422C105.409 28.75 107.812 26.3467 107.812 23.3594V12.5781C107.812 9.59082 105.409 7.1875 102.422 7.1875H100.625C80.7695 7.1875 64.6875 23.2695 64.6875 43.125V97.0312C64.6875 102.983 69.5166 107.812 75.4688 107.812H104.219C110.171 107.812 115 102.983 115 97.0312V68.2812C115 62.3291 110.171 57.5 104.219 57.5ZM39.5312 57.5H21.5625V43.125C21.5625 35.1963 28.0088 28.75 35.9375 28.75H37.7344C40.7217 28.75 43.125 26.3467 43.125 23.3594V12.5781C43.125 9.59082 40.7217 7.1875 37.7344 7.1875H35.9375C16.082 7.1875 0 23.2695 0 43.125V97.0312C0 102.983 4.8291 107.812 10.7812 107.812H39.5312C45.4834 107.812 50.3125 102.983 50.3125 97.0312V68.2812C50.3125 62.3291 45.4834 57.5 39.5312 57.5Z" fill="#EEEEEE" />
                    </svg>
                  </span>
                  <h5 className="color-6 mt-4 pt-5 line-height">Vel elit faucibus sodales elementum scelerisque. Phasellus semper risus sapien ultrices adipiscing. Aliquam, gravida gravida tortor aliquet. Feugiat ipsum aliquet odio convallis eget fermentum, nunc. Eleifend orci molestie in feugiat sit. Nunc arcu cursus lacus, tempus ac. Nisi.</h5>
                  <div className="t-user-profile mt-4">
                    <div className="t-use-pic">
                      <img src="images/testimonials-user-01.jpg" alt="" />
                    </div>
                    <div className="t-user-info">
                      <div className="t-user-name font-weight-bold">Jean V. Castillo</div>
                      <div className="t-user-designation color-6">Rose Street</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src="images/testimonials-user-worked-01.jpg" alt="" />
                </div>
              </div>
              <div className="item mlr-m row justify-content-center">
                <div className="col-md-6">
                  <span className="quotes">
                    <svg width={115} height={115} viewBox="0 0 115 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M104.219 57.5H86.25V43.125C86.25 35.1963 92.6963 28.75 100.625 28.75H102.422C105.409 28.75 107.812 26.3467 107.812 23.3594V12.5781C107.812 9.59082 105.409 7.1875 102.422 7.1875H100.625C80.7695 7.1875 64.6875 23.2695 64.6875 43.125V97.0312C64.6875 102.983 69.5166 107.812 75.4688 107.812H104.219C110.171 107.812 115 102.983 115 97.0312V68.2812C115 62.3291 110.171 57.5 104.219 57.5ZM39.5312 57.5H21.5625V43.125C21.5625 35.1963 28.0088 28.75 35.9375 28.75H37.7344C40.7217 28.75 43.125 26.3467 43.125 23.3594V12.5781C43.125 9.59082 40.7217 7.1875 37.7344 7.1875H35.9375C16.082 7.1875 0 23.2695 0 43.125V97.0312C0 102.983 4.8291 107.812 10.7812 107.812H39.5312C45.4834 107.812 50.3125 102.983 50.3125 97.0312V68.2812C50.3125 62.3291 45.4834 57.5 39.5312 57.5Z" fill="#EEEEEE" />
                    </svg>
                  </span>
                  <h5 className="color-6 mt-4 pt-5 line-height">Vel elit faucibus sodales elementum scelerisque. Phasellus semper risus sapien ultrices adipiscing. Aliquam, gravida gravida tortor aliquet. Feugiat ipsum aliquet odio convallis eget fermentum, nunc. Eleifend orci molestie in feugiat sit. Nunc arcu cursus lacus, tempus ac. Nisi.</h5>
                  <div className="t-user-profile mt-4">
                    <div className="t-use-pic">
                      <img src="images/testimonials-user-01.jpg" alt="" />
                    </div>
                    <div className="t-user-info">
                      <div className="t-user-name font-weight-bold">Jean V. Castillo</div>
                      <div className="t-user-designation color-6">Rose Street</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src="images/testimonials-user-worked-01.jpg" alt="" />
                </div>
              </div>
              <div className="item mlr-m row justify-content-center">
                <div className="col-md-6">
                  <span className="quotes">
                    <svg width={115} height={115} viewBox="0 0 115 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M104.219 57.5H86.25V43.125C86.25 35.1963 92.6963 28.75 100.625 28.75H102.422C105.409 28.75 107.812 26.3467 107.812 23.3594V12.5781C107.812 9.59082 105.409 7.1875 102.422 7.1875H100.625C80.7695 7.1875 64.6875 23.2695 64.6875 43.125V97.0312C64.6875 102.983 69.5166 107.812 75.4688 107.812H104.219C110.171 107.812 115 102.983 115 97.0312V68.2812C115 62.3291 110.171 57.5 104.219 57.5ZM39.5312 57.5H21.5625V43.125C21.5625 35.1963 28.0088 28.75 35.9375 28.75H37.7344C40.7217 28.75 43.125 26.3467 43.125 23.3594V12.5781C43.125 9.59082 40.7217 7.1875 37.7344 7.1875H35.9375C16.082 7.1875 0 23.2695 0 43.125V97.0312C0 102.983 4.8291 107.812 10.7812 107.812H39.5312C45.4834 107.812 50.3125 102.983 50.3125 97.0312V68.2812C50.3125 62.3291 45.4834 57.5 39.5312 57.5Z" fill="#EEEEEE" />
                    </svg>
                  </span>
                  <h5 className="color-6 mt-4 pt-5 line-height">Vel elit faucibus sodales elementum scelerisque. Phasellus semper risus sapien ultrices adipiscing. Aliquam, gravida gravida tortor aliquet. Feugiat ipsum aliquet odio convallis eget fermentum, nunc. Eleifend orci molestie in feugiat sit. Nunc arcu cursus lacus, tempus ac. Nisi.</h5>
                  <div className="t-user-profile mt-4">
                    <div className="t-use-pic">
                      <img src="images/testimonials-user-01.jpg" alt="" />
                    </div>
                    <div className="t-user-info">
                      <div className="t-user-name font-weight-bold">Jean V. Castillo</div>
                      <div className="t-user-designation color-6">Rose Street</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src="images/testimonials-user-worked-01.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section pb-5 mb-4 section-white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 data-aos="fade-down">Making <span className="highlight-orange">The World </span>a Little Smarter</h2>
                <p data-aos="fade-up">Doodle accelerates business and visionary individuals who are striving to expand their business and ideas by the power of information technology. Our team uses the smartest digital solution to achieve their goal and ensure better digital expereince.
                We thrive to enable them by leveraging our experience, expertise and diverse resources to their best interest, so we can keep growing together and contribute towards making this world a bit smarter and a lot better every day.
                </p>
              </div>
            </div>
            <div className="row mt-3 justify-content-center" data-aos="fade-left">
              <ul className="list-group small-img-block list-group-horizontal">
                <li className="list-group-item list-gap3">
                  <img src="images/microsoft-logo.png" alt="Microsoft" />
                </li>
                <li className="list-group-item list-gap3">
                  <img src="images/google-partner-logo.png" alt="Google" />
                </li>
                <li className="list-group-item list-gap3">
                  <img src="images/AWS_PartnerNetwork.png" alt="AWS" />
                </li>
                <li className="list-group-item list-gap3">
                  <img src="images/google-partner-logo.png" alt="Google" />
                </li>
                <li className="list-group-item list-gap3">
                  <img src="images/microsoft-logo.png" alt="Microsoft" />
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section pt-0 pb-0 section-white">
          <div className="call-to-action pt-5 pb-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-7" data-aos="fade-right">
                  <h3 className="mb-0 pt-1">Our offshore dedicated team <span className="highlight-orange">surely can</span> be a lifeline!</h3>
                </div>
                <div className="col-5 text-right" data-aos="fade-left">
                  <a href="#" className="btn btn-primary btn-lg" role="button" aria-pressed="true"><span>Get Free Consultancy</span></a>
                  <span className="font-weight-semibold color-2 pl-3 pr-3">or</span>
                  <a href="#" className="btn btn-primary btn-lg" role="button" aria-pressed="true"><span>Contact Now</span></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*----------Footer---------*/}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DedicatedOffshoreTeam)