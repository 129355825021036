import React, { useEffect } from "react";
import ToolsAndTechnologies from "./../../common/ToolsAndTechnologies";
import TellUsAbout from "../../common/TellUsAbout";
import FAQ from "../../common/FAQ";
import RelatedBlogArticles from "../../common/RelatedBlogArticles";
import Footer from "./../../Footer";
import AOS from "aos";
function MobileApplication() {
  useEffect(() => {
    document.title = "Mobile Application Development";
    AOS.init({});
  });
  return (
    <div>
      <section
        className="banner"
        style={{
          background: "url(images/services-banner.png) no-repeat bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">
                  Mobile Application Development
                </h1>
              </div>
              <p className="text-white">
                Services /{" "}
                <span className="dark-text">
                  Mobile Application Development
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="center-section pt-3 pb-3 section-black">
        <div className="container">
          <div className="row pt-3 pb-3 text-center">
            <div className="col-lg-5 ml-auto">
              <div className="award-text">
                <span className="award-type">Award</span>{" "}
                <span className="award-and">&</span> Certifications
              </div>
              
            </div>
            <div className="col-lg-5 mobile-hide-only mr-auto">
              <div className="counter-item">
                <img src="images/circle-img01.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_01">9</span>
                    <span className="c_text_01">YEARS</span>IN OPERATION
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">100s</span>
                    <span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">4.9/5</span>
                    <span className="c_text_01">CUSTOMERS</span>RATING
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-drak-gray pt-5 pb-5">
        <div className="container">
          <div
            className="row section-title-top mb-5 pt-5 justify-content-center"
            data-aos="fade-down"
          >
            <div className="col-lg-12 text-center">
              <h2>
                Mobile <span className="highlight-orange">Application</span>{" "}
                Development Services
              </h2>
              <p className="color-7">
                Doodle Inc. provides reliable mobile application development
                services. Use our expertise to build a solid mobile app for any
                device. From ideation, implementation to delivery and
                maintenance, we do it all. Your business will boost up by
                leveraging our experience. The heart of our mobile app
                development is your business requirement.
              </p>
            </div>
          </div>
          <div className="row ml-0 mr-0 services-boxs mb-5">
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>iOS App Development</span>Leverage our decade long
                expertise to build an impactful, attractive, and efficient iOS
                app. Release captivating products compatible with iPhone, iPad,
                Apple watch & TV. We can develop iOS applications that serve
                above your expectations. We can also migrate your existing
                android app to iOS.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Android App Development</span>At Doodle, we can help you
                expand your business by reaching millions of android users.
                We’ve earned the reputation as one of the most reliable native
                app development companies for android. We create high
                performing, feature-packed applications that are engaging and
                user friendly.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Cross-Platform App Development</span>We can build
                cross-platform applications from a single code base, which will
                run on both android and iOS. Doodle ensures a consistent user
                experience in all devices and platforms.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Hybrid Application Development</span>Maximize benefits and
                reach all sorts of users with hybrid applications. Doodle makes
                high performing, feature-packed, neat hybrid applications
                ensuring scalability. We use React Native, HTML5, Angular,
                Javascript, Node, and other similar technologies to make
                reusable code for Android, iOS, and windows phone. Seamless
                performance for all users in an efficient way, is what we seek.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>IoT Enabled Mobile Application</span>Doodle Inc. can help
                you make mobile apps compatible with IoT. We have an experienced
                team who will research your requirements, build solid firmware,
                and present you with quality products in time. We ensure top
                quality maintenance service, we will update the firmware
                whenever needed.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Application Redesign & Scale Up</span>You can always scale
                up & redesign your existing app with Doodle. Our expert will
                focus on scaling and redesigning your applications keeping the
                core of your application the same. Doodle Inc. values your
                vision and always tends to uphold your needs.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-white pt-5 pb-5">
        <div className="container">
          <div className="row section-title-top mb-5 pt-5" data-aos="fade-down">
            <div className="col-lg-5 text-left">
              <h2>
                Emerging <span className="highlight-orange">Technologies</span>{" "}
                <br />
                for Custom Software Development
              </h2>
              <p className="color-7">
                Doodle Inc. has earned global recognition in custom software
                development. With a variety of modern technologies and
                approaches, we are serving our clients with dependable
                solutions.
              </p>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
              <div className="tech-item tech-color-1">
                <img src="/images/tech-test-img-01.png" alt="" />
                <p>
                  <span>Artificial Intelligence</span>AI-based algorithms &
                  automated operation for scalable solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
              <div className="tech-item tech-color-2">
                <img src="/images/tech-test-img-02.png" alt="" />
                <p>
                  <span>Internet of Things</span>IoT devices to make a
                  real-time, efficient, and robust infrastructure.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="tech-item tech-color-3">
                <img src="/images/tech-test-img-03.png" alt="" />
                <p>
                  <span>Cloud Computing</span>Flexible, scalable & integrated
                  business solution.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="tech-item tech-color-4">
                <img src="/images/tech-test-img-04.png" alt="" />
                <p>
                  <span>Big Data</span>Increased business intelligence and
                  dependable data analysis.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3 push-right">
              <div className="tech-item tech-color-5">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-05.png"
                  alt=""
                />
                <p>
                  <span>BlockChain</span>Secured data, automated key operations,
                  detailed database, reduces cost, and increases efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="tech-item tech-color-6">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-06.png"
                  alt=""
                />
                <p>
                  <span>Augmented Reality</span>Greater & interactive user
                  experience with increased efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="tech-item tech-color-7">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-07.png"
                  alt=""
                />
                <p>
                  <span>Virtual Reality</span>Simulated user experience that can
                  be applied in education, entertainment, business, and many
                  other sectors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="center-section section-white box-shadow">
        <div className="container">
          <div
            className="row section-title-top justify-content-center"
            data-aos="fade-down"
          >
            <div className="col-lg-12 text-center">
              <h2>
                Our Mobile App Development Process
                {/* <span className="highlight-orange">Life-cycle </span>(SDLC) */}
              </h2>
              {/* <p className="color-7">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p> */}
            </div>
          </div>
          <div className="row mt-5 pt-4">
            <div className="col-lg-4 d-inline-table text-center">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        1. Idea <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      It all starts with collecting information from the
                      customers and getting into their core vision. So we set
                      the project goal, probable cost, and resources we’re going
                      to need.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. Research & Analysis{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Determining what the application is supposed to do and its
                      requirements. It also includes defining the resources
                      needed to develop the project and the technical dynamics
                      we may need to cover.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. UI & UX Design <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The system and documents are arranged as per requirement
                      specifications. Such an arrangement helps to reach the
                      aimed system architecture and technology stack. Designers
                      start designing the user interface.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. Development
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      This is where the core development starts. Developers
                      start to write code to build the whole system using the
                      chosen language and methodologies. The designers continue
                      working on the user interface and testers start analyzing
                      the requirements for their test plans.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFive">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5. Testing & Quality assurance{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="headingFive"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      This phase is to find bugs in the code and fix them while
                      evaluating the overall quality of the software. The
                      testing team also evaluates the functionality of the
                      entire system. App usability, compatibility with devices,
                      interface-performance testing, resource testing, and many
                      more things are tested in this phase.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingSix">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        6. Launch <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSix"
                    className="collapse"
                    aria-labelledby="headingSix"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The application is launched to market. The goal is to make
                      the application available to users as much as possible.
                      The more download/reach the application gets the more
                      successful the launch is.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingSeven">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        7. Maintenance <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    aria-labelledby="headingSeven"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Maintenance is performed over time to change the system
                      architecture according to future needs. This may require
                      the addition of code or alteration of the existing code,
                      bug fixing, upgrade, enhancement, server maintenance, and
                      much more are done during the maintenance phase.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-lg-0 mt-sm-5 d-inline-table lifecycle text-center">
              <img src="/images/mobile-application-development.png" alt="" />
              {/* <div className="step01">
                <span className="stepleft">Deploy</span>
                <span className="stepright">Test</span>
              </div>
              <div className="step02">
                <span className="stepleft">Review</span>
                <span className="stepright">Develop</span>
              </div>
              <div className="step03">
                <span className="stepleft">Plan</span>
                <span className="stepcenter">Design</span>
                <span className="stepright">Launch</span>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      
      <ToolsAndTechnologies />
      <TellUsAbout />
      {/* <section
        className="center-section section-black pt-0 pb-0 box-shadow"
        style={{ background: "url(images/contact-bg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 bg-black ex-pt">
              <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
                <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                  <div className="award-text">
                    <span className="award-type">Award</span>{" "}
                    <span className="award-and">&</span> Certifications
                  </div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon01.png" alt="..." />
                  </div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon02.png" alt="..." />
                  </div>
                </div>
                <div className="col-lg-12 mr-auto">
                  <div className="counter-item">
                    <img src="images/circle-img01.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_01">9</span>
                        <span className="c_text_01">YEARS</span>IN OPERATION
                      </p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_02">100s</span>
                        <span className="c_text_01">OF CUSTOMERS</span>
                        WORLDWIDE
                      </p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_02">4.9/5</span>
                        <span className="c_text_01">CUSTOMERS</span>RATING
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 bg-white ex-pt">
              <div
                className="row p-5 pl-lg-5 section-title-top"
                data-aos="fade-down"
              >
                <div className="col-lg-12 pl-lg-5 text-left">
                  <h2>
                    Tell Us About{" "}
                    <span className="highlight-orange">Your Project</span>
                  </h2>
                </div>
                <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                  <form className="row">
                    <div className="form-group  mb-4 col-lg-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="John Smith"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>E-mail</label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="example@provider.com"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Phone</label>
                      <input
                        type="phone"
                        className="form-control form-control-lg"
                        placeholder="+1 112 222 333"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Ex: Pexel Inc."
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-12">
                      <label>Messages</label>
                      <textarea
                        className="form-control ex-h"
                        placeholder="Hello..."
                      ></textarea>
                    </div>
                    <div className="form-group col-lg-12">
                      <button type="submit" className="btn btn-primary btn-lg">
                        <span>Send Now</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="center-section section-white box-shadow">
        <div className="container">
          <div className="row section-title-top" data-aos="fade-down">
            <div className="col-lg-5 text-left">
              <h2>F.A.Q.</h2>
              <p className="color-7 pr-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </div>
            <div className="col-lg-7 faq-cls">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne1"
                        aria-expanded="false"
                        aria-controls="collapseOne1"
                      >
                        Lorem Ipsum is simply dummy text of the?
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne1"
                    className="collapse"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo1"
                        aria-expanded="false"
                        aria-controls="collapseTwo1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo1"
                    className="collapse"
                    aria-labelledby="headingTwo1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree1"
                        aria-expanded="false"
                        aria-controls="collapseThree1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree1"
                    className="collapse"
                    aria-labelledby="headingThree1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFive1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive1"
                        aria-expanded="false"
                        aria-controls="collapseFive1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive1"
                    className="collapse"
                    aria-labelledby="headingFive1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="center-section section-white box-shadow">
        <div className="container">
          <div className="row section-title-top" data-aos="fade-down">
            <div className="col-lg-12 text-center">
              <h2>Related Blog Articles</h2>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img1.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img2.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
          </div>
        </div>
      </section> */}
      {/*----------Footer---------*/}
      <Footer />
    </div>
  );
}
export default MobileApplication;
