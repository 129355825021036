import React, { useEffect } from "react";
import Footer from "../../Footer";
import AOS from "aos";
import ToolsAndTechnologies from "./../../common/ToolsAndTechnologies";
import TellUsAbout from "../../common/TellUsAbout";
function MachineLearning() {
  useEffect(() => {
    document.title = `Machine Learning`;
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  });
  return (
    <div>
      <section
        className="banner"
        style={{
          background: "url(images/services-banner.png) no-repeat bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">
                  Machine Learning
                </h1>
              </div>
              <p className="text-white">
                Services / <span className="dark-text">Machine Learning</span>
              </p>
            </div>
          </div>
          {/* <a className="mt-5 arrow-down mb-5" href="#makingtheworl">
            <svg width={20} height={37} viewBox="0 0 20 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1.63028C5.40498 1.63028 1.66667 5.28699 1.66667 9.78168V27.2186C1.66667 31.7133 5.40498 35.37 10 35.37C14.595 35.37 18.3333 31.7133 18.3333 27.2186V9.78168C18.3333 5.28699 14.595 1.63028 10 1.63028ZM10 0C15.5229 0 20 4.37941 20 9.78168V27.2186C20 32.6208 15.5229 37.0003 10 37.0003C4.47715 37.0003 0 32.6208 0 27.2186V9.78168C0 4.37941 4.47715 0 10 0Z" fill="white" />
              <path d="M10 15.3106C13.1218 15.3106 15.6525 12.8352 15.6525 9.78153C15.6525 6.7279 13.1218 4.25244 10 4.25244C6.87824 4.25244 4.34753 6.7279 4.34753 9.78153C4.34753 12.8352 6.87824 15.3106 10 15.3106Z" fill="white" />
            </svg>
          </a> */}
        </div>
      </section>

      <section className="center-section pt-3 pb-3 section-black">
        <div className="container">
          <div className="row pt-3 pb-3 text-center">
            <div className="col-lg-5 ml-auto">
              <div className="award-text">
                <span className="award-type">Award</span>{" "}
                <span className="award-and">&</span> Certifications
              </div>
              <div className="iso-icon">
                <img src="images/ISO_icon01.png" alt="..." />
              </div>
              <div className="iso-icon">
                <img src="images/ISO_icon02.png" alt="..." />
              </div>
            </div>
            <div className="col-lg-5 mobile-hide-only mr-auto">
              <div className="counter-item">
                <img src="images/circle-img01.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_01">9</span>
                    <span className="c_text_01">YEARS</span>IN OPERATION
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">100s</span>
                    <span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">4.9/5</span>
                    <span className="c_text_01">CUSTOMERS</span>RATING
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-drak-gray pt-5 pb-5">
        <div className="container">
          <div
            className="row section-title-top mb-5 pt-5 justify-content-center"
            data-aos="fade-down"
          >
            <div className="col-lg-12 text-center">
              <h2>
                <span className="highlight-orange">Machine Learning</span>{" "}
                Services
              </h2>
              <p className="color-7">
                Get data-driven decision making & solve key business challenges
                with machine learning. Our futuristic machine learning solutions
                provide pattern recognition, computational intelligence,
                advanced algorithms, mathematical optimization & more. Doodle’s
                efficient machine learning solutions deliver improved
                transferability detailed analysis & accurate predictions.
                Utilize existing data to get fraud detection, demand
                forecasting, lead generation, etc.
              </p>
            </div>
          </div>
          <div className="row ml-0 mr-0 services-boxs mb-5">
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Predictive Analytics</span>Minimize risk and improve
                efficiency with predictive analytics. With your existing data
                predict the future and take necessary actions. Our solutions are
                the result of our industry research combining complex
                statistical models, data mining methods & advanced machine
                learning tools. Doodle’s predictive analytics solutions can help
                you to understand customer behavior, demand forecasting as well
                as cross-selling opportunities.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Image & Video Analytics</span>Automate image and video and
                analysis with artificial intelligence. Identify people, texts,
                objects & inappropriate contents in images and videos. Doodle’s
                deep learning-powered computer vision analytics tools provide
                precise facial recognition facilities. Our analysis will be
                focusing on your business needs so that you get significant
                business insights. Our facial recognition system helps you to
                provide a better customer experience and fraud prevention.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Natural Language Processing</span>Interpret human language
                and behavior with NLP powered solution. Gain a competitive edge
                and operate efficiently using NLP tools to process, analyze &
                understand unstructured “big data”. Extract significant insights
                from texts and audios. We can build digital assistants tailored
                to your business. Doodle’s NLP services support chatbots,
                personalized search results, threat detection, sentiment
                analysis, speech-document recognition, and many more.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Automated Machine Learning</span>Figure out insights and
                make predictions using algorithms and statistical models.
                Develop and deploy predictive models from data preparation and
                feature engineering & selection, model configuration, training,
                and validation. Our automated ML solutions will empower you to
                create top class models as per tailored business needs, even
                with little machine learning skill. Doodle’s solutions will
                automate the end-to-end processes enabling you to make faster &
                better decisions.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Marketing Automation Solutions</span>Employ marketing
                automation solutions & take your business a notch ahead. Our
                marketing automation solutions can sort market segmentation,
                carry out specified marketing, optimize demand forecasting,
                assess leads & enhance content suggestions for the target group
                of customers. Our solutions will be featuring your business
                needs.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <div className="box-contents">
              <img
                className="serviceimg-normal"
                src="/images/services-test-img.png"
                alt=""
              />
              <img
                className="serviceimg-hover"
                src="/images/services-test-img-hover.png"
                alt=""
              />
              <p className="font-roboto">
                <span>Machine Learning Programming</span>Our AI developer team
                can provide custom made machine learning solution which will
                create a pragmatic decision model and automate business
                processes. We convert legacy systems into clean data sets, raw
                data from big data providers and deploy them across the system.
                Our solution will be implementing your business requirements.
              </p>
              <span className="single-top-border"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-white pt-5 pb-5">
        <div className="container">
          <div className="row section-title-top mb-5 pt-5" data-aos="fade-down">
            <div className="col-lg-5 text-left">
              <h2>
                Emerging <span className="highlight-orange">Technologies</span>{" "}
                <br />
                for Machine Learning
              </h2>
              <p className="color-7">
                Doodle Inc. has earned global recognition in custom software
                development. With a variety of modern technologies and
                approaches, we are serving our clients with dependable
                solutions.
              </p>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
              <div className="tech-item tech-color-1">
                <img src="/images/tech-test-img-01.png" alt="" />
                <p>
                  <span>Artificial Intelligence</span>AI-based algorithms &
                  automated operation for scalable solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
              <div className="tech-item tech-color-2">
                <img src="/images/tech-test-img-02.png" alt="" />
                <p>
                  <span>Internet of Things</span>IoT devices to make a
                  real-time, efficient, and robust infrastructure.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="tech-item tech-color-3">
                <img src="/images/tech-test-img-03.png" alt="" />
                <p>
                  <span>Cloud Computing</span>Flexible, scalable & integrated
                  business solution.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="tech-item tech-color-4">
                <img src="/images/tech-test-img-04.png" alt="" />
                <p>
                  <span>Big Data</span>Increased business intelligence and
                  dependable data analysis.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3 push-right">
              <div className="tech-item tech-color-5">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-05.png"
                  alt=""
                />
                <p>
                  <span>BlockChain</span>Secured data, automated key operations,
                  detailed database, reduces cost, and increases efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="tech-item tech-color-6">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-06.png"
                  alt=""
                />
                <p>
                  <span>Augmented Reality</span>Greater & interactive user
                  experience with increased efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="tech-item tech-color-7">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-07.png"
                  alt=""
                />
                <p>
                  <span>Virtual Reality</span>Simulated user experience that can
                  be applied in education, entertainment, business, and many
                  other sectors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="center-section section-white box-shadow">
        <div className="container">
          <div
            className="row section-title-top justify-content-center"
            data-aos="fade-down"
          >
            <div className="col-lg-12 text-center">
              <h2>
                Machine Learning
                <span className="highlight-orange">Life Cycle </span>
              </h2>
              <p className="color-7">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </div>
          </div>
          <div className="row mt-5 pt-4">
            <div className="col-lg-4 d-inline-table text-center">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        1. Planning <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      It all starts with collecting information from the
                      customers and getting into their core vision. So we set
                      the project goal, probable cost, and resources we’re going
                      to need.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. Defining Requirements{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Determining what the application is supposed to do and its
                      requirements. It also includes defining the resources
                      needed to develop the project and the technical dynamics
                      we may need to cover.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. Design <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The system and documents are arranged as per requirement
                      specifications. Such an arrangement helps to reach the
                      aimed system architecture and technology stack. Designers
                      start designing the user interface.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. Implementation/Building{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      This is where the core development starts. Developers
                      start to write code to build the whole system using chosen
                      language and methodologies. The designers continue working
                      on user interface and testers start analyzing the
                      requirements for their test plans.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFive">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5. Testing <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="headingFive"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      This phase is to find bugs in the code and fix them while
                      evaluating the overall quality of the software. The
                      testing team also evaluates the functionality of the
                      entire system.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingSix">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        6. Deployment <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSix"
                    className="collapse"
                    aria-labelledby="headingSix"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The application is made available to a specific set of
                      users and checked for issues. The basic ideas are sought
                      to be improved here.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingSeven">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        7. Review <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    aria-labelledby="headingSeven"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The product is ready. Possible bugs and imperfections are
                      reviewed. The aim is to make the software complete.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingEight">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        8. Launch <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseEight"
                    className="collapse"
                    aria-labelledby="headingEight"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      The final product is released to the market. Users
                      starting to use the product.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingNine">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        9. Maintenance <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseNine"
                    className="collapse"
                    aria-labelledby="headingNine"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Maintenance is performed over time to change the system
                      architecture according to future needs. This may require
                      the addition of code or alteration of the existing code,
                      bug fixing, upgrade, enhancement, server maintenance, and
                      much more are done during the maintenance phase.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-lg-0 mt-sm-5 d-inline-table lifecycle text-center">
              <img src="/images/lifecycle.png" alt="" />
              <div className="step01">
                <span className="stepleft">Deploy</span>
                <span className="stepright">Test</span>
              </div>
              <div className="step02">
                <span className="stepleft">Review</span>
                <span className="stepright">Develop</span>
              </div>
              <div className="step03">
                <span className="stepleft">Plan</span>
                <span className="stepcenter">Design</span>
                <span className="stepright">Launch</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToolsAndTechnologies />
      <TellUsAbout/>
      {/* <section
        className="center-section section-black pt-0 pb-0 box-shadow"
        style={{ background: "url(images/contact-bg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 bg-black ex-pt">
              <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
                <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                  <div className="award-text">
                    <span className="award-type">Award</span>{" "}
                    <span className="award-and">&</span> Certifications
                  </div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon01.png" alt="..." />
                  </div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon02.png" alt="..." />
                  </div>
                </div>
                <div className="col-lg-12 mr-auto">
                  <div className="counter-item">
                    <img src="images/circle-img01.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_01">9</span>
                        <span className="c_text_01">YEARS</span>IN OPERATION
                      </p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_02">100s</span>
                        <span className="c_text_01">OF CUSTOMERS</span>
                        WORLDWIDE
                      </p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_02">4.9/5</span>
                        <span className="c_text_01">CUSTOMERS</span>RATING
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 bg-white ex-pt">
              <div
                className="row p-5 pl-lg-5 section-title-top"
                data-aos="fade-down"
              >
                <div className="col-lg-12 pl-lg-5 text-left">
                  <h2>
                    Tell Us About{" "}
                    <span className="highlight-orange">Your Project</span>
                  </h2>
                </div>
                <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                  <form className="row">
                    <div className="form-group  mb-4 col-lg-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="John Smith"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>E-mail</label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="example@provider.com"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Phone</label>
                      <input
                        type="phone"
                        className="form-control form-control-lg"
                        placeholder="+1 112 222 333"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Ex: Pexel Inc."
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-12">
                      <label>Messages</label>
                      <textarea
                        className="form-control ex-h"
                        placeholder="Hello..."
                      ></textarea>
                    </div>
                    <div className="form-group col-lg-12">
                      <button type="submit" className="btn btn-primary btn-lg">
                        <span>Send Now</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="center-section section-white box-shadow">
        <div className="container">
          <div className="row section-title-top" data-aos="fade-down">
            <div className="col-lg-5 text-left">
              <h2>F.A.Q.</h2>
              <p className="color-7 pr-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </div>
            <div className="col-lg-7 faq-cls">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne1"
                        aria-expanded="false"
                        aria-controls="collapseOne1"
                      >
                        Lorem Ipsum is simply dummy text of the?
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne1"
                    className="collapse"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo1"
                        aria-expanded="false"
                        aria-controls="collapseTwo1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo1"
                    className="collapse"
                    aria-labelledby="headingTwo1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree1"
                        aria-expanded="false"
                        aria-controls="collapseThree1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree1"
                    className="collapse"
                    aria-labelledby="headingThree1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFive1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive1"
                        aria-expanded="false"
                        aria-controls="collapseFive1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive1"
                    className="collapse"
                    aria-labelledby="headingFive1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="center-section section-white box-shadow">
        <div className="container">
          <div className="row section-title-top" data-aos="fade-down">
            <div className="col-lg-12 text-center">
              <h2>Related Blog Articles</h2>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img1.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img2.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
          </div>
        </div>
      </section> */}
      {/*----------Footer---------*/}
      <Footer />
    </div>
  );
}
export default MachineLearning;
