import React, { Fragment } from "react";
import { connect } from "react-redux";
import PieceBlog from "./blog/PieceBlog";
import Pagination from "./blog/Pagination";
import {
  GetHomeEn,
  GetHomeBn,
  GetCat,
  GetTag,
  GetBlogList,
  GetBlogsByCategory,
  GetBlogsByTag,
  GetBlogsByTextSearch,
  GetTotalBlog,
  GetTotalBlogByCat,
  GetTotalBlogBySearch,
  GetTotalBlogByTag,
  TotalCat,
} from "./../actions/homeAction";
import AOS from "aos";
import { Link, withRouter } from "react-router-dom";
import { getAdsAction } from "../actions/adsAction";
import Footer from "./Footer";
// import { Pagination } from 'swiper/js/swiper.esm';
const axios = require("axios");

class Blog extends React.Component {
  constructor(props) {
    //console.log(props)
    super(props);
    this.state = {
      blogdata: [],
      categories: [],
      tags: [],
      searchText: "",
      is_search: false,
      blogSearchList: [],
      cat_total_limit: 50,
      tag_total_limit: 50,
      cat_current_limit: 5,
      tag_current_limit: 5,
      loading: true,
      dataExist: false,
      currentBlog: 1,
      blogPerPage: 10,
      blogByTagId: 0,
      dataExistSearch: "",
      blogByCategoryId: 0,
      active: 1,
      totalBlog: 0,
      tagSearchId: 0,
      catSearchId: 0,
    };
  }

  removeAllCataAndTags = () => {
    var parms = {
      limit: this.state.blogPerPage,
      offset: 0,
    };
    this.props.GetBlogList(parms).then((res) => {
      this.setState({
        blogdata: res,
        loading: false,
        blogByCategoryId: 0,
        blogByTagId: 0,
        searchText: "",
        is_search: false,
        dataExist: res.length ? false : true,
      });
    });
    var parms = {
      limit: 5,
      offset: 0,
    };

    this.props.GetCat(parms).then((res) => {
      this.setState({ categories: res });
    });

    this.props.GetTag(parms).then((res) => {
      this.setState({ tags: res });
    });
    this.props.GetTotalBlog().then((res) => {
      this.setState({ totalBlog: res.totalblog });
    });
  };
  componentDidMount = () => {
    this.props.TotalCat().then((res) => {
      this.setState({ cat_total_limit: res.totalCat });
    });

    this.props.GetHomeEn().then((res) => {
      console.log("we tag", res);
      this.setState({ tag_total_limit: res.totalTag });
    });
    // load ads
    if (!this.props.ads.loaded) {
      this.props.getAds();
    }

    var parms = {
      limit: this.state.blogPerPage,
      offset: 0,
    };
    this.props.GetBlogList(parms).then((res) => {
      this.setState({
        blogdata: res,
        loading: false,
        blogByCategoryId: 0,
        blogByTagId: 0,
        searchText: "",
        is_search: false,
        dataExist: res.length ? false : true,
      });

      if (this.props.category_route) {
        let { category_route } = this.props;
        window.history.pushState(
          { page: 1 },
          "Blog",
          window.location.origin + "/blog"
        );
        this.blogByCategory(
          category_route.category_id,
          category_route.category_name
        );
      }
      if (this.props.tag_route) {
        let { tag_route } = this.props;
        console.log(tag_route);
        //console.log(window.location.origin)
        window.history.pushState(
          { page: 1 },
          "Blog",
          window.location.origin + "/blog"
        );
        //this.blogByCategory(this.props.category_route.category_id, this.props.category_route.category_name);
        this.blogByTag(tag_route.tag_id, tag_route.tag_name);
      }
    });
    var parms = {
      limit: 5,
      offset: 0,
    };

    this.props.GetCat(parms).then((res) => {
      this.setState({ categories: res });
    });

    this.props.GetTag(parms).then((res) => {
      this.setState({ tags: res });
    });
    this.props.GetTotalBlog().then((res) => {
      this.setState({ totalBlog: res.totalblog });
    });

    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  };

  paginate = (number) => {
    var cat_id = this.state.blogByCategoryId;
    var tag_id = this.state.blogByTagId;
    var search_text = this.state.searchText;
    var is_search = this.state.is_search;

    if (cat_id == 0 && tag_id == 0 && !is_search) {
      this.setState({ active: number, loading: true });
      let parms = {
        limit: this.state.blogPerPage,
        offset: this.state.blogPerPage * (number - 1),
      };
      this.props.GetBlogList(parms).then((res) => {
        this.setState({
          blogdata: res,
          loading: false,
          blogByCategoryId: 0,
          blogByTagId: 0,
          searchText: "",
          is_search: false,
          dataExist: res.length ? false : true,
          blogSearchList: [],
        });
      });
    } else if (cat_id > 0) {
      this.setState({ loading: true });
      var parms = {
        limit: this.state.blogPerPage,
        category_id: cat_id,
        offset: this.state.blogPerPage * (number - 1),
      };
      this.props.GetBlogsByCategory(parms).then((res) => {
        this.setState({
          blogdata: res,
          loading: false,
          blogByCategoryId: cat_id,
          blogByTagId: 0,
          searchText: "",
          is_search: false,
          dataExist: res.length ? false : true,
          blogSearchList: [],
        });
      });
    } else if (tag_id > 0) {
      this.setState({ loading: true });
      var parms = {
        limit: this.state.blogPerPage,
        tag_id: tag_id,
        offset: this.state.blogPerPage * (number - 1),
      };
      this.props.GetBlogsByTag(parms).then((res) => {
        this.setState({
          blogdata: res,
          loading: false,
          blogByCategoryId: 0,
          blogByTagId: tag_id,
          searchText: "",
          is_search: false,
          dataExist: res.length ? false : true,
          blogSearchList: [],
        });
      });
    } else if (search_text != "" && is_search) {
      this.setState({ loading: true });
      var parms = {
        limit: this.state.blogPerPage,
        searchkey: this.state.searchText,
        offset: this.state.blogPerPage * (number - 1),
      };
      this.props.GetBlogsByTextSearch(parms).then((res) => {
        this.setState({
          blogdata: res,
          loading: false,
          blogByCategoryId: 0,
          is_search: true,
          dataExist: res.length ? false : true,
          blogByTagId: 0,
        });
      });
    }
  };

  tags_more = () => {
    var new_limit = 5 + this.state.tag_current_limit;
    var parms = {
      limit: new_limit,
      offset: 0,
    };
    this.setState({ tag_current_limit: new_limit });

    this.props.GetTag(parms).then((res) => {
      this.setState({ tags: res });
    });
  };

  categories_more = () => {
    var new_limit = 5 + this.state.cat_current_limit;
    var parms = {
      limit: new_limit,
      offset: 0,
    };
    this.setState({ cat_current_limit: new_limit });

    this.props.GetCat(parms).then((res) => {
      this.setState({ categories: res });
    });
  };

  blogByCategory = (id, cat_name) => {
    this.setState({ loading: true });
    var parms = {
      limit: this.state.blogPerPage,
      category_id: id,
      offset: 0,
    };
    this.props.GetBlogsByCategory(parms).then((res) => {
      if (res.length > 0) {
        this.setState({
          blogdata: res,
          loading: false,
          blogByCategoryId: id,
          blogByTagId: 0,
          searchText: "",
          is_search: false,
          dataExist: res.length ? false : true,
          blogSearchList: [],
        });
      } else {
        var newparms = {
          limit: this.state.blogPerPage,
          offset: 0,
        };
        this.props.GetBlogList(newparms).then((res) => {
          this.setState({
            blogdata: res,
            loading: false,
            blogByCategoryId: 0,
            blogByTagId: 0,
            searchText: "",
            is_search: false,
            dataExistSearch: ' searching by category "' + cat_name + '"',
            dataExist: true,
          });
        });
      }
    });

    let catParam = {
      category_id: id,
    };

    this.props.GetTotalBlogByCat(catParam).then((res) => {
      if (res.totalblog > 0) this.setState({ totalBlog: res.totalblog });
      else {
        this.props.GetTotalBlog().then((res) => {
          this.setState({ totalBlog: res.totalblog });
        });
      }
    });

    /*
     */
  };

  blogByTag = (id, tagname) => {
    console.log("Tags", id, tagname);
    this.setState({ loading: true });
    var parms = {
      limit: this.state.blogPerPage,
      tag_id: id,
      offset: 0,
    };
    this.props.GetBlogsByTag(parms).then((res) => {
      if (res.length > 0) {
        this.setState({
          blogdata: res,
          loading: false,
          blogByCategoryId: 0,
          blogByTagId: id,
          searchText: "",
          is_search: false,
          dataExist: res.length ? false : true,
          blogSearchList: [],
        });
      } else {
        var newparms = {
          limit: this.state.blogPerPage,
          offset: 0,
        };
        this.props.GetBlogList(newparms).then((res) => {
          this.setState({
            blogdata: res,
            loading: false,
            blogByCategoryId: 0,
            blogByTagId: 0,
            searchText: "",
            is_search: false,
            dataExistSearch: ' searching by tag "' + tagname + '"',
            dataExist: true,
          });
        });
      }
    });

    var tagParam = {
      tag_id: id,
    };
    this.props.GetTotalBlogByTag(tagParam).then((res) => {
      this.setState({ totalBlog: res.totalblog });
    });
  };

  blogBySearching = () => {
    this.setState({ loading: true });
    var parms = {
      limit: this.state.blogPerPage,
      searchkey: this.state.searchText,
      offset: 0,
    };
    this.props.GetBlogsByTextSearch(parms).then((res) => {
      if (res.length > 0) {
        this.setState({
          blogdata: res,
          loading: false,
          blogByCategoryId: 0,
          is_search: true,
          dataExist: res.length ? false : true,
          blogByTagId: 0,
        });
      } else {
        var newparms = {
          limit: this.state.blogPerPage,
          offset: 0,
        };
        this.props.GetBlogList(newparms).then((res) => {
          this.setState({
            blogdata: res,
            loading: false,
            blogByCategoryId: 0,
            blogByTagId: 0,
            searchText: "",
            is_search: false,
            dataExistSearch: ' searching by "' + this.state.searchText + '"',
            dataExist: true,
          });
        });
      }
    });

    var searchParam = {
      searchkey: this.state.searchText,
    };

    this.props.GetTotalBlogBySearch(searchParam).then((res) => {
      this.setState({ totalBlog: res.length });
    });
  };

  handleSearchText = (e) => {
    const { value } = e.target;

    this.setState({ searchText: value });
    if (value.length > 2) {
      var parms = {
        limit: 5,
        searchkey: value,
        offset: 0,
      };
      this.props.GetBlogsByTextSearch(parms).then((res) => {
        this.setState({ blogSearchList: res });
      });
    } else {
      this.setState({ blogSearchList: [] });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //console.log('enter press here! ')
      this.blogBySearching();
    }
  };

  getRightAd = () => {
    return this.props.ads.items.find((ad) => ad.type === "right");
  };

  
  render() {
    /* if (this.props.location && this.props.location.state && this.props.location.state.category_route) {
      console.log(this.props.location.state.category_route);
    } */

    const ad = this.getRightAd();

    const indexOfLastBlog = this.state.currentBlog * this.state.blogPerPage;
    const indexOfFirstBlog = indexOfLastBlog - this.state.blogPerPage;
    const currentBlogs = this.state.blogdata.slice(
      indexOfFirstBlog,
      indexOfLastBlog
    );
    const tags = this.state.tags;
    const categories = this.state.categories;
    const blogSearchList = this.state.blogSearchList;
    const dataExist = this.state.dataExist;
    const dataExistSearch = this.state.dataExistSearch;
    const blogByCategoryId = this.state.blogByCategoryId;
    const blogByTagId = this.state.blogByTagId;
    return (
      <div>

      {  console.log(window.location.origin)}
        {/*Background image section*/}
        <section className="center-section pt-0 pb-0 section-white">
          <div
            className="bg-image-section"
            style={{
              background: "url(images/blog-banner.jpg) no-repeat center center",
              backgroundSize: "cover",
            }}
          >
            <div className="img-overly1 pt-5 pb-5 center-section">
              <div className="container pt-5 pb-5">
                <div className="row justify-content-center pt-4 pb-4">
                  <div className="col-lg-9 pt-4 pb-4 text-center">
                    <div className="text-wrapper p-wrapper">
                      <h1 className="text-white text-center">Blog</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*White Section*/}
        <section className="center-section section-white">
          <div className="container">
            <div className="row">
              <div className="col-9 mb-5">
                {this.state.loading == true ? (
                  <h2>Loading ...</h2>
                ) : (
                  <div>
                    {dataExist && (
                      <div>
                        <h2>
                          No data found{" "}
                          {dataExistSearch != "" && "for " + dataExistSearch}{" "}
                          <span
                            style={{
                              float: "right",
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => this.removeAllCataAndTags()}
                          >
                            x
                          </span>
                        </h2>
                      </div>
                    )}

                    {currentBlogs.length > 0 &&
                      currentBlogs.map((item, i) => (
                        <PieceBlog item={item} i={i} key={i} />
                      ))}
                  </div>
                )}
                {currentBlogs.length > 0 && (
                  <Pagination
                    blogPerPage={this.state.blogPerPage}
                    totalBLogs={this.state.totalBlog}
                    paginate={this.paginate}
                    active={this.state.active}
                  />
                )}
              </div>
              <div className="col-3 blog-right-sidebar text-left">
                <div className="input-group input-group-lg mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleSearchText}
                    onKeyPress={this.handleKeyPress}
                    placeholder="Search..."
                    autoFocus={true}
                    value={this.state.searchText}
                    aria-describedby="button-addon2"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => this.blogBySearching()}
                      type="button"
                      id="button-addon2"
                    >
                      <svg
                        width={17}
                        height={18}
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5714 7.92857C11.5714 10.4096 9.55246 12.4286 7.07143 12.4286C4.5904 12.4286 2.57143 10.4096 2.57143 7.92857C2.57143 5.44754 4.5904 3.42857 7.07143 3.42857C9.55246 3.42857 11.5714 5.44754 11.5714 7.92857ZM16.7143 16.2857C16.7143 15.9442 16.5737 15.6127 16.3426 15.3817L12.8973 11.9364C13.7109 10.7612 14.1429 9.35491 14.1429 7.92857C14.1429 4.0212 10.9788 0.857142 7.07143 0.857142C3.16406 0.857142 0 4.0212 0 7.92857C0 11.8359 3.16406 15 7.07143 15C8.49777 15 9.90402 14.5681 11.0792 13.7545L14.5246 17.1897C14.7556 17.4308 15.0871 17.5714 15.4286 17.5714C16.1317 17.5714 16.7143 16.9888 16.7143 16.2857Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {blogSearchList.length > 0 &&
                  blogSearchList.map((blog, i) => (
                    <p key={i}>
                      <a href={"/blog/" + blog.canonical_url}>
                        {blog.b_tittle}
                      </a>
                      {/* <Link 
                        to={"/blog_details/" + blog.b_id}
                        className="color-2"
                      >
                        {" "}
                        {blog.b_tittle}
                      </Link> */}
                    </p>
                  ))}
                <p className="highlight-orange font-weight-semibold font-size-18 mt-5 mb-3">
                  CATEGORIES
                </p>
                {categories.length > 0 &&
                  categories.map((item, i) => (
                    <p
                      key={i}
                      className={
                        blogByCategoryId != 0 && blogByCategoryId == item.c_id
                          ? "highlight-orange"
                          : ""
                      }
                    >
                      {blogByCategoryId != 0 &&
                      blogByCategoryId == item.c_id ? (
                        <a className="highlight-orange">
                          {" "}
                          {item.c_tittle}{" "}
                          <span
                            style={{
                              float: "right",
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => this.removeAllCataAndTags()}
                          >
                            x
                          </span>
                        </a>
                      ) : (
                        <a
                          className="color-2"
                          onClick={() =>
                            this.blogByCategory(item.c_id, item.c_tittle)
                          }
                        >
                          {" "}
                          {item.c_tittle}{" "}
                        </a>
                      )}
                    </p>
                  ))}
                {console.log("current", this.state.cat_current_limit)}
                {console.log("total", this.state.cat_total_limit)}

                {this.state.cat_total_limit > this.state.cat_current_limit && (
                  <a
                    className="btn__more"
                    /* href="javascript:void(0)" */
                    onClick={() => this.categories_more()}
                  >
                    <svg
                      width={14}
                      height={18}
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 13.9092C4.28773 13.9092 2.09091 11.7123 2.09091 9.00006C2.09091 8.16962 2.29954 7.39233 2.65956 6.70506L1.465 5.5105C0.83088 6.52503 0.454529 7.71549 0.454529 9.00006C0.454529 12.6164 3.38361 15.5455 7 15.5455V18.0001L10.2727 14.7273L7 11.4546V13.9092Z"
                        fill="#999999"
                      />
                      <path
                        d="M7.00001 2.45455V0L3.72729 3.27272L7.00001 6.54543V4.09089C9.71228 4.09089 11.9091 6.28771 11.9091 8.99998C11.9091 9.83042 11.7005 10.6077 11.3404 11.295L12.535 12.4895C13.1691 11.475 13.5455 10.2846 13.5455 8.99998C13.5454 5.38363 10.6164 2.45455 7.00001 2.45455Z"
                        fill="#999999"
                      />
                    </svg>
                    See More
                  </a>
                )}

                <p className="highlight-orange font-weight-semibold font-size-18 mt-5 mb-3">
                  Tags
                </p>
                {tags.length > 0 &&
                  tags.map((tag, i) => (
                    <p
                      key={i}
                      className={
                        blogByTagId != 0 && blogByTagId == tag.t_id
                          ? "highlight-orange"
                          : ""
                      }
                    >
                      {blogByTagId != 0 && blogByTagId == tag.t_id ? (
                        <a className="highlight-orange">
                          {" "}
                          {tag.t_tittle}
                          <span
                            style={{
                              float: "right",
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => this.removeAllCataAndTags()}
                          >
                            x
                          </span>
                        </a>
                      ) : (
                        <a
                          className="color-2"
                          onClick={() => this.blogByTag(tag.t_id, tag.t_tittle)}
                        >
                          {" "}
                          {tag.t_tittle}
                        </a>
                      )}
                    </p>
                  ))}
                {this.state.tag_total_limit > tags.length && (
                  <a
                    className="btn__more"
                    /* href="javascript:void(0)" */
                    onClick={() => this.tags_more()}
                  >
                    <svg
                      width={14}
                      height={18}
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 13.9092C4.28773 13.9092 2.09091 11.7123 2.09091 9.00006C2.09091 8.16962 2.29954 7.39233 2.65956 6.70506L1.465 5.5105C0.83088 6.52503 0.454529 7.71549 0.454529 9.00006C0.454529 12.6164 3.38361 15.5455 7 15.5455V18.0001L10.2727 14.7273L7 11.4546V13.9092Z"
                        fill="#999999"
                      />
                      <path
                        d="M7.00001 2.45455V0L3.72729 3.27272L7.00001 6.54543V4.09089C9.71228 4.09089 11.9091 6.28771 11.9091 8.99998C11.9091 9.83042 11.7005 10.6077 11.3404 11.295L12.535 12.4895C13.1691 11.475 13.5455 10.2846 13.5455 8.99998C13.5454 5.38363 10.6164 2.45455 7.00001 2.45455Z"
                        fill="#999999"
                      />
                    </svg>
                    See More
                  </a>
                )}

                {ad && (
                  <a href={ad.link}>
                    <img
                      className="mt-5 w-100"
                      src={this.props.ads.cdn_path + ad.image}
                      alt={ad.text}
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
          <span className="effect-bg-top-right-outline" />
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  homeData: state.homeData,
  catData: state.catData,
  blogData: state.blogData,
  ads: state.ads,
});

const mapDispatchToProps = (dispatch) => ({
  TotalCat: () => dispatch(TotalCat()),
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
  GetTotalBlog: () => dispatch(GetTotalBlog()),
  GetTotalBlogByCat: (requestData) => dispatch(GetTotalBlogByCat(requestData)),
  GetTotalBlogByTag: (requestData) => dispatch(GetTotalBlogByTag(requestData)),
  GetCat: (requestData) => dispatch(GetCat(requestData)),
  GetTotalBlogBySearch: (requestData) =>
    dispatch(GetTotalBlogBySearch(requestData)),
  GetTag: (requestData) => dispatch(GetTag(requestData)),
  GetBlogList: (requestData) => dispatch(GetBlogList(requestData)),
  GetBlogsByTag: (requestData) => dispatch(GetBlogsByTag(requestData)),
  GetBlogsByCategory: (requestData) =>
    dispatch(GetBlogsByCategory(requestData)),
  GetBlogsByTextSearch: (requestData) =>
    dispatch(GetBlogsByTextSearch(requestData)),
  getAds: () => dispatch(getAdsAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Blog));
