import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SideVideo from './video/SideVideo'
import HeadlineSlider from './slider/HeadlineSlider'
import MenuSlider from './slider/MenuSlider'
import HomeLeftSlider from './slider/HomeLeftSlider'
import { GetHomeEn, GetHomeBn } from './../actions/homeAction';
import AOS from 'aos';
import Newsletter from './newsletter/Newsletter';
import Footer from './Footer';



class Approach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoName: ''
    }
  }
  componentDidMount = () => {
    document.title = "Approach"
    // var element = document.getElementById("blogPage");
    // element.classList.remove("active");

    this.props.GetHomeEn().then(res => {
      //console.log(res[0].emp_name);

    })
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  }
  render() {
    return (
      <div>
      {/*-Banner section-*/}
      <section className="banner" style={{ background: 'url(images/services-banner.png) no-repeat bottom', backgroundSize: 'cover' }}>
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">Approach</h1>
              </div>
              {/* <p className="text-white pl-lg-0 pl-4 pr-lg-0 pr-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
            </div>
          </div>
          {/* <a className="mt-5 arrow-down mb-5" href="#makingtheworl">
            <svg width={20} height={37} viewBox="0 0 20 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1.63028C5.40498 1.63028 1.66667 5.28699 1.66667 9.78168V27.2186C1.66667 31.7133 5.40498 35.37 10 35.37C14.595 35.37 18.3333 31.7133 18.3333 27.2186V9.78168C18.3333 5.28699 14.595 1.63028 10 1.63028ZM10 0C15.5229 0 20 4.37941 20 9.78168V27.2186C20 32.6208 15.5229 37.0003 10 37.0003C4.47715 37.0003 0 32.6208 0 27.2186V9.78168C0 4.37941 4.47715 0 10 0Z" fill="white" />
              <path d="M10 15.3106C13.1218 15.3106 15.6525 12.8352 15.6525 9.78153C15.6525 6.7279 13.1218 4.25244 10 4.25244C6.87824 4.25244 4.34753 6.7279 4.34753 9.78153C4.34753 12.8352 6.87824 15.3106 10 15.3106Z" fill="white" />
            </svg>
          </a> */}
        </div>
      </section>
        
        
      <section className="center-section pt-3 pb-3 section-black">
          <div className="container">
            <div className="row pt-3 pb-3 text-center">
              <div className="col-lg-5 ml-auto">
                <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                
              </div>
              <div className="col-lg-5 mobile-hide-only mr-auto">
                <div className="counter-item">
                  <img src="images/circle-img01.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>

      <section className="section-white pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-12 text-center">
                <p className="color-7">Doodle follows a systematic & strategic approach to execute your solutions. The main objective of our service is to ensure that our solution aligns with your business requirement. So each step of our development process aims to uphold your vision within our solution.</p>
              </div>
            </div>
        </div>
      </section>
      
      <section id="list-example" className="section-gray mobile-hide-only position-sticky">
        <div className="container">
          <div className="row scrollspy-continer-left"> 
              <div className="left-scroll">
                <div className="list-group">
                  {/* <a className="list-group-item list-group-item-action" href="#list-item-1" onClick="event.preventDefault();">1. Approach</a> */}
                  <a className="list-group-item list-group-item-action" href="#list-item-2" onClick="event.preventDefault();">1. Discovery Workshop</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-3" onClick="event.preventDefault();">2. Planning</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-4" onClick="event.preventDefault();">3. Design</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-5" onClick="event.preventDefault();">4. Development</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-6" onClick="event.preventDefault();">5. Testing</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-7" onClick="event.preventDefault();">6. Maintenance</a>
                </div>
              </div>
          </div>
        </div>
      </section>
      
      <section id="list-example" className="section-white desktop-hide-only">
        <div className="container text-center">
          <h2 id="list-item-1" className="mb-4">Let’s talk about the specifics</h2>
          <div className="row scrollspy-continer-left">
              <div className="left-scroll">
                <div className="list-group">
                  <a className="list-group-item list-group-item-action active" href="#list-item-1" onClick="event.preventDefault();">1</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-2" onClick="event.preventDefault();">2</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-3" onClick="event.preventDefault();">3</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-4" onClick="event.preventDefault();">4</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-5" onClick="event.preventDefault();">5</a>
                  <a className="list-group-item list-group-item-action" href="#list-item-6" onClick="event.preventDefault();">6</a>
                </div>
              </div>
          </div>
        </div>
      </section>

      <section  data-spy="scroll" data-target="#list-example" data-offset="0" className="scrollspy-example section-white pt-5 pb-5">
        <div className="container">
          <div className="scrollspy-continer-left-">
            <div className="row section-title-top justify-content-center" data-aos="fade-down">
              <div className="right-content">
                <div>
                  {/* <h2 id="list-item-1" className="mobile-hide-only">Let’s talk about the specifics</h2>
                  <h3 className="text-center desktop-hide-only">Approach</h3> */}
                  {/* <div className="black-spy row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-white">Objective</h5> 
                            <ul className="text-white pl-4">
                            Doodle follows a systematic & strategic approach to execute your solutions. The main objective of our service is to ensure that our solution aligns with your business requirement. So each step of our development process aims to uphold your vision within our solution. 
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        <div className="discovery-workshop-right">
                            <img src="images/discovery.jpg" alt="..." />
                        </div>
                    </div> */}
                  {/* </div> */}
                  {/* <div className="white-spy pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Outcome</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Lorem Ipsum is simply</span></li>
                                <li><span>Lorem Ipsum is simply dummy</span></li>
                                <li><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                <li><span>Lorem Ipsum is simply dummy text</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        
                    </div>
                  </div> */}
                  {/* <div className="white-spy pt-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Team Members Involved</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Lorem Ipsum is simply</span></li>
                                <li><span>Lorem Ipsum is simply dummy</span></li>
                                <li><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                <li><span>Lorem Ipsum is simply dummy text</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        
                    </div>
                  </div>
                   */}



                  <h3 className="text-center desktop-hide-only">Discovery Workshop</h3>
                  <div id="list-item-2" className="black-spy row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-white">Discovery Workshop</h5> 
                            <ul className="text-white pl-4">
                                <li><span>Research and market analysis.</span></li>
                                <li><span>Determining the use of the application.</span></li>
                                <li><span>Identifying users.</span></li>
                                <li><span>Dynamics we need to cover.</span></li>
                                <li><span>Resources we may need to develop the project.</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        <div className="discovery-workshop-right">
                            {/* <img src="images/blog-img02.jpg" alt="..." />   */}
                            <img src="images/discovery.jpg" alt="..." />
                        </div>
                    </div>
                  </div>
                  <div className="white-spy pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Outcome</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Solutions as per the vision of the client.</span></li>
                                <li><span>Cost reduction.</span></li>
                                <li><span>Quicker delivery.</span></li>
                                <li><span>Risk minimization</span></li>
                                <li><span>Delivery of a better product</span>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div>
                  {/* <div className="white-spy pt-3 pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Deliverables</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Lorem Ipsum is simply</span></li>
                                <li><span>Lorem Ipsum is simply dummy</span></li>
                                <li><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                <li><span>Lorem Ipsum is simply dummy text</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div> */}
                  {/* <div className="white-spy pt-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Team Members Involved</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Lorem Ipsum is simply</span></li>
                                <li><span>Lorem Ipsum is simply dummy</span></li>
                                <li><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                <li><span>Lorem Ipsum is simply dummy text</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div> */}
                  

                  
                  
                  <h3 className="text-center desktop-hide-only">Planning</h3>
                  <div id="list-item-3" className="black-spy row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-white">Planning</h5> 
                            <ul className="text-white pl-4">
                                <li><span>Understanding the client’s vision.</span></li>
                                <li><span>Setting up the project goal.</span></li>
                                <li><span>Cost estimation of the project execution.</span></li>
                                <li><span>Necessary resources for the project implementation.</span></li>
                                <li><span>Defining technology stack.</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        <div className="discovery-workshop-right">
                            <img src="images/2nd-banner.jpg" alt="..." />
                        </div>
                    </div>
                  </div>
                  <div className="white-spy pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Outcome</h5> 
                            <ul className="text-black pl-4">
                                <li><span>A clear idea of the product’s usage.</span></li>
                                <li><span>A better understanding of the development phase.</span></li>
                                <li><span>Estimation of expenses and time necessary for the project. </span></li>
                                <li><span>Usage of right tools and frameworks ensured</span></li>
                                <li><span>Successful implementation of the product as the customer wants. </span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">

                    </div>
                  </div>
                  {/* <div className="white-spy pt-3 pb-5 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 pb-4 text-black">Tools</h5> 
                            <div className="row m-0">
                                <div className="col pl-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                        <g fill="none" fill-rule="nonzero">
                                            <path fill="#001D26" d="M1.5 1.5h33v32.1h-33z"></path>
                                            <path fill="#00C8FF" d="M0 0v35.1h36V0H0zm1.5 1.5h33v32.1h-33V1.5zm7.2 6.72c0-.105.21-.18.33-.18a89.61 89.61 0 0 1 3.87-.075c4.155 0 5.775 2.28 5.775 5.205 0 3.81-2.76 5.445-6.15 5.445-.57 0-.765-.03-1.17-.03v5.76c0 .12-.045.18-.18.18H8.88c-.12 0-.18-.045-.18-.18V8.22zm2.67 7.965c.345.03.615.03 1.215.03 1.755 0 3.405-.615 3.405-3 0-1.905-1.185-2.865-3.18-2.865-.6 0-1.17.03-1.44.045v5.79zm12.9-1.785c-1.185 0-1.59.6-1.59 1.095 0 .54.27.915 1.86 1.74 2.355 1.14 3.09 2.235 3.09 3.84 0 2.4-1.83 3.69-4.305 3.69-1.305 0-2.43-.27-3.075-.645-.105-.045-.12-.12-.12-.24v-2.205c0-.15.075-.195.18-.12a5.568 5.568 0 0 0 3.015.885c1.185 0 1.68-.495 1.68-1.17 0-.54-.345-1.02-1.86-1.8-2.13-1.02-3.015-2.055-3.015-3.78 0-1.935 1.515-3.54 4.14-3.54 1.29 0 2.19.195 2.685.42.12.075.15.195.15.3v2.055c0 .12-.075.195-.225.15-.66-.42-1.635-.675-2.61-.675z"></path>
                                        </g>
                                    </svg>                                                                        
                                </div>
                                <div className="col pl-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36">
                                        <g fill="none" fill-rule="nonzero">
                                            <path fill="#261300" d="M1.7 1.5h33v32.1h-33z"></path>
                                            <path fill="#FF7C00" d="M.2 0v35.1h36V0H.2zm1.5 1.5h33v32.1h-33V1.5z"></path>
                                            <path fill="#FF7C00" d="M12.695 19.875l-1.185 4.5c-.03.12-.075.15-.225.15H9.08c-.15 0-.18-.045-.15-.225l4.26-14.91c.075-.27.12-.51.15-1.245 0-.105.045-.15.12-.15h3.15c.105 0 .15.03.18.15l4.77 16.185c.03.12 0 .195-.12.195h-2.49c-.12 0-.195-.03-.225-.135l-1.245-4.515h-4.785zm4.17-2.43c-.42-1.665-1.41-5.295-1.785-7.05h-.03c-.315 1.755-1.11 4.725-1.74 7.05h3.555zm6.75-8.34c0-.96.675-1.53 1.53-1.53.915 0 1.53.615 1.53 1.53 0 .99-.645 1.53-1.56 1.53-.87 0-1.5-.54-1.5-1.53zm.18 3.405c0-.12.045-.18.18-.18h2.355c.12 0 .18.045.18.18v11.835c0 .12-.03.18-.18.18h-2.325c-.15 0-.195-.075-.195-.195V12.51h-.015z"></path>
                                        </g>
                                    </svg>                                                                        
                                </div>
                                <div className="col pl-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36">
                                        <g fill="none" fill-rule="nonzero">
                                            <path fill="#2E001E" d="M1.9 1.5h33v32.1h-33z"></path>
                                            <path fill="#FF2BC2" d="M.4 0v35.1h36V0H.4zm1.5 1.5h33v32.1h-33V1.5z"></path>
                                            <path fill="#FF2BC2" d="M26.545 14.745c-.225-.105-.51-.15-.87-.15-1.89 0-3.15 1.455-3.15 3.87 0 2.76 1.29 3.87 2.97 3.87.36 0 .75-.045 1.035-.165v-7.425h.015zm-6.72 3.84c0-3.495 2.25-6.225 5.94-6.225.315 0 .48 0 .78.03v-3.9c0-.09.075-.15.15-.15h2.415c.12 0 .15.045.15.12v13.71c0 .405 0 .915.075 1.47 0 .105 0 .12-.09.165-1.26.6-2.58.87-3.84.87-3.255 0-5.58-2.01-5.58-6.09zm-5.115-2.43l4.2 8.145c.075.12.03.24-.09.24h-2.61c-.165 0-.24-.045-.315-.195-.96-1.98-1.935-4.035-2.94-6.165h-.03c-.9 2.01-1.89 4.2-2.85 6.18-.075.12-.15.165-.27.165h-2.49c-.15 0-.165-.12-.09-.21l4.11-7.905L7.36 8.55c-.09-.12 0-.225.105-.225h2.58c.15 0 .225.03.27.165.945 1.995 1.905 3.96 2.805 5.97h.03c.87-1.98 1.83-3.975 2.745-5.94.075-.12.12-.195.27-.195h2.415c.12 0 .165.09.09.225l-3.96 7.605z"></path>
                                        </g>
                                    </svg>                                                                                                                                                
                                </div>
                                <div className="col pl-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="33" viewBox="0 0 37 33">
                                        <g fill="none" fill-rule="nonzero">
                                            <path fill="#EA6C00" d="M7.914 11.71l10.648 20.842L.664 11.71zM29.214 11.71L18.566 32.552 36.464 11.71z"></path>
                                            <path fill="#FDAD00" d="M7.914 11.71H29.21L18.562 32.552z"></path>
                                            <path fill="#FDD231" d="M18.562.16L8.474 1.225l-.56 10.484zM18.562.16L28.65 1.225l.56 10.484z"></path>
                                            <path fill="#FDAD00" d="M36.464 11.71l-7.81-10.484.56 10.484zM.664 11.71l7.81-10.484-.56 10.484z"></path>
                                            <path fill="#FEEEB7" d="M18.562.16L7.914 11.71H29.21z"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="col pl-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35">
                                        <g fill="none" fill-rule="nonzero">
                                            <path fill="#F11B5C" d="M32.627 0H3.973A3.173 3.173 0 0 0 .8 3.173v28.654A3.173 3.173 0 0 0 3.973 35h28.654a3.173 3.173 0 0 0 3.173-3.173V3.173A3.173 3.173 0 0 0 32.627 0"></path>
                                            <path fill="#FFF" d="M12.497 11.012c1.163 0 2.138-.912 2.138-2.107 0-1.194-.975-2.106-2.138-2.106-1.164 0-2.138.912-2.138 2.106 0 1.195.974 2.107 2.138 2.107M8.064 22.294a7.236 7.236 0 0 0-.189 1.583c0 1.855 1.006 3.087 3.144 3.087 1.774 0 3.211-1.053 4.246-2.754l-.632 2.537h3.521l2.013-8.072c.503-2.043 1.478-3.104 2.956-3.104 1.163 0 1.886.723 1.886 1.918 0 .346-.031.723-.157 1.132l-1.038 3.71a5.577 5.577 0 0 0-.22 1.572c0 1.761 1.038 3.05 3.207 3.05 1.855 0 3.333-1.195 4.15-4.056l-1.383-.534c-.692 1.917-1.289 2.263-1.76 2.263-.472 0-.724-.314-.724-.942 0-.283.063-.597.157-.976l1.007-3.614a7.972 7.972 0 0 0 .346-2.293c0-2.704-1.636-4.115-3.616-4.115-1.855 0-3.742 1.673-4.685 3.434l.691-3.16h-5.376l-.755 2.783h2.516l-1.55 6.202c-1.216 2.704-3.45 2.748-3.73 2.685-.46-.104-.755-.279-.755-.877 0-.345.063-.841.22-1.439l2.358-9.355H7.938l-.755 2.784h2.484l-1.603 6.551"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="col pl-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" viewBox="0 0 40 32">
                                        <g fill="none" fill-rule="nonzero">
                                            <path fill="#FDBD39" d="M6.165 22.059l-2.092.764-.111 6.224 7.242-2.646c-2.486-.832-4.3-2.3-5.039-4.342"></path>
                                            <path fill="#F69833" d="M7.26 15.477L0 18.129l4.073 4.696 2.092-.764c-.737-2.036-.282-4.334 1.095-6.584"></path>
                                            <path fill="#FECF33" d="M19.477 6.494C10.232 9.871 4.27 16.841 6.165 22.06l33.478-12.23C37.748 4.613 28.72 3.119 19.477 6.495"></path>
                                            <path fill="#EE6723" d="M26.33 25.396C35.575 22.019 41.545 15.08 39.64 9.83L6.164 22.06c1.904 5.251 10.921 6.714 20.166 3.337"></path>
                                            <path fill="#F69833" d="M39.641 9.83L6.165 22.059c.655 1.808 3.156 2.699 6.663 2.699 3.42 0 7.797-.846 12.36-2.513C34.433 18.868 40.936 13.4 39.641 9.83"></path>
                                            <path fill="#FDBD39" d="M32.979 7.133c-3.419 0-7.797.845-12.36 2.512C11.372 13.022 4.87 18.49 6.164 22.06l33.478-12.23c-.657-1.807-3.156-2.696-6.664-2.696"></path>
                                            <g fill="#EE6723">
                                                <path d="M31.13 29.378c-.986 0-2.439-.89-4.32-2.644-2.225-2.076-4.773-5.117-7.177-8.562-2.403-3.445-4.377-6.888-5.56-9.698-1.289-3.061-1.473-4.943-.547-5.594.214-.146.468-.221.727-.214.753 0 2.222.56 4.936 3.232l.06.058-.008.083c-.019.193-.035.386-.05.577l-.03.377-.266-.268c-2.925-2.946-4.211-3.263-4.63-3.263a.484.484 0 0 0-.282.071c-.403.283-.43 1.665.82 4.632 1.162 2.758 3.108 6.15 5.48 9.55 2.373 3.402 4.882 6.396 7.069 8.435 2.356 2.199 3.418 2.437 3.766 2.437.12 0 .216-.025.289-.077.423-.297.423-1.778-.97-4.972l-.064-.146.14-.077c.14-.076.276-.15.402-.224l.168-.095.078.176c.987 2.264 1.91 5.14.7 5.991-.215.147-.47.221-.732.215zm0-.35v.175-.174z"></path>
                                                <path d="M26.554 25.3l-.013.172c-.234 2.892-.85 6.368-2.455 6.51-.033.003-.066.003-.098.003-1.09 0-2.122-1.562-3.064-4.641-.894-2.915-1.587-6.827-1.951-11.017-.366-4.19-.36-8.162.015-11.189C19.397 1.841 20.174.12 21.301.02c.033-.003.068-.005.102-.005.79 0 1.982.896 3.202 5.067l-.796.153c-.407-1.393-.851-2.526-1.291-3.307-.407-.72-.805-1.117-1.115-1.117-.01 0-.02 0-.032.002-.489.043-1.2 1.226-1.595 4.423-.37 2.972-.374 6.887-.014 11.022.361 4.136 1.042 7.99 1.92 10.851.392 1.282.822 2.338 1.246 3.055.389.661.766 1.025 1.06 1.025.01 0 .02 0 .03-.002.467-.04 1.348-1.273 1.719-5.608l.817-.28z"></path>
                                            </g>
                                        </g>
                                    </svg>                                                                                                                                               
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">

                    </div>
                  </div> */}
                  {/* <div className="white-spy pt-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Team Members Involved</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Lorem Ipsum is simply</span></li>
                                <li><span>Lorem Ipsum is simply dummy</span></li>
                                <li><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                <li><span>Lorem Ipsum is simply dummy text</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">

                    </div>
                  </div> */}

                  
                
                  
                  
                  <h3 className="text-center desktop-hide-only">Design</h3>
                  <div id="list-item-4" className="black-spy row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-white">Design</h5> 
                            <ul className="text-white pl-4">
                                <li><span>Defining and outlining the functionality of every module.</span></li>
                                <li><span>Completing architecture diagrams along with technical details.</span></li>
                                <li><span>Identifying database tables along with their key elements.</span></li>
                                <li><span>Prototyping and wireframing</span></li>
                                <li><span>Creating screen section schemes</span></li>
                                <li><span>UI & UX design.</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        <div className="discovery-workshop-right">
                            <img src="images/blog-img01.jpg" alt="..." />
                        </div>
                    </div>
                  </div>
                  <div className="white-spy pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Outcome</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Helps to ensure the reliability and quality of the final product.</span></li>
                                <li><span>Better user interface.</span></li>
                                <li><span>Better user experience.</span></li>
                                <li><span>Idea about the functionality of the product.</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div>
                  {/* <div className="white-spy pt-3 pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Mobile Technologies</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Lorem Ipsum is simply</span></li>
                                <li><span>Lorem Ipsum is simply dummy</span></li>
                                <li><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                <li><span>Lorem Ipsum is simply dummy text</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div> */}
                  {/* <div className="white-spy pt-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Team Members Involved</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Lorem Ipsum is simply</span></li>
                                <li><span>Lorem Ipsum is simply dummy</span></li>
                                <li><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                <li><span>Lorem Ipsum is simply dummy text</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div> */}

                  
                
                  
                  
                  <h3 className="text-center desktop-hide-only">Development</h3>
                  <div id="list-item-5" className="black-spy row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-white">Development</h5> 
                            <ul className="text-white pl-4">
                                <li><span>Implementing chosen language and methodologies.</span></li>
                                <li><span>Creating a strong code-base.</span></li>
                                <li><span>Improving the user interface.</span></li>
                                <li><span>Analyzation of the test plans by the testing team.</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        <div className="discovery-workshop-right">
                            <img src="images/blog-img06.jpg" alt="..." />
                        </div>
                    </div>
                  </div>

                  {/* <div className="white-spy row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Tools</h5> 
                            <div className="row m-0">
                                <div className="col-4 tools-col pl-0">
                                    <div className="tools-img">
                                        <img className="blur-out" src="images/jira.jpg" alt="..." />
                                    </div>
                                </div>
                                <div className="col-4 tools-col pl-0">
                                    <div className="tools-img">
                                        <img className="blur-out" src="images/mantis-bug-tracker-logo.jpg" alt="..." />
                                    </div>                                                                      
                                </div>
                                <div className="col-4 tools-col pl-0">
                                    <div className="tools-img">
                                        <img className="blur-out" src="images/firebase.jpg" alt="..." />
                                    </div>
                                </div>
                                <div className="col-4 tools-col pl-0">
                                    <div className="tools-img">
                                        <img className="blur-out" src="images/jmeter.jpg" alt="..." />
                                    </div>
                                </div>
                                <div className="col-4 tools-col pl-0">
                                    <div className="tools-img">
                                        <img className="blur-out" src="images/GTmetrix.jpg" alt="..." />
                                    </div>
                                </div>
                                <div className="col-4 tools-col pl-0">
                                    <div className="tools-img">
                                        <img className="blur-out" src="images/se.jpg" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div> */}
                  <div className="white-spy pt-3 pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Outcome</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Code of the software.</span></li>
                                <li><span>Aligning design with the code.</span></li>
                                <li><span>Testing the code’s functionality.</span></li>
                                <li><span>Adaptation of proven methodologies and frameworks. </span></li>
                                <li><span>Building the software as per the customer’s requirement. </span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div>
                  {/* <div className="white-spy pt-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Team Members Involved</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Lorem Ipsum is simply</span></li>
                                <li><span>Lorem Ipsum is simply dummy</span></li>
                                <li><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                <li><span>Lorem Ipsum is simply dummy text</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div> */}
                  

                  <h3 className="text-center desktop-hide-only">Testing</h3>
                  <div id="list-item-6" className="black-spy row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-white">Testing</h5> 
                            <ul className="text-white pl-4">
                                <li><span>Scanning flaws of the program.</span></li>
                                <li><span>Finding bugs and solving them.</span></li>
                                <li><span>Ensuring the functionality of the software.</span></li>
                                <li><span>Vulnerability, penetration & security testing. </span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        <div className="discovery-workshop-right">
                            <img src="images/blog-img02.jpg" alt="..." />
                        </div>
                    </div>
                  </div>
                  <div className="white-spy pt-3 pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Outcome</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Ensures flawless performance.  </span></li>
                                <li><span>Finding scope of improvement. </span></li>
                                <li><span>Finding vulnerabilities of the software.</span></li>
                                <li><span>Scope of increasing security.  </span></li>
                                {/* <li><span>Serving clients 24*7 as the situation demands.  </span></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div>
{/* maintance */}

                  <h3 className="text-center desktop-hide-only">Maintenance</h3>
                  <div id="list-item-7" className="black-spy row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-white">Maintenance</h5> 
                            <ul className="text-white pl-4">
                                <li><span>Changing the system architecture according to future needs.</span></li>
                                <li><span>Adding & altering existing code.</span></li>
                                <li><span>Bug fixing, upgrading, enhancement of the software.</span></li>
                                <li><span>Server maintenance, system update & security management. </span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                        <div className="discovery-workshop-right">
                            <img src="images/maintenance.jpg" alt="..." />
                        </div>
                    </div>
                  </div>
                  <div className="white-spy pt-3 pb-3 row">
                    <div className="col-md-12 col-lg-6 align-top p-0">
                        <div className="common-padd-left">
                            <h5 className="text-uppercase l-spacing-3 text-black">Outcome</h5> 
                            <ul className="text-black pl-4">
                                <li><span>Updating the software to a more efficient version. </span></li>
                                <li><span>Adding new features. </span></li>
                                <li><span>Ensuring flawless performance.</span></li>
                                <li><span>Ensuring the security of the solution.  </span></li>
                                <li><span>Serving clients 24*7 as the situation demands.  </span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-bottom mobile-white-bg p-0">
                      
                    </div>
                  </div>

                </div>
              </div>
            </div>
        </div>
        </div>
      </section>

      
      {/* <section className="section-white pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top mb-5 pt-5" data-aos="fade-down">
              <div className="col-lg-12 text-center">
                <h2>Project <span className="highlight-orange">Communication</span> Structure</h2>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                <div className="pc-item">
                  <h3 className="text-white pb-4">Daily Scrum Standup</h3>
                  <p>Mon -Fri<br />30 minutes</p>
                  <div className="border-bottom-p"></div>
                  <p>Lorem Ipsum is simply dummy text of the </p>
                  <div className="border-bottom-p"></div>
                  <p>Lorem Ipsum is simply dummy text of the printing</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                <div className="pc-item">
                  <h3 className="text-white pb-4">Weekly<br />Review</h3>
                  <p>Weekly<br />45-60 minutes</p>
                  <div className="border-bottom-p"></div>
                  <p>Lorem Ipsum is simply dummy text of the </p>
                  <div className="border-bottom-p"></div>
                  <p>Lorem Ipsum is simply dummy text of the printing</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                <div className="pc-item">
                  <h3 className="text-white pb-4">Sprint<br/>Delivery</h3>
                  <p>2-3 weeks<br />1-2 hours</p>
                  <div className="border-bottom-p"></div>
                  <p>Lorem Ipsum is simply dummy text of the </p>
                  <div className="border-bottom-p"></div>
                  <p>Lorem Ipsum is simply dummy text of the printing</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="pc-item">
                  <h3 className="text-white pb-4">Code<br/>Reviews</h3>
                  <p>Bi-Weekly<br />3-4 hours</p>
                  <div className="border-bottom-p"></div>
                  <p>Lorem Ipsum is simply dummy text of the </p>
                  <div className="border-bottom-p"></div>
                  <p>Lorem Ipsum is simply dummy text of the printing</p>
                </div>
              </div>
            </div>
        </div>
      </section> */}

      
      <section className="section-white pt-0 pb-5">
        <div className="container">
            <div className="row section-title-top mb-5 pt-5" data-aos="fade-down">
              <div className="col-lg-5 text-left">
                <h2><span className="highlight-orange">Collaboration</span> Tools</h2>
                <p className="color-7">We prioritize proper communication and thus we use profound platforms to ensure smooth communication. We can opt for the communication tools you prefer and comfortable with. </p>
              </div>
              <div className="col-lg-7 pl-lg-5 text-lrft">
                <div class="row">
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/jira.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/slack.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/github.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/asana.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/invision.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/axure.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/zeplin.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/office365.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/zoom.jpg" alt="..." />
                      </div>
                  </div>
                  <div class="col-3 tools-img-list">
                      <div class="tools-img">
                          <img class="blur-out" src="images/skype.jpg" alt="..." />
                      </div>
                  </div>
              </div>
              </div>
            </div>
        </div>
      </section>
      
      

      
      {/* <section className="center-section section-white box-shadow">
          <div className="container">
            <div className="row section-title-top" data-aos="fade-down">
              <div className="col-lg-5 text-left">
                <h2>Engagement Models</h2>
                <p className="color-7 pr-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
              <div className="col-lg-7 faq-cls">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne1">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                        Lorem Ipsum is simply dummy?<i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>

                    <div id="collapseOne1" className="collapse" aria-labelledby="headingOne1" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo1">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1">
                        Lorem Ipsum is simply dummy?<i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo1" className="collapse" aria-labelledby="headingTwo1" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
      </section> */}
      


      


      
      <section className="center-section section-black pt-0 pb-0 box-shadow"  style={{ background: 'url(images/contact-bg.png)' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 bg-black ex-pt">
              <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
                <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                  <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                 
                </div>
                <div className="col-lg-12 mr-auto">
                  <div className="counter-item">
                    <img src="images/circle-img01.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 bg-white ex-pt">
              <div className="row p-5 pl-lg-5 section-title-top" data-aos="fade-down">
                <div className="col-lg-12 pl-lg-5 text-left">
                  <h2>Tell Us About <span className="highlight-orange">Your Project</span></h2>
                </div>
                <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                  <form className="row">
                    <div className="form-group  mb-4 col-lg-6">
                      <label>Name</label>
                      <input type="text" className="form-control form-control-lg" placeholder="John Smith" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>E-mail</label>
                      <input type="email" className="form-control form-control-lg" placeholder="example@provider.com" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Phone</label>
                      <input type="phone" className="form-control form-control-lg" placeholder="+1 112 222 333" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Company</label>
                      <input type="text" className="form-control form-control-lg" placeholder="Ex: Pexel Inc." />
                    </div>
                    <div className="form-group mb-4 col-lg-12">
                      <label>Messages...</label>
                      <textarea className="form-control" placeholder="Hello..."></textarea>
                    </div>
                    <div className="form-group col-lg-12">
                      <button type="submit" className="btn btn-primary btn-lg"><span>Send Now</span></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*----------Footer---------*/}
      <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Approach)
