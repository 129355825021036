import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Cookies, withCookies } from "react-cookie";
import Home from "./Home";
import Corporate from "./Corporate";
import Blog from "./Blog";
import Blogdetails from "./Blogdetails";
import DedicatedOffshoreTeam from "./DedicatedOffshoreTeam";
import Portfolio from "./Portfolio";
import Contribution from "./Contribution";
import Contact from "./Contact";
import Services from "./Services";
import Technology from "./technology";
import Approach from "./approach";
import Engagement from "./engagement";
import Industries from "./industries";
import Certifications from "./certifications";
import NewsletterPopup from "./newsletter/NewsletterPopup";
//Services Software Department
import CustomSoftware from "./services/softwareDepartment/CustomSoftware";
import CloudApplication from "./services/softwareDepartment/CloudApplication";
import EnterpriseSoftware from "./services/softwareDepartment/EnterpriseSoftware";
import Product from "./services/softwareDepartment/Product";
import MobileApplication from "./services/softwareDepartment/MobileApplication";
import WebApplication from "./services/softwareDepartment/WebApplication";
import SystemIntegration from "./services/softwareDepartment/SystemIntegration";
//Services Advanced Tech
import Bigdata from "./services/advancedTech/Bigdata";
import ArtificialIntelligence from "./services/advancedTech/ArtificialIntelligence";
import DataAnalyticsandVisualization from "./services/advancedTech/DataAnalyticsandVisualization";
import InternetofThings from "./services/advancedTech/InternetofThings";
import MachineLearning from "./services/advancedTech/MachineLearning";
import RoboticProcessAutomation from "./services/advancedTech/RoboticProcessAutomation";
//Services Infrastructure
import CloudInfrastructure from "./services/infrastructure/CloudInfrastructure";
import HybridInfrastructure from "./services/infrastructure/HybridInfrastructure";
import OnPremisesInfrastructure from "./services/infrastructure/OnPremisesInfrastructure";
import Integration from "./services/infrastructure/Integration";
import Migration from "./services/infrastructure/Migration";
import DevOps from "./services/infrastructure/DevOps";
//Services Advisory
import ApplicationSupport from "./services/advisory/ApplicationSupport";
import BusinessTechnologyConsulting from "./services/advisory/BusinessTechnologyConsulting";
import CloudStrategyConsulting from "./services/advisory/CloudStrategyConsulting";
import CustomerSupport from "./services/advisory/CustomerSupport";
import DigitalTransformation from "./services/advisory/DigitalTransformation";
import InfrastructureSupport from "./services/advisory/InfrastructureSupport";
import MaintenanceandSupport from "./services/advisory/MaintenanceandSupport";
// Services Security and Compliance
import ApplicationSecurity from "./services/SecurityandCompliance/ApplicationSecurity";
import InfrastructureSecurity from "./services/SecurityandCompliance/InfrastructureSecurity";
import NetworkSecurity from "./services/SecurityandCompliance/NetworkSecurity";
import RisksandCompliance from "./services/SecurityandCompliance/RisksandCompliance";
// Services How to get Started
import HireDedicatedTeam from "./services/started/HireDedicatedTeam";
import Overview from "./services/started/Overview";
import ProjectBasedEngagement from "./services/started/ProjectBasedEngagement";
import SLABasedApproach from "./services/started/SLABasedApproach";
// Services BI & Analytics
import BIConsulting from "./services/biandanalytics/BIConsulting";
import BIImplementation from "./services/biandanalytics/BIImplementation";
import BIServiceDeliveryAndFramework from "./services/biandanalytics/BIServiceDeliveryAndFramework";
import BiBigData from "./services/biandanalytics/BiBigData";
// Services Marketing & Branding
import DigitalAdvertising from "./services/marketingbranding/DigitalAdvertising";
import LifecycleMarketing from "./services/marketingbranding/LifecycleMarketing";
import SMSMarketing from "./services/marketingbranding/SMSMarketing";
import SearchEngineOptimization from "./services/marketingbranding/SearchEngineOptimization";
import SiteTesting from "./services/marketingbranding/SiteTesting";
import SocialMediaMarketing from "./services/marketingbranding/SocialMediaMarketing";
import StrategyandPlanning from "./services/marketingbranding/StrategyandPlanning";
// import Services1 from "./services/Services1";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import ContactUs from "./contact/ContactUs";
import AskForQuote from "./contact/AskForQuote";
import ScheduleCall from "./contact/ScheduleCall";
import AboutDoodle from "./company/AboutDoodle";
import ResponsibleContribution from "./company/ResponsibleContribution";
import CompanyBlog from "./company/Blog";
import CompanyCareer from "./company/Career";
import CaseStudies from "./company/CaseStudies";

class Nav extends React.Component {
  render() {
    var pathname = window.document.location.pathname;
    return (
      <Router>
        <header className="main-header desktop-header">
          <nav className="navbar navbar-expand-lg navbar-light">
            <NavLink className="navbar-brand" to="/">
              <img width="100px" src="/images/new-logo-doodle-white-text.png" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse my-2 my-lg-0"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto mr-5 pr-3">
                <li className="nav-item">
                  <NavLink exact className="nav-link" to="/">
                    Home{" "}
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Services
                  </a>
                  <div
                    className="dropdown-menu mega-nav"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="row p-2 m-0">
                      <div className="col-lg-3 mb-4">
                        <p className="color-4 font-weight-bold mb-1">
                          Software Development
                        </p>
                        <ul>
                          {/* custom cloud enterprisesoft mobileapp product webapp */}
                          <li>
                            <NavLink to="/custom-software-development">
                              Custom Software Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/enterprise-software-development">
                              Enterprise Software Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/product-development">
                              Product Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/web-application-evelopment">
                              Web Application Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/mobile-application-development">
                              Mobile Application Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/cloud-application-development">
                              Cloud Application Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/system-integration">
                              System Integration
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 mb-4">
                        <p className="color-4 font-weight-bold mb-1">
                          Advanced Tech
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/robotic-process-automation">
                              Robotic Process Automation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/artificial-intelligence">
                              Artificial Intelligence
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/machineLearning">
                              Machine Learning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/internet-of-things">
                              Internet of Things
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/data-analyticsand-visualization">
                              Data Analytics and Visualization
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/big-data">Big data</NavLink>
                          </li>
                        </ul>
                      </div>

                      <div className="col-lg-3 mb-4">
                        <p className="color-4 font-weight-bold mb-1">
                          Advisory
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/digital-transformation">
                              Digital Transformation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/business-technology-consulting">
                              Business Technology Consulting
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/cloud-strategy-consulting">
                              Cloud Strategy Consulting
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/maintenance-and-support">
                              Maintenance and Support
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/application-support">
                              Application Support
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/infrastructure-support">
                              Infrastructure Support
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer-support">
                              Customer Support
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="col-lg-3 mb-4">
                        <p className="color-4 font-weight-bold mb-1">
                          Marketing & Branding
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/strategy-and-planning">
                              Strategy and Planning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/search-engine-optimization">
                              Search Engine Optimization
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/digital-advertising">
                              Digital Advertising
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/social-media-marketing">
                              Social Media Marketing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/site-testing">Site Testing</NavLink>
                          </li>
                          <li>
                            <NavLink to="/sms-marketing">SMS Marketing</NavLink>
                          </li>
                          <li>
                            <NavLink to="/lifecycle-marketing">
                              Lifecycle Marketing
                            </NavLink>
                          </li>
                        </ul>
                      </div> */}
                      <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          How to get Started?
                        </p>
                        <ul>
                          {/* <li>
                            <NavLink to="/overview">Overview</NavLink>
                          </li> */}
                          <li>
                            <NavLink to="/project-based-engagement">
                              Project Based Engagement
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/hire-dedicated-team-Center">
                              Offshore Dedicated Team
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/sla-based-approach">
                              SLA-Based Approach
                            </NavLink>
                          </li>
                        </ul>
                      </div>

                      <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          Infrastructure
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/cloud-infrastructure">
                              Cloud Infrastructure
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/hybrid-infrastructure">
                              Hybrid Infrastructure
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/on-premises-infrastructure">
                              On-Premises Infrastructure
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/integration">Integration</NavLink>
                          </li>
                          <li>
                            <NavLink to="/migration">Migration</NavLink>
                          </li>
                          <li>
                            <NavLink to="/devOps">DevOps</NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          Security and Compliance
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/network-security">
                              Network Security
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/infrastructure-security">
                              Infrastructure Security
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/application-security">
                              Application Security
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/risks-and-compliance">
                              Risks and Compliance
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          BI & Analytics
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/bi-consulting">BI Consulting</NavLink>
                          </li>
                          <li>
                            <NavLink to="/bi-implementation">
                              BI Implementation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/bi-service-delivery-and-framework">
                              BI Service Delivery & Framework
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/bi-big-data">Big Data</NavLink>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          How to get Started?
                        </p>
                        <ul> */}
                      {/* <li>
                            <NavLink to="/overview">Overview</NavLink>
                          </li> */}
                      {/* <li>
                            <NavLink to="/project-based-engagement">
                              Project Based Engagement
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/hire-dedicated-team-Center">
                              Hire Dedicated Team/ Center
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/sla-based-approach">
                              SLA-Based Approach
                            </NavLink>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Expert Solution
                  </a>
                  <div
                    className="dropdown-menu mega-nav single-dropdown"
                    aria-labelledby="navbarDropdown1"
                  >
                    <div className="row p-2 m-0">
                      <div className="col-lg-12">
                        <ul>
                          <li><a href="#">Social Media Platform</a></li>
                          <li><a href="#">Social Network Platform</a></li>
                          <li><a href="#">Dating Platform</a></li>
                          <li><a href="#">Ecommerce Platform</a></li>
                          <li><a href="#">News Portal Platform</a></li>
                          <li><a href="#">Booking Portal Platform</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li> */}
                <li className="nav-item">
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to="/technology"
                  >
                    Technology
                  </NavLink>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Industries
                  </a>
                  <div
                    className="dropdown-menu mega-nav single-dropdown"
                    aria-labelledby="navbarDropdown1"
                  >
                    <div className="row p-2 m-0">
                      <div className="col-lg-12">
                        <ul>
                          <li>
                            <a href="#">Finance</a>
                          </li>
                          <li>
                            <a href="#">Sholesale and Retail</a>
                          </li>
                          <li>
                            <a href="#">Entertainment</a>
                          </li>
                          <li>
                            <a href="#">Energy</a>
                          </li>
                          <li>
                            <NavLink to="/industries">Healthcare</NavLink>
                          </li>
                          <li>
                            <a href="#">Education</a>
                          </li>
                          <li>
                            <a href="#">Real Estate</a>
                          </li>
                          <li>
                            <a href="#">Mid-Sized Businesses</a>
                          </li>
                          <li>
                            <a href="#">Construction</a>
                          </li>
                          <li>
                            <a href="#">Transportation</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li> */}
                <li className="nav-item">
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to="/approach"
                  >
                    Approach
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Company
                  </a>
                  <div
                    className="dropdown-menu mega-nav single-dropdown"
                    aria-labelledby="navbarDropdown1"
                  >
                    <div className="row p-2 m-0">
                      <div className="col-lg-12">
                        <ul>
                          <li>
                            <NavLink to="/about-doodle">About Doodle</NavLink>
                          </li>
                          <li>
                            <NavLink to="/responsible-contribution">
                              Responsible Contribution
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="/career">Career</NavLink>
                          </li>
                          <li>
                            <NavLink to="/case-studies">
                              Case Studies
                            </NavLink>
                          </li> */}
                          {/*  <li>
                            <a to="#">Quality Policy</a>
                          </li> */}
                          {/* <li>
                            <a href="#">Testimonial</a>
                          </li> */}
                          {/* <li>
                            <NavLink to="/blogs">Blog</NavLink>
                          </li> */}
                          {/* <li>
                            <a href="#">CSR</a>
                          </li> */}
                          {/* <li>
                            <a href="#">Partnership</a>
                          </li>
                          <li>
                            <NavLink to="/certifications">
                              Award and Certifications
                            </NavLink>
                          </li> */}
                          {/* <li>
                            <a href="#">Our Team</a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="/">
                    Academy
                  </NavLink>
                </li> */}
                {/* <li className="nav-item">
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to="/DedicatedOffshoreTeam"
                  >
                    Dedicated Offshore Team
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Corporate
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to="/corporate">
                      <img src="/images/menu-img1.jpg" alt="img" />
                      <div className="sub-menu-block">
                        <h3>Corporate Profile</h3>
                        <p className="color-4">
                          Our team loves exploring new ideas, technologies and
                          tools of all kinds of industries.{" "}
                        </p>
                      </div>
                    </Link>
                    <Link className="dropdown-item" to="/Contribution">
                      <img src="/images/menu-img2.jpg" alt="img" />
                      <div className="sub-menu-block">
                        <h3>Responsible Contribution</h3>
                        <p className="color-4">
                          Our team loves exploring new ideas, technologies and
                          tools of all kinds of industries.{" "}
                        </p>
                      </div>
                    </Link>
                    <Link className="dropdown-item" to="/Contact">
                      <img src="/images/menu-img3.jpg" alt="img" />
                      <div className="sub-menu-block">
                        <h3>Contac Us</h3>
                        <p className="color-4">
                          Our team loves exploring new ideas, technologies and
                          tools of all kinds of industries.{" "}
                        </p>
                      </div>
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to="/Portfolio"
                  >
                    Portfolio
                  </NavLink>
                </li> */}
                {/* <li className="nav-item"> */}
                {/* <a href="/blog" id="blogPage" className={'nav-link' + (pathname.includes("blog") ? ' active' : '')}>Blog</a> */}
                {/* <NavLink
                    activeClassName="active"
                    className="nav-link"
                    to="/blog"
                    id="blogPage"
                  >
                    Blog
                  </NavLink> */}
                {/* </li>  */}
              </ul>
              <div className="contactbtnh contact-dropdown">
                <Link
                  to="/contact-us"
                  className="btn btn-primary "
                  role="button"
                  aria-pressed="true"
                >
                  <span >Contact</span>
                </Link>
                {/* <div className="contact-dropdown-toggle p-4">
                  <ul className="mb-0">
                    <li>
                      <NavLink to="/schedule-a-call">Schedule a Call</NavLink>
                    </li>
                    <li>
                      <NavLink to="/ask-for-a-quote">Ask for a Quote</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact-us">Contact Us</NavLink>
                    </li>
                  </ul>
                </div> */}
              </div>
              <a className="call-action-btn" href="tel:+88 01966 000 050">
                <img src="/images/call-icon.png" alt="img" />{" "}
                <span>+88 01966 000 051</span>
              </a>
            </div>
          </nav>
        </header>

        {/* <!---Mobile Header---> */}
        <header className="main-header mobile-header">
          <nav className="navbar navbar-expand-lg navbar-light">
            <NavLink className="navbar-brand" to="/">
              <img width="100px" src="/images/new-logo-doodle-white-text.png" />
            </NavLink>
            <button
              className="navbar-toggler p-0 float-right"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 12.75V14.25C18 14.4531 17.9258 14.6289 17.7773 14.7773C17.6289 14.9258 17.4531 15 17.25 15H0.75C0.546875 15 0.371094 14.9258 0.222656 14.7773C0.0742188 14.6289 0 14.4531 0 14.25V12.75C0 12.5469 0.0742188 12.3711 0.222656 12.2227C0.371094 12.0742 0.546875 12 0.75 12H17.25C17.4531 12 17.6289 12.0742 17.7773 12.2227C17.9258 12.3711 18 12.5469 18 12.75ZM18 6.75V8.25C18 8.45312 17.9258 8.62891 17.7773 8.77734C17.6289 8.92578 17.4531 9 17.25 9H0.75C0.546875 9 0.371094 8.92578 0.222656 8.77734C0.0742188 8.62891 0 8.45312 0 8.25V6.75C0 6.54687 0.0742188 6.37109 0.222656 6.22266C0.371094 6.07422 0.546875 6 0.75 6H17.25C17.4531 6 17.6289 6.07422 17.7773 6.22266C17.9258 6.37109 18 6.54687 18 6.75ZM18 0.749999V2.25C18 2.45312 17.9258 2.62891 17.7773 2.77734C17.6289 2.92578 17.4531 3 17.25 3H0.75C0.546875 3 0.371094 2.92578 0.222656 2.77734C0.0742188 2.62891 0 2.45312 0 2.25V0.749999C0 0.546875 0.0742188 0.371093 0.222656 0.222655C0.371094 0.0742182 0.546875 -3.57628e-07 0.75 -3.57628e-07H17.25C17.4531 -3.57628e-07 17.6289 0.0742182 17.7773 0.222655C17.9258 0.371093 18 0.546875 18 0.749999Z"
                  fill="black"
                />
              </svg>
            </button>

            <div
              className="collapse navbar-collapse my-lg-0"
              id="navbarSupportedContent"
            >
              <div className="mobile-nav-white text-center">
                <a className="navbar-brand" href="index.html">
                  <img width="100px" src="images/new-logo-doodle.png" />
                </a>
                <ul className="navbar-nav ml-auto mb-5 text-left">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li
                    className="nav-item dropdown addremove collapsed"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent1"
                    aria-controls="navbarSupportedContent1"
                    aria-expanded="false"
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href=""
                      id="navbarDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                    >
                      Services{" "}
                      <svg
                        className="nav-add"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="21" height="22" fill="#EEEEEE" />
                        <path
                          d="M15.4286 9.64286C15.4286 9.28795 15.1406 9 14.7857 9H12V6.21429C12 5.85937 11.7121 5.57143 11.3571 5.57143H10.0714C9.71652 5.57143 9.42857 5.85937 9.42857 6.21429V9H6.64286C6.28795 9 6 9.28795 6 9.64286V10.9286C6 11.2835 6.28795 11.5714 6.64286 11.5714H9.42857V14.3571C9.42857 14.7121 9.71652 15 10.0714 15H11.3571C11.7121 15 12 14.7121 12 14.3571V11.5714H14.7857C15.1406 11.5714 15.4286 11.2835 15.4286 10.9286V9.64286Z"
                          fill="#999999"
                        />
                      </svg>
                      <svg
                        className="nav-remove"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="21" height="22" fill="#34D633" />
                        <path
                          d="M15.4286 9.64286C15.4286 9.28795 15.1406 9 14.7857 9H6.64286C6.28795 9 6 9.28795 6 9.64286V10.9286C6 11.2835 6.28795 11.5714 6.64286 11.5714H14.7857C15.1406 11.5714 15.4286 11.2835 15.4286 10.9286V9.64286Z"
                          fill="white"
                        />
                      </svg>{" "}
                    </a>
                    <div
                      className="dropdown-menu collapse"
                      id="navbarSupportedContent1"
                    >
                      <div className="row m-0">
                        <div className="col-lg-3 pr-0 mb-4">
                          <p className="color-4 font-weight-bold mb-1">
                            Software Department
                          </p>
                        <ul>
                          {/* custom cloud enterprisesoft mobileapp product webapp */}
                          <li>
                            <NavLink to="/custom-software-development">
                              Custom Software Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/enterprise-software-development">
                              Enterprise Software Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/product-development">
                              Product Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/web-application-evelopment">
                              Web Application Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/mobile-application-development">
                              Mobile Application Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/cloud-application-development">
                              Cloud Application Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/system-integration">
                              System Integration
                            </NavLink>
                          </li>
                        </ul>
                        </div>
                        <div className="col-lg-3 pr-0 mb-4">
                          <p className="color-4 font-weight-bold mb-1">
                            Advanced Tech
                          </p>
                        <ul>
                          <li>
                            <NavLink to="/robotic-process-automation">
                              Robotic Process Automation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/artificial-intelligence">
                              Artificial Intelligence
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/machineLearning">
                              Machine Learning
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/internet-of-things">
                              Internet of Things
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/data-analyticsand-visualization">
                              Data Analytics and Visualization
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/big-data">Big data</NavLink>
                          </li>
                        </ul>
                        </div>
                        <div className="col-lg-3 pr-0 mb-4">
                          <p className="color-4 font-weight-bold mb-1">
                            Advisory
                          </p>
                        <ul>
                          <li>
                            <NavLink to="/digital-transformation">
                              Digital Transformation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/business-technology-consulting">
                              Business Technology Consulting
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/cloud-strategy-consulting">
                              Cloud Strategy Consulting
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/maintenance-and-support">
                              Maintenance and Support
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/application-support">
                              Application Support
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/infrastructure-support">
                              Infrastructure Support
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/customer-support">
                              Customer Support
                            </NavLink>
                          </li>
                        </ul>
                        </div>
                        {/* <div className="col-lg-3 pr-0 mb-4">
                          <p className="color-4 font-weight-bold mb-1">
                            Marketing & Branding
                          </p>
                          <ul>
                            <li>
                              <a href="#">Strategy and Planning</a>
                            </li>
                            <li>
                              <a href="#">Search Engine Optimization</a>
                            </li>
                            <li>
                              <a href="#">Digital Advertising</a>
                            </li>
                            <li>
                              <a href="#">Social Media Marketing</a>
                            </li>
                            <li>
                              <a href="#">Site Testing</a>
                            </li>
                            <li>
                              <a href="#">SMS Marketing</a>
                            </li>
                            <li>
                              <a href="#">Lifecycle Marketing</a>
                            </li>
                          </ul>
                        </div> */}


                      <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          Infrastructure
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/cloud-infrastructure">
                              Cloud Infrastructure
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/hybrid-infrastructure">
                              Hybrid Infrastructure
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/on-premises-infrastructure">
                              On-Premises Infrastructure
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/integration">Integration</NavLink>
                          </li>
                          <li>
                            <NavLink to="/migration">Migration</NavLink>
                          </li>
                          <li>
                            <NavLink to="/devOps">DevOps</NavLink>
                          </li>
                        </ul>
                      </div>

                        
                      <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          Security and Compliance
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/network-security">
                              Network Security
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/infrastructure-security">
                              Infrastructure Security
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/application-security">
                              Application Security
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/risks-and-compliance">
                              Risks and Compliance
                            </NavLink>
                          </li>
                        </ul>
                      </div>

                        
                      <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          BI & Analytics
                        </p>
                        <ul>
                          <li>
                            <NavLink to="/bi-consulting">BI Consulting</NavLink>
                          </li>
                          <li>
                            <NavLink to="/bi-implementation">
                              BI Implementation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/bi-service-delivery-and-framework">
                              BI Service Delivery & Framework
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/bi-big-data">Big Data</NavLink>
                          </li>
                        </ul>
                      </div>

                        
                      <div className="col-lg-3">
                        <p className="color-4 font-weight-bold mb-1">
                          How to get Started?
                        </p>
                        <ul>
                          {/* <li>
                            <NavLink to="/overview">Overview</NavLink>
                          </li> */}
                          <li>
                            <NavLink to="/project-based-engagement">
                              Project Based Engagement
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/hire-dedicated-team-Center">
                            Offshore Dedicated Team
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/sla-based-approach">
                              SLA-Based Approach
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/technology">
                      Technology
                    </NavLink>
                  </li>
                  {/* <li
                    className="nav-item dropdown addremove collapsed"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent2"
                    aria-controls="navbarSupportedContent2"
                    aria-expanded="false"
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href=""
                      id="navbarDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                    >
                      Industries{" "}
                      <svg
                        className="nav-add"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="21" height="22" fill="#EEEEEE" />
                        <path
                          d="M15.4286 9.64286C15.4286 9.28795 15.1406 9 14.7857 9H12V6.21429C12 5.85937 11.7121 5.57143 11.3571 5.57143H10.0714C9.71652 5.57143 9.42857 5.85937 9.42857 6.21429V9H6.64286C6.28795 9 6 9.28795 6 9.64286V10.9286C6 11.2835 6.28795 11.5714 6.64286 11.5714H9.42857V14.3571C9.42857 14.7121 9.71652 15 10.0714 15H11.3571C11.7121 15 12 14.7121 12 14.3571V11.5714H14.7857C15.1406 11.5714 15.4286 11.2835 15.4286 10.9286V9.64286Z"
                          fill="#999999"
                        />
                      </svg>
                      <svg
                        className="nav-remove"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="21" height="22" fill="#FF8135" />
                        <path
                          d="M15.4286 9.64286C15.4286 9.28795 15.1406 9 14.7857 9H6.64286C6.28795 9 6 9.28795 6 9.64286V10.9286C6 11.2835 6.28795 11.5714 6.64286 11.5714H14.7857C15.1406 11.5714 15.4286 11.2835 15.4286 10.9286V9.64286Z"
                          fill="white"
                        />
                      </svg>{" "}
                    </a>
                    <div
                      className="dropdown-menu collapse"
                      id="navbarSupportedContent2"
                    >
                      <div className="row m-0">
                        <div className="col-lg-3 pr-0 pl-0 mb-4">
                          <ul>
                            <li>
                              <a href="#">Finance</a>
                            </li>
                            <li>
                              <a href="#">Sholesale and Retail</a>
                            </li>
                            <li>
                              <a href="#">Entertainment</a>
                            </li>
                            <li>
                              <a href="#">Energy</a>
                            </li>
                            <li>
                              <NavLink to="/industries">Healthcare</NavLink>
                            </li>
                            <li>
                              <a href="#">Education</a>
                            </li>
                            <li>
                              <a href="#">Real Estate</a>
                            </li>
                            <li>
                              <a href="#">Mid-Sized Businesses</a>
                            </li>
                            <li>
                              <a href="#">Construction</a>
                            </li>
                            <li>
                              <a href="#">Transportation</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/approach">
                      Approach
                    </NavLink>
                  </li>
                  <li
                    className="nav-item dropdown addremove collapsed"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent3"
                    aria-controls="navbarSupportedContent2"
                    aria-expanded="false"
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href=""
                      id="navbarDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                    >
                      Company{" "}
                      <svg
                        className="nav-add"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="21" height="22" fill="#EEEEEE" />
                        <path
                          d="M15.4286 9.64286C15.4286 9.28795 15.1406 9 14.7857 9H12V6.21429C12 5.85937 11.7121 5.57143 11.3571 5.57143H10.0714C9.71652 5.57143 9.42857 5.85937 9.42857 6.21429V9H6.64286C6.28795 9 6 9.28795 6 9.64286V10.9286C6 11.2835 6.28795 11.5714 6.64286 11.5714H9.42857V14.3571C9.42857 14.7121 9.71652 15 10.0714 15H11.3571C11.7121 15 12 14.7121 12 14.3571V11.5714H14.7857C15.1406 11.5714 15.4286 11.2835 15.4286 10.9286V9.64286Z"
                          fill="#999999"
                        />
                      </svg>
                      <svg
                        className="nav-remove"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="21" height="22" fill="#34D633" />
                        <path
                          d="M15.4286 9.64286C15.4286 9.28795 15.1406 9 14.7857 9H6.64286C6.28795 9 6 9.28795 6 9.64286V10.9286C6 11.2835 6.28795 11.5714 6.64286 11.5714H14.7857C15.1406 11.5714 15.4286 11.2835 15.4286 10.9286V9.64286Z"
                          fill="white"
                        />
                      </svg>{" "}
                    </a>
                    <div
                      className="dropdown-menu collapse"
                      id="navbarSupportedContent3"
                    >
                      <div className="row m-0">
                        <div className="col-lg-3 pr-0 pl-0 mb-4">
                          <ul>
                            <li>
                              <NavLink to="/about-doodle">About Doodle</NavLink>
                            </li>
                            <li>
                              <NavLink to="/responsible-contribution">
                                Responsible Contribution
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      Contact <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" href="#">
                      Academy
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      Contact <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  {/* <li
                    className="nav-item dropdown addremove collapsed"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent3"
                    aria-controls="navbarSupportedContent3"
                    aria-expanded="false"
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href=""
                      id="navbarDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                    >
                      Contact{" "}
                      <svg
                        className="nav-add"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="21" height="22" fill="#EEEEEE" />
                        <path
                          d="M15.4286 9.64286C15.4286 9.28795 15.1406 9 14.7857 9H12V6.21429C12 5.85937 11.7121 5.57143 11.3571 5.57143H10.0714C9.71652 5.57143 9.42857 5.85937 9.42857 6.21429V9H6.64286C6.28795 9 6 9.28795 6 9.64286V10.9286C6 11.2835 6.28795 11.5714 6.64286 11.5714H9.42857V14.3571C9.42857 14.7121 9.71652 15 10.0714 15H11.3571C11.7121 15 12 14.7121 12 14.3571V11.5714H14.7857C15.1406 11.5714 15.4286 11.2835 15.4286 10.9286V9.64286Z"
                          fill="#999999"
                        />
                      </svg>
                      <svg
                        className="nav-remove"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="21" height="22" fill="#FF8135" />
                        <path
                          d="M15.4286 9.64286C15.4286 9.28795 15.1406 9 14.7857 9H6.64286C6.28795 9 6 9.28795 6 9.64286V10.9286C6 11.2835 6.28795 11.5714 6.64286 11.5714H14.7857C15.1406 11.5714 15.4286 11.2835 15.4286 10.9286V9.64286Z"
                          fill="white"
                        />
                      </svg>{" "}
                    </a>
                    <div
                      className="dropdown-menu collapse"
                      id="navbarSupportedContent3"
                    >
                      <div className="row m-0">
                        <div className="col-lg-3 pr-0 pl-0 mb-4">
                          <ul>
                            <li>
                              <a href="#">Schedule a Call</a>
                            </li>
                            <li>
                              <a href="#">Ask for a Quote</a>
                            </li>
                            <li>
                              <a href="#">Contact Us</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
                <a
                  className="call-action-btn color-0 border-0"
                  href="tel:+88 01966 000 050"
                >
                  <img src="/images/call-icon.png" alt="img" />{" "}
                  <span>+88 01966 000 051</span>
                </a>

                <div className="col mt-4 pl-0 pr-0 social-icons text-center">
                  <a className="color-2" href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="color-2" href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="color-2" href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a className="color-2" href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
              <span
                className="nav-close"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4866 11.6116C13.4866 11.3326 13.375 11.0536 13.1741 10.8527L9.89286 7.57143L13.1741 4.29018C13.375 4.08929 13.4866 3.81027 13.4866 3.53125C13.4866 3.25223 13.375 2.97321 13.1741 2.77232L11.6562 1.25446C11.4554 1.05357 11.1763 0.941963 10.8973 0.941963C10.6183 0.941963 10.3393 1.05357 10.1384 1.25446L6.85714 4.53571L3.57589 1.25446C3.375 1.05357 3.09598 0.941963 2.81696 0.941963C2.53795 0.941963 2.25893 1.05357 2.05804 1.25446L0.540179 2.77232C0.339286 2.97321 0.227679 3.25223 0.227679 3.53125C0.227679 3.81027 0.339286 4.08929 0.540179 4.29018L3.82143 7.57143L0.540179 10.8527C0.339286 11.0536 0.227679 11.3326 0.227679 11.6116C0.227679 11.8906 0.339286 12.1696 0.540179 12.3705L2.05804 13.8884C2.25893 14.0893 2.53795 14.2009 2.81696 14.2009C3.09598 14.2009 3.375 14.0893 3.57589 13.8884L6.85714 10.6071L10.1384 13.8884C10.3393 14.0893 10.6183 14.2009 10.8973 14.2009C11.1763 14.2009 11.4554 14.0893 11.6562 13.8884L13.1741 12.3705C13.375 12.1696 13.4866 11.8906 13.4866 11.6116Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </nav>
        </header>

        <NewsletterPopup cookies={this.props.cookies} />

        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/about">{/* <About /> */}</Route>
          <Route path="/corporate">
            <Corporate />
          </Route>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route exact path="/blog/:blogId" component={Blogdetails} />
          <Route path="/Portfolio">
            <Portfolio />
          </Route>
          <Route path="/DedicatedOffshoreTeam">
            <DedicatedOffshoreTeam />
          </Route>
          <Route path="/Contribution">
            <Contribution />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/custom-software-development">
            <CustomSoftware />
          </Route>
          <Route path="/cloud-application-development">
            <CloudApplication />
          </Route>
          <Route path="/enterprise-software-development">
            <EnterpriseSoftware />
          </Route>
          <Route path="/mobile-application-development">
            <MobileApplication />
          </Route>
          <Route path="/product-development">
            <Product />
          </Route>
          <Route path="/web-application-evelopment">
            <WebApplication />
          </Route>
          <Route path="/system-integration">
            <SystemIntegration />
          </Route>
          <Route path="/artificial-intelligence">
            <ArtificialIntelligence />
          </Route>
          <Route path="/big-data">
            <Bigdata />
          </Route>
          <Route path="/data-analyticsand-visualization">
            <DataAnalyticsandVisualization />
          </Route>
          <Route path="/internet-of-things">
            <InternetofThings />
          </Route>
          <Route path="/machineLearning">
            <MachineLearning />
          </Route>
          <Route path="/robotic-process-automation">
            <RoboticProcessAutomation />
          </Route>
          <Route path="/cloud-infrastructure">
            <CloudInfrastructure />
          </Route>
          <Route path="/hybrid-infrastructure">
            <HybridInfrastructure />
          </Route>
          <Route path="/on-premises-infrastructure">
            <OnPremisesInfrastructure />
          </Route>
          <Route path="/integration">
            <Integration />
          </Route>
          <Route path="/migration">
            <Migration />
          </Route>
          <Route path="/devOps">
            <DevOps />
          </Route>
          <Route path="/digital-transformation">
            <DigitalTransformation />
          </Route>
          <Route path="/business-technology-consulting">
            <BusinessTechnologyConsulting />
          </Route>
          <Route path="/cloud-strategy-consulting">
            <CloudStrategyConsulting />
          </Route>
          <Route path="/maintenance-and-support">
            <MaintenanceandSupport />
          </Route>
          <Route path="/application-support">
            <ApplicationSupport />
          </Route>
          <Route path="/infrastructure-support">
            <InfrastructureSupport />
          </Route>
          <Route path="/customer-support">
            <CustomerSupport />
          </Route>
          <Route path="/network-security">
            <NetworkSecurity />
          </Route>
          <Route path="/infrastructure-security">
            <InfrastructureSecurity />
          </Route>
          <Route path="/application-security">
            <ApplicationSecurity />
          </Route>
          <Route path="/risks-and-compliance">
            <RisksandCompliance />
          </Route>
          <Route path="/overview">
            <Overview />
          </Route>
          <Route path="/project-based-engagement">
            <ProjectBasedEngagement />
          </Route>
          <Route path="/hire-dedicated-team-Center">
            <HireDedicatedTeam />
          </Route>
          <Route path="/sla-based-approach">
            <SLABasedApproach />
          </Route>
          <Route path="/bi-consulting">
            <BIConsulting />
          </Route>
          <Route path="/bi-implementation">
            <BIImplementation />
          </Route>
          <Route path="/bi-service-delivery-and-framework">
            <BIServiceDeliveryAndFramework />
          </Route>
          <Route path="/bi-big-data">
            <BiBigData />
          </Route>
          <Route path="/strategy-and-planning">
            <StrategyandPlanning />
          </Route>
          <Route path="/search-engine-optimization">
            <SearchEngineOptimization />
          </Route>
          <Route path="/digital-advertising">
            <DigitalAdvertising />
          </Route>
          <Route path="/social-media-marketing">
            <SocialMediaMarketing />
          </Route>
          <Route path="/site-testing">
            <SiteTesting />
          </Route>
          <Route path="/sms-marketing">
            <SMSMarketing />
          </Route>
          <Route path="/lifecycle-marketing">
            <LifecycleMarketing />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/ask-for-a-quote">
            <AskForQuote />
          </Route>
          <Route path="/schedule-a-call">
            <ScheduleCall />
          </Route>
          <Route path="/about-doodle">
            <AboutDoodle />
          </Route>
          <Route path="/responsible-contribution">
            <ResponsibleContribution />
          </Route>
          <Route path="/blogs">
            <CompanyBlog />
          </Route>
          <Route path="/career">
            <CompanyCareer />
          </Route>
          <Route path="/case-studies">
            <CaseStudies />
          </Route>
          <Route path="/technology">
            <Technology />
          </Route>
          <Route path="/approach">
            <Approach />
          </Route>
          <Route path="/engagement">
            <Engagement />
          </Route>
          <Route path="/industries">
            <Industries />
          </Route>
          <Route path="/certifications">
            <Certifications />
          </Route>
          <Route path="/Contact">
            <Contact />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default withCookies(Nav);
