import React, { useEffect } from "react";
import Footer from "./../../Footer";
import AOS from "aos";
function Overview() {
  useEffect(() => {
    document.title = `Overview`;
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  });
  return (
    <div>
      <section
        className="banner"
        style={{
          background: "url(images/services-banner.png) no-repeat bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">
                  Application Security
                </h1>
              </div>
              <p className="text-white">
                Services /{" "}
                <span className="dark-text">Application Security</span>
              </p>
            </div>
          </div>
         
        </div>
      </section>

      <section className="center-section pt-3 pb-3 section-black">
        <div className="container">
          <div className="row pt-3 pb-3 text-center">
            <div className="col-lg-5 ml-auto">
              <div className="award-text">
                <span className="award-type">Award</span>{" "}
                <span className="award-and">&</span> Certifications
              </div>
              
            </div>
            <div className="col-lg-5 mobile-hide-only mr-auto">
              <div className="counter-item">
                <img src="images/circle-img01.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_01">9</span>
                    <span className="c_text_01">YEARS</span>IN OPERATION
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">100s</span>
                    <span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">4.9/5</span>
                    <span className="c_text_01">CUSTOMERS</span>RATING
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*White Section*/}
      <section className="center-section pt-0 section-white" data-aos="fade-up">
        <div className="container">
          <div className="row section-title-top justify-content-center">
            <div className="col-lg-11 text-center">
              <div className="highlight-black text-center mb-3 font-weight-semibold text-size-13">
                WITH US
              </div>
              <h2>
                Now You <span className="highlight-orange">Can!</span>
              </h2>
              <p className="color-9 mb-5">
                At doodle, we believe height comes from freedom, so we let our
                clients be our partners and enable them with our top-notch
                dedicated resources which we name as ‘offshore dedicated team’.
                This way, we can transform our limitations into strengths
                together while spurring to go beyond. So you can ...
              </p>
            </div>
          </div>
          <div className="row pb-5 justify-content-center">
            <div className="col text-center">
              <span className="box-block-count">01.</span>
              <p className="mt-3 font-size-18 color-4">
                Build custom solution
                <br />
                perfectly fit to your requirement!
              </p>
            </div>
            <div className="col text-center">
              <span className="box-block-count">02.</span>
              <p className="mt-3 font-size-18 color-4">
                Outline your project
                <br /> aligned to your budget and timeframe!
              </p>
            </div>
            <div className="col text-center">
              <span className="box-block-count">03.</span>
              <p className="mt-3 font-size-18 color-4">
                Make any change
                <br />
                anytime as your project requires
              </p>
            </div>
          </div>
          <div className="row mt-5 ml-0 mr-0 inline-call-toaction">
            <div className="col-lg-9 col-xl-10 text-left">
              <h3 className="font-weight-semibold">
                Our offshore dedicated team surely can be a lifeline to your
                growth!
              </h3>
              <p className="mt-2 mb-0 color-6">
                We build and let you hire a fully scalable team of dedicated
                developers, designers and other experts who will work
                exclusively on your projects according to your needs just like
                any in-house team but without any hiring hustles.
              </p>
            </div>
            <div className="col-lg-3 col-xl-2 text-right">
              <a
                href="#"
                className="btn btn-primary mt-4 btn-lg"
                role="button"
                aria-pressed="true"
              >
                <span>Know How</span>
              </a>
            </div>
          </div>
        </div>
        <span className="effect-bg-top-left-round">
          <svg
            width={31}
            height={31}
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.4906 2.8739C21.5695 0.79387 18.0176 -0.208957 14.44 0.036291C10.8623 0.281539 7.48044 1.75969 4.8705 4.21888C2.26055 6.67807 0.584038 9.96615 0.126617 13.5229C-0.330805 17.0796 0.459166 20.6849 2.36193 23.7244C4.26469 26.764 7.16252 29.0498 10.5617 30.1923C13.9608 31.3348 17.6509 31.2633 21.0033 29.9901C24.3556 28.7169 27.1628 26.3207 28.9465 23.2098C30.7302 20.0989 31.3801 16.4657 30.7853 12.9293L27.1298 13.5441C27.5823 16.2347 27.0879 18.999 25.7308 21.366C24.3736 23.7329 22.2378 25.556 19.6872 26.5248C17.1365 27.4935 14.3289 27.5478 11.7427 26.6786C9.15645 25.8093 6.95165 24.0702 5.50394 21.7575C4.05623 19.4449 3.45518 16.7018 3.80321 13.9957C4.15124 11.2896 5.42681 8.78786 7.41258 6.9168C9.39835 5.04573 11.9715 3.92108 14.6935 3.73449C17.4155 3.54789 20.118 4.31089 22.3405 5.89347L24.4906 2.8739Z"
              fill="#FF8135"
              fillOpacity="0.2"
            />
          </svg>
        </span>
      </section>
      {/*White Section*/}
      <section className="center-section pt-0 section-white">
        <div className="container">
          <div className="row">
            <div className="col-6 pr-lg-5" data-aos="fade-right">
              <h2 className="text-left mb-2">
                With Our <br />
                <span className="highlight-orange">
                  Dedicated Offshore
                </span>{" "}
                Team,
                <br />
                You Get Complete Authority
              </h2>
              <p className="text-left color-a">
                Select a dedicated team entirely under your control specifically
                for your project. Change or customize any features anytime and
                manage your work according to the way you want.
              </p>
              <a
                href="#"
                className="btn btn-primary mt-0-d mt-3 btn-lg"
                role="button"
                aria-pressed="true"
              >
                <span>Learn More</span>
              </a>
            </div>
            <div className="col-6 pl-lg-5 mt-2" data-aos="fade-left">
              <div className="row">
                <div className="col-6 border-right border-bottom">
                  <div className="box-item text-center pt-0">
                    <img src="images/watch.png" alt="" />
                    <p className="color-4 font-weight-semibold">
                      Manage the work schedule of your team
                    </p>
                  </div>
                </div>
                <div className="col-6 border-bottom">
                  <div className="box-item text-center pt-0">
                    <img src="images/settings.png" alt="" />
                    <p className="color-4 font-weight-semibold">
                      Keep your team as long as you need
                    </p>
                  </div>
                </div>
                <div className="col-6 border-right">
                  <div className="box-item text-center pb-0">
                    <img src="images/settings.png" alt="" />
                    <p className="color-4 font-weight-semibold">
                      Monitor daily work progress of each person
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="box-item text-center pb-0">
                    <img src="images/watch.png" alt="" />
                    <p className="color-4 font-weight-semibold">
                      Make any changes anytime as required
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Black Section*/}
      <section className="center-section section-black">
        <div className="container">
          <div
            className="row section-title-top justify-content-center"
            data-aos="fade-down"
          >
            <div className="col-lg-10">
              <h2 className="text-center text-white">
                How Does <span className="highlight-orange">it Work?</span>
              </h2>
              <p className="text-center color-a">
                With Doodle, recruiting offshore teams is more flexible. With
                our years of experience, we've developed a process that will
                make every step of your development journey, from hiring to
                getting the job done, a lot easier, faster, transparent and
                efficient.
              </p>
            </div>
          </div>
          <div className="row icon-with-tab" data-aos="fade-up">
            <ul className="nav col-12 p-0 nav-tabs" id="myTab" role="tablist">
              <li className="col-md p-0 nav-item">
                <a
                  className="nav-link text-center active"
                  id="Discovery-tab"
                  data-toggle="tab"
                  href="#Discovery"
                  role="tab"
                  aria-controls="Discovery"
                  aria-selected="true"
                >
                  <svg
                    width={81}
                    height={65}
                    viewBox="0 0 81 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M76.0435 40.8615H71.3105V38.1795C71.3105 36.87 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.87 47.6455 38.1795V57.9003C47.6455 61.8129 50.8324 64.9998 54.745 64.9998H64.211C67.9816 64.9998 71.0739 62.0338 71.2947 58.3105C76.6115 57.7583 80.7765 53.262 80.7765 47.8033V45.5945C80.7765 42.9756 78.6467 40.8615 76.0435 40.8615ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0253 71.3105 53.5302V45.5945H76.0435V47.8033Z"
                      fill="#FF8235"
                    />
                    <path
                      d="M31.9477 4.733C33.2571 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2571 0 31.9477 0C30.6382 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6382 4.733 31.9477 4.733Z"
                      fill="white"
                    />
                    <path
                      d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z"
                      fill="white"
                    />
                    <path
                      d="M42.9125 40.5464H0V44.9639C0 46.2733 1.05704 47.3304 2.3665 47.3304H42.9125V40.5464Z"
                      fill="white"
                    />
                    <path
                      d="M42.9124 52.063H20.9829V55.6916H16.0921C14.7826 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7826 60.4246 16.0921 60.4246H43.1806C43.0071 59.6042 42.9124 58.7681 42.9124 57.9004V52.063Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white">
                    Discovery &amp;
                    <br /> Project Understandin
                  </p>
                </a>
              </li>
              <li className="col-md p-0 nav-item">
                <a
                  className="nav-link text-center"
                  id="Discovery-tab"
                  data-toggle="tab"
                  href="#Discovery"
                  role="tab"
                  aria-controls="Discovery"
                  aria-selected="true"
                >
                  <svg
                    width={81}
                    height={65}
                    viewBox="0 0 81 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M76.0435 40.8615H71.3105V38.1795C71.3105 36.87 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.87 47.6455 38.1795V57.9003C47.6455 61.8129 50.8324 64.9998 54.745 64.9998H64.211C67.9816 64.9998 71.0739 62.0338 71.2947 58.3105C76.6115 57.7583 80.7765 53.262 80.7765 47.8033V45.5945C80.7765 42.9756 78.6467 40.8615 76.0435 40.8615ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0253 71.3105 53.5302V45.5945H76.0435V47.8033Z"
                      fill="#FF8235"
                    />
                    <path
                      d="M31.9477 4.733C33.2571 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2571 0 31.9477 0C30.6382 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6382 4.733 31.9477 4.733Z"
                      fill="white"
                    />
                    <path
                      d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z"
                      fill="white"
                    />
                    <path
                      d="M42.9125 40.5464H0V44.9639C0 46.2733 1.05704 47.3304 2.3665 47.3304H42.9125V40.5464Z"
                      fill="white"
                    />
                    <path
                      d="M42.9124 52.063H20.9829V55.6916H16.0921C14.7826 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7826 60.4246 16.0921 60.4246H43.1806C43.0071 59.6042 42.9124 58.7681 42.9124 57.9004V52.063Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white">
                    Resource
                    <br /> unification &amp; quotation
                  </p>
                </a>
              </li>
              <li className="col-md p-0 nav-item">
                <a
                  className="nav-link text-center"
                  id="Discovery-tab"
                  data-toggle="tab"
                  href="#Discovery"
                  role="tab"
                  aria-controls="Discovery"
                  aria-selected="true"
                >
                  <svg
                    width={81}
                    height={65}
                    viewBox="0 0 81 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M76.0435 40.8615H71.3105V38.1795C71.3105 36.87 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.87 47.6455 38.1795V57.9003C47.6455 61.8129 50.8324 64.9998 54.745 64.9998H64.211C67.9816 64.9998 71.0739 62.0338 71.2947 58.3105C76.6115 57.7583 80.7765 53.262 80.7765 47.8033V45.5945C80.7765 42.9756 78.6467 40.8615 76.0435 40.8615ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0253 71.3105 53.5302V45.5945H76.0435V47.8033Z"
                      fill="#FF8235"
                    />
                    <path
                      d="M31.9477 4.733C33.2571 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2571 0 31.9477 0C30.6382 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6382 4.733 31.9477 4.733Z"
                      fill="white"
                    />
                    <path
                      d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z"
                      fill="white"
                    />
                    <path
                      d="M42.9125 40.5464H0V44.9639C0 46.2733 1.05704 47.3304 2.3665 47.3304H42.9125V40.5464Z"
                      fill="white"
                    />
                    <path
                      d="M42.9124 52.063H20.9829V55.6916H16.0921C14.7826 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7826 60.4246 16.0921 60.4246H43.1806C43.0071 59.6042 42.9124 58.7681 42.9124 57.9004V52.063Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white">
                    Setup &amp;
                    <br /> meet your team
                  </p>
                </a>
              </li>
              <li className="col-md p-0 nav-item">
                <a
                  className="nav-link text-center"
                  id="Discovery-tab"
                  data-toggle="tab"
                  href="#Discovery"
                  role="tab"
                  aria-controls="Discovery"
                  aria-selected="true"
                >
                  <svg
                    width={81}
                    height={65}
                    viewBox="0 0 81 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M76.0435 40.8615H71.3105V38.1795C71.3105 36.87 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.87 47.6455 38.1795V57.9003C47.6455 61.8129 50.8324 64.9998 54.745 64.9998H64.211C67.9816 64.9998 71.0739 62.0338 71.2947 58.3105C76.6115 57.7583 80.7765 53.262 80.7765 47.8033V45.5945C80.7765 42.9756 78.6467 40.8615 76.0435 40.8615ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0253 71.3105 53.5302V45.5945H76.0435V47.8033Z"
                      fill="#FF8235"
                    />
                    <path
                      d="M31.9477 4.733C33.2571 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2571 0 31.9477 0C30.6382 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6382 4.733 31.9477 4.733Z"
                      fill="white"
                    />
                    <path
                      d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z"
                      fill="white"
                    />
                    <path
                      d="M42.9125 40.5464H0V44.9639C0 46.2733 1.05704 47.3304 2.3665 47.3304H42.9125V40.5464Z"
                      fill="white"
                    />
                    <path
                      d="M42.9124 52.063H20.9829V55.6916H16.0921C14.7826 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7826 60.4246 16.0921 60.4246H43.1806C43.0071 59.6042 42.9124 58.7681 42.9124 57.9004V52.063Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white">
                    Execution &amp;
                    <br /> timeless monitoring
                  </p>
                </a>
              </li>
              <li className="col-md p-0 nav-item">
                <a
                  className="nav-link text-center"
                  id="Discovery-tab"
                  data-toggle="tab"
                  href="#Discovery"
                  role="tab"
                  aria-controls="Discovery"
                  aria-selected="true"
                >
                  <svg
                    width={81}
                    height={65}
                    viewBox="0 0 81 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M76.0435 40.8615H71.3105V38.1795C71.3105 36.87 70.2535 35.813 68.944 35.813H50.012C48.7025 35.813 47.6455 36.87 47.6455 38.1795V57.9003C47.6455 61.8129 50.8324 64.9998 54.745 64.9998H64.211C67.9816 64.9998 71.0739 62.0338 71.2947 58.3105C76.6115 57.7583 80.7765 53.262 80.7765 47.8033V45.5945C80.7765 42.9756 78.6467 40.8615 76.0435 40.8615ZM76.0435 47.8033C76.0435 50.6431 74.0083 53.0253 71.3105 53.5302V45.5945H76.0435V47.8033Z"
                      fill="#FF8235"
                    />
                    <path
                      d="M31.9477 4.733C33.2571 4.733 34.3142 3.67596 34.3142 2.3665C34.3142 1.05704 33.2571 0 31.9477 0C30.6382 0 29.5812 1.05704 29.5812 2.3665C29.5812 3.67596 30.6382 4.733 31.9477 4.733Z"
                      fill="white"
                    />
                    <path
                      d="M63.8955 31.08V2.3665C63.8955 1.05704 62.8384 0 61.529 0H38.637C38.9052 0.741503 39.0472 1.53034 39.0472 2.3665C39.0472 6.27911 35.8604 9.466 31.9477 9.466C28.0351 9.466 24.8482 6.27911 24.8482 2.3665C24.8482 1.53034 24.9902 0.741503 25.2584 0H2.3665C1.05704 0 0 1.05704 0 2.3665V35.813H43.3227C44.3009 33.0521 46.9356 31.08 50.012 31.08H63.8955Z"
                      fill="white"
                    />
                    <path
                      d="M42.9125 40.5464H0V44.9639C0 46.2733 1.05704 47.3304 2.3665 47.3304H42.9125V40.5464Z"
                      fill="white"
                    />
                    <path
                      d="M42.9124 52.063H20.9829V55.6916H16.0921C14.7826 55.6916 13.7256 56.7487 13.7256 58.0581C13.7256 59.3676 14.7826 60.4246 16.0921 60.4246H43.1806C43.0071 59.6042 42.9124 58.7681 42.9124 57.9004V52.063Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white">
                    Delivery &amp;
                    <br /> continual maintenance
                  </p>
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent1">
              <div
                className="tab-pane fade show text-center color-d active"
                id="Discovery"
                role="tabpanel"
                aria-labelledby="Discovery-tab"
              >
                Understanding your vision means a lot to us. So, we start with
                getting the sense of the project, its dynamics as well as
                technological requirements s and most importantly your demands
                and expectations to perfectly outline the structure,
                specifications and probable duration of your project. We start
                with getting the sense of the project, its dynamics as well as
                technological requirement
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="center-section section-white  box-shadow">
        <div className="container">
          <div
            className="row section-title-top justify-content-center"
            data-aos="fade-down"
          >
            <div className="col-lg-12 text-center">
              <h2>
                <span className="highlight-orange">Tools & Technologies</span>{" "}
                We Use
              </h2>
              <p className="color-7">
                Doodle Inc. has earned global recognition in software
                development.
                <br />
                With a variety of modern technologies and approaches, we are
                serving our clients with dependable solutions.
              </p>
            </div>
          </div>
          <div className="row mt-5 tools-sec">
            <div className="col-lg-6 col-sm-6 tools-2nd border-right-2 border-bottom-2 tools-color-02 border-radius-lt">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Languages</p>
              </div>
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">Java</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">Python</span>
                <span className="tools-btn-tag">Groovy</span>
                <span className="tools-btn-tag">Swift</span>
                <span className="tools-btn-tag">Kotlin</span>
                <span className="tools-btn-tag">PHP</span>
                <span className="tools-btn-tag">Rust</span>
                <span className="tools-btn-tag">Scala</span>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 tools-2nd tools-color-01 border-bottom-2 border-radius-rt">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Frameworks</p>
              </div>
              {/* JDBC / JPA JMS LARAVEL, Codeigniter,  Django, Apache Camel Express */}

              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">JDBC / JPA</span>
                <span className="tools-btn-tag">JMS</span>
                <span className="tools-btn-tag">LARAVEL</span>
                <span className="tools-btn-tag">Codeigniter</span>
                <span className="tools-btn-tag">Django</span>
                <span className="tools-btn-tag">Apache Camel</span>
                <span className="tools-btn-tag">Express</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 border-bottom-2 tools-color-01 border-radius-lb m-border-radius-lb">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Mobile</p>
              </div>
              {/* iOS Android Flutter HTML5 React Native  Firebase,  */}
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">iOS</span>
                <span className="tools-btn-tag">Android</span>
                <span className="tools-btn-tag">Flutter</span>
                {/* <span className="tools-btn-tag">HTML5</span> */}
                <span className="tools-btn-tag">React Native</span>
                <span className="tools-btn-tag">Firebase</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 m-border-right-2 tools-color-02 border-bottom-2">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Web</p>
              </div>
              {/* Vue Sass LESS CoffeeScript, Angular React WebGL, HTML5, CSS3, Bootstrap, Foundation */}
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">Vue</span>
                <span className="tools-btn-tag">Sass</span>
                <span className="tools-btn-tag">LESS</span>
                <span className="tools-btn-tag">CoffeeScript</span>
                <span className="tools-btn-tag">Angular</span>
                <span className="tools-btn-tag">React</span>
                <span className="tools-btn-tag">WebGL</span>
                <span className="tools-btn-tag">HTML5</span>
                <span className="tools-btn-tag">CSS3</span>
                <span className="tools-btn-tag">Bootstrap</span>
                <span className="tools-btn-tag">Foundation</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 tools-color-03 border-bottom-2 m-show-border-radius-lb">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" alt="" />
                <p>Database Management</p>
              </div>
              {/* NoSQL MySQL Oracle SQL MariaDB, Amazon RDS Microsoft SQL */}
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">NoSQL</span>
                <span className="tools-btn-tag">MySQL</span>
                <span className="tools-btn-tag">Oracle SQL</span>
                <span className="tools-btn-tag">MariaDB</span>
                <span className="tools-btn-tag">Amazon</span>
                <span className="tools-btn-tag">RDS</span>
                <span className="tools-btn-tag">Microsoft SQL</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd tools-color-04 border-bottom-2 border-radius-rb">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Cloud</p>
              </div>
              {/* Amazon Web Services (AWS) Google Cloud Oracle Cloud IBM Cloud Microsoft Azure */}
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">Amazon Web Services (AWS)</span>
                <span className="tools-btn-tag">Google Cloud</span>
                <span className="tools-btn-tag">Oracle Cloud</span>
                <span className="tools-btn-tag">IBM Cloud</span>
                <span className="tools-btn-tag">Microsoft Azure</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="center-section section-black pt-0 pb-0 box-shadow"
        style={{ background: "url(images/contact-bg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 bg-black ex-pt">
              <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
                <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                  <div className="award-text">
                    <span className="award-type">Award</span>{" "}
                    <span className="award-and">&</span> Certifications
                  </div>
                  
                </div>
                <div className="col-lg-12 mr-auto">
                  <div className="counter-item">
                    <img src="images/circle-img01.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_01">9</span>
                        <span className="c_text_01">YEARS</span>IN OPERATION
                      </p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_02">100s</span>
                        <span className="c_text_01">OF CUSTOMERS</span>
                        WORLDWIDE
                      </p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p>
                        <span className="c_number_02">4.9/5</span>
                        <span className="c_text_01">CUSTOMERS</span>RATING
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 bg-white ex-pt">
              <div
                className="row p-5 pl-lg-5 section-title-top"
                data-aos="fade-down"
              >
                <div className="col-lg-12 pl-lg-5 text-left">
                  <h2>
                    Tell Us About{" "}
                    <span className="highlight-orange">Your Project</span>
                  </h2>
                </div>
                <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                  <form className="row">
                    <div className="form-group  mb-4 col-lg-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="John Smith"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>E-mail</label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="example@provider.com"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Phone</label>
                      <input
                        type="phone"
                        className="form-control form-control-lg"
                        placeholder="+1 112 222 333"
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Ex: Pexel Inc."
                      />
                    </div>
                    <div className="form-group mb-4 col-lg-12">
                      <label>Messages...</label>
                      <textarea
                        className="form-control"
                        placeholder="Hello..."
                      ></textarea>
                    </div>
                    <div className="form-group col-lg-12">
                      <button type="submit" className="btn btn-primary btn-lg">
                        <span>Send Now</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="center-section section-white box-shadow">
        <div className="container">
          <div className="row section-title-top" data-aos="fade-down">
            <div className="col-lg-5 text-left">
              <h2>F.A.Q.</h2>
              <p className="color-7 pr-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </div>
            <div className="col-lg-7 faq-cls">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne1"
                        aria-expanded="false"
                        aria-controls="collapseOne1"
                      >
                        Lorem Ipsum is simply dummy text of the?
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne1"
                    className="collapse"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo1"
                        aria-expanded="false"
                        aria-controls="collapseTwo1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo1"
                    className="collapse"
                    aria-labelledby="headingTwo1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree1"
                        aria-expanded="false"
                        aria-controls="collapseThree1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree1"
                    className="collapse"
                    aria-labelledby="headingThree1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFive1">
                    <h2 className="mb-0">
                      <button
                        className="btn pl-0 pr-0 btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive1"
                        aria-expanded="false"
                        aria-controls="collapseFive1"
                      >
                        Lorem Ipsum is simply dummy text of the?{" "}
                        <i className="fas fa-angle-down"></i>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive1"
                    className="collapse"
                    aria-labelledby="headingFive1"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="center-section section-white box-shadow">
        <div className="container">
          <div className="row section-title-top" data-aos="fade-down">
            <div className="col-lg-12 text-center">
              <h2>Related Blog Articles</h2>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img1.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img2.png" alt="" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">
                  Lorem Ipsum is simply dummy text
                </h3>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/*----------Footer---------*/}
      <Footer />
    </div>
  );
}
export default Overview;
