import React from 'react';
 function ToolsAndTechnologies() {
    return (
        <section className="center-section section-white  box-shadow">
        <div className="container">
          <div
            className="row section-title-top justify-content-center"
            data-aos="fade-down"
          >
            <div className="col-lg-12 text-center">
              <h2>
                <span className="highlight-orange">Tools & Technologies</span>{" "}
                We Use
              </h2>
              <p className="color-7">
                Doodle Inc. has earned global recognition in software
                development. With a variety of modern technologies and
                approaches, we are serving our clients with dependable
                solutions.
              </p>
            </div>
          </div>
          <div className="row mt-5 tools-sec">
            <div className="col-lg-6 col-sm-6 tools-2nd border-right-2 border-bottom-2 tools-color-02 border-radius-lt">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Languages</p>
              </div>
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">NodeJs</span>
                <span className="tools-btn-tag">Java</span>
                <span className="tools-btn-tag">PHP</span>
                <span className="tools-btn-tag">Python</span>
                <span className="tools-btn-tag">Swift</span>
                <span className="tools-btn-tag">Kotlin</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">C#</span>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 tools-2nd tools-color-01 border-bottom-2 border-radius-rt">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Frameworks</p>
              </div>
              {/* JDBC / JPA JMS LARAVEL, Codeigniter,  Django, Apache Camel Express */}

              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">ExpressJS</span>
                <span className="tools-btn-tag">LARAVEL</span>
                <span className="tools-btn-tag">Codeigniter</span>
                <span className="tools-btn-tag">Django</span>
                <span className="tools-btn-tag">Xamarin</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 border-bottom-2 tools-color-01 border-radius-lb m-border-radius-lb">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Mobile</p>
              </div>
              {/* iOS Android Flutter HTML5 React Native  Firebase,  */}
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">React Native</span>
                <span className="tools-btn-tag">Java</span>
                <span className="tools-btn-tag">Swift </span>
                <span className="tools-btn-tag">Kotlin</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">Ionic</span>
                <span className="tools-btn-tag">Apache Cordova</span>
                <span className="tools-btn-tag">Flutter</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 m-border-right-2 tools-color-02 border-bottom-2">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Web</p>
              </div>
              {/* Vue Sass LESS CoffeeScript, Angular React WebGL, HTML5, CSS3, Bootstrap, Foundation */}
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">ReactJS</span>
                <span className="tools-btn-tag">HTML5</span>
                <span className="tools-btn-tag">CSS3</span>
                <span className="tools-btn-tag">Bootstrap</span>
                <span className="tools-btn-tag">Vue</span>
                <span className="tools-btn-tag">Angular</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 tools-color-03 border-bottom-2 m-show-border-radius-lb">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" alt="" />
                <p>Database Management</p>
              </div>
              {/* NoSQL MySQL Oracle SQL MariaDB, Amazon RDS Microsoft SQL */}
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">MongoDB</span>
                <span className="tools-btn-tag">DynamoDB</span>
                <span className="tools-btn-tag">Aurora</span>
                <span className="tools-btn-tag">MySQL</span>
                <span className="tools-btn-tag">MariaDB</span>
                <span className="tools-btn-tag">PostgreSQL</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd tools-color-04 border-bottom-2 border-radius-rb">
              <div className="tools-title">
                <img src="/images/small-watch.png" alt="" />
                <p>Cloud</p>
              </div>
              {/* Amazon Web Services (AWS) Google Cloud Oracle Cloud IBM Cloud Microsoft Azure */}
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">Amazon Web Services (AWS)</span>
                <span className="tools-btn-tag">Google Cloud</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
export default ToolsAndTechnologies;
