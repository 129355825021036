import qs from 'qs';
import axios from 'axios';
import {ajaxConfig, objectToQueryString} from './../components/utils'
import { getAdsType, saveSubscriberInfoType } from "../actionTypes";


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['crossDomain'] = true;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const HomeData = (homeData)=>({
    type: 'HOMEPAGE',
    homeData
});

export const CatData = (catData)=>({
    type: 'CATDATA',
    catData
});

export const BlogData = (blogData)=>({
    type: 'BlOGDATA',
    blogData
});
export const BlogMetaTag = (blogData)=>({
    type: 'BlOGMETATAG',
    blogData
});
export const BlogDetails = (blogData)=>({
    type: 'BlOGDETAILS',
    blogData
});
export const BlogCategory = (blogData)=>({
    type: 'BlOGCATEGORY',
    blogData
});

export const TotalCatData = (sumCat)=>({
    type: 'TOTALCATDATA',
    sumCat
});

export const GetCat = (parms) =>{
    var params = {}
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/blog/category?offset='+parms.offset+'&limit='+parms.limit,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                dispatch(CatData(res))
            }
            console.log('Wesg',res);
             return res;
        })
    }

}

export const GetTag = (parms) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/blog/tags?offset='+parms.offset+'&limit='+parms.limit,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                dispatch(CatData(res))
            }
             return res;
        })
    }

}

export const TotalCat = () =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/blog/category/totalcat',ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                dispatch(TotalCatData(res))
            }
             return res;
        })
    }

}

export const GetBlogList = (parms) =>{
    var params = {}
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/blog-list?offset='+parms.offset+'&limit='+parms.limit,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                dispatch(BlogData(res))
            }
             return res;
        })
    }
    
}
export const GetTotalBlog = (parms) =>{
    var params = {}
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/totalblogcount',ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
            }
             return res;
        })
    }

}
export const GetTotalBlogByCat = (param) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/totalblogbycatcount?cat_id='+param.category_id,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
            }
             return res;
        })
    }

}
export const GetTotalBlogByTag = (param) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/totalblogbytag?tag_id='+param.tag_id,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
            }
             return res;
        })
    }

}
export const GetTotalBlogBySearch = (params) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/totalbogbysearchcount?searchkey='+params.searchkey,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
            }
             return res;
        })
    }

}

export const GetBlogDetailBy = (blogID) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/blog-details?blogId='+blogID,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                dispatch(BlogDetails(res))
            }
             return res;
        })
    }

}
export const GetPrevBlog = (param) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/preblog?blogid='+param.blog_id+'&cat_id='+param.cat_id,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
            }
             return res;
        })
    }

}
export const GetNextBlog = (param) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/totalblogbytag/nextblog?blogid='+param.blog_id+'&cat_id='+param.cat_id,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
            }
             return res;
        })
    }

}

export const GetBlogCategory = (blogID) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/blog/blog-details-category?blogId='+blogID,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                dispatch(BlogCategory(res))
            }
             return res;
        })
    }

}

export const GetBlogMetaTag = (blogID) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/blog-details-tag?blogId='+blogID,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                dispatch(BlogMetaTag(res))
            }
             return res;
        })
    }

}

export const GetBlogsByTag = (parms) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/filterbytag?offset='+parms.offset+'&limit='+parms.limit+'&tagid='+parms.tag_id,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                //dispatch(BlogData(res))
            }
             return res;
        })
    }

}

export const GetBlogsByTextSearch = (parms) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/searchblog?offset='+parms.offset+'&limit='+parms.limit+'&searchkey='+parms.searchkey,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                //dispatch(BlogData(res))
            }
             return res;
        })
    }

}

export const GetBlogsByCategory = (parms) =>{
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/filterbycategory?offset='+parms.offset+'&limit='+parms.limit+'&catid='+parms.category_id,ajaxConfig).then(response => response.json())
        .then(res => {
            if(res){
                //dispatch(BlogData(res))
            }
             return res;
        })
    }

}

export const GetHomeEn = () =>{
    var params = {}
    return (dispatch) => {
        return fetch ('https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/totaltag',ajaxConfig)
          .then(response => response.json())
          .then(res => {
            if(res){
              dispatch(HomeData(res))
            }
            return res;
          })
        ;
    }

}

export const GetHomeBn = () =>{
    var params = {}
    return (dispatch) => {
        return axios.get('http://127.0.0.1:8040/sites/doodle/blog/api_blog_list',ajaxConfig).then(res =>{
            // if(res.data) {
            //     dispatch(Watch_GetHomeBn(res.data));
            // }
            if(res.error) {
                throw(res.error);
            }
            return res
        })
    }

}

export const saveSubscriberInfoAction = data => {
    
    const query = objectToQueryString(data);
    
    const endPint = `https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/subscriberstore?${query}`;
    
    // return dispatch => axios.get(endPint).then(res => dispatch(saveSubscriberInfoType(res.data)));
    return dispatch => fetch(endPint).then(res => dispatch(saveSubscriberInfoType(res.data)));
}
