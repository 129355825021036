import React, { Component } from 'react';
import { validateEmail } from "../utils";
import classes from './Newsletter.module.scss';
import { connect } from "react-redux";
import { saveSubscriberInfoAction } from "../../actions/homeAction";

class Newsletter extends Component {
  
  state = {
    email: '',
    name: '',
    errorMessage: '',
    saved: false
  };
  
  saveSubscriberInfo = () => {
    
    const { name, email, errorMessage } = this.state;
    
    const emailValid = validateEmail(email);
    const nameValid = (name.length);
  
    let errorMsg = '';
    
    if (!nameValid) {
      errorMsg = 'Name field is required';
    }
  
    
    if (!email.length) {
      errorMsg = 'Email field is required';
      
    } else if (!emailValid) {
      
      errorMsg = 'Invalid email address';
    }
    
    this.setState({
      errorMessage: errorMsg
    });
  
    if (errorMsg.length <= 0) {
    
      const data = {name, email, profession: 0};
    
      this.props.saveSubscribersData(data)
        .then(data => this.setState({
          name: '',
          email: '',
          errorMessage: '',
          saved: true
        }))
      // .catch(err => saved = false)
      ;
    
    }
    
  };
  
  emailChangeHandler = ({target}) => {

    this.setState({
      email: target.value
    })
  };

  nameChangeHandler = ({target}) => {
    this.setState({
      name: target.value
    })
  };
  
  
  render() {
    
    const { name, email, errorMessage, saved } = this.state;
    
  
    return (
      <div className="input-group col-lg-6 col-12 ml-auto mr-auto mb-5">
        <div className="d-flex m-auto">
          <input
            type="email"
            className="form-control border-right"
            placeholder="E-mail..."
            aria-label="E-mail..."
            aria-describedby="button-addon2"
            onChange={this.emailChangeHandler}
            value={email}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Your name"
            aria-label="Your name"
            aria-describedby="button-addon2"
            onChange={this.nameChangeHandler}
            value={name}
          />
  
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="submit"
              id="button-addon2"
              onClick={this.saveSubscriberInfo}
            >
              <i className="far fa-paper-plane" />
            </button>
          </div>
        </div>
        <div>
          <span className={classes.errorMessage}>{errorMessage}</span>
          {
            saved && <span className={classes.successMessage}>Your subscription has been saved</span>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveSubscribersData: data => dispatch(saveSubscriberInfoAction(data))
});

export default connect('', mapDispatchToProps)(Newsletter);
