import React, { useEffect } from "react";
import Footer from "../../Footer";
import AOS from "aos";
import ToolsAndTechnologies from "./../../common/ToolsAndTechnologies";
import TellUsAbout from "../../common/TellUsAbout";
import FAQ from "../../common/FAQ";
import RelatedBlogArticles from "../../common/RelatedBlogArticles";



function BiBigData() {
  useEffect(() => {
    document.title = `Big Data`;
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  });
  return (
    <div>
      <section
        className="banner"
        style={{
          background: "url(images/services-banner.png) no-repeat bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">
                  Big Data
                </h1>
              </div>
              <p className="text-white">
                Services / <span className="dark-text">Big Data</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="center-section pt-3 pb-3 section-black">
        <div className="container">
          <div className="row pt-3 pb-3 text-center">
            <div className="col-lg-5 ml-auto">
              <div className="award-text">
                <span className="award-type">Award</span>{" "}
                <span className="award-and">&</span> Certifications
              </div>
              <div className="iso-icon">
                <img src="images/ISO_icon01.png" alt="..." />
              </div>
              <div className="iso-icon">
                <img src="images/ISO_icon02.png" alt="..." />
              </div>
            </div>
            <div className="col-lg-5 mobile-hide-only mr-auto">
              <div className="counter-item">
                <img src="images/circle-img01.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_01">9</span>
                    <span className="c_text_01">YEARS</span>IN OPERATION
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">100s</span>
                    <span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE
                  </p>
                </div>
              </div>
              <div className="counter-item">
                <img src="images/circle-img02.png" alt="..." />
                <div className="cn_01">
                  <p>
                    <span className="c_number_02">4.9/5</span>
                    <span className="c_text_01">CUSTOMERS</span>RATING
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     

      <section className="section-drak-gray pt-5 pb-5">
      <div className="container">
        <div
          className="row section-title-top mb-5 pt-5 justify-content-center"
          data-aos="fade-down"
        >
          <div className="col-lg-12 text-center">
            <h2>
              <span className="highlight-orange">
                Big Data
              </span>{" "}
              Services
            </h2>
            <p className="color-7">
            Turn existing complex data into business insight & gain a competitive edge. Doodle can be your statistical analysis provider. We leverage our AI-driven advanced analytics tools which integrate with existing data infrastructure. Our custom Big Data software will function in an agile and non-disruptive way. Our data scientists can also help you to set your big data strategy. 
            </p>
          </div>
        </div>
        <div className="row ml-0 mr-0 services-boxs mb-5">
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Big Data Strategy Consulting</span>Doodle helps businesses to set their big data strategies & to choose advanced tools and technologies to attain them. We will provide a detailed roadmap, enterprise data architecture, possible results, and even proof of concept. Our AI-driven analytics tools and solutions are tailored as per the client’s requirements. Our data scientists & analysts with industry-specific knowledge will provide suitable solutions for your business. From strategy definition to implementation, we got you all covered. 
            </p>
            <span className="single-top-border"></span>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Big Data Implementation</span>Doodle implements big data solutions powered by machine learning technology. Our solutions vary from business to business. We will analyze your business & our data scientists will come up with a solution that your business requires. Our solution will suit your data infrastructure & ingrate with your existing systems. Our solutions will dive deep into knowledge discovery & BI turning complex data into information. 
            </p>
            <span className="single-top-border"></span>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Real-Time Predictions</span>Obtain real-time data for prompt decision making. Doodle helps you to make real-time predictions from business insights. We value the need for quick responses in your business processes. Our solutions thus entirely reflect your business requirements. From ride sharing solutions to stock market analysis or real-time banking services, our solutions will cover your needs. 
            </p>
            <span className="single-top-border"></span>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Big Data Maintenance & Support</span>Doodle Inc. is your one-stop solution for all of your big data solutions. We can integrate our solution with your existing systems, migrate data, and monitor your solution. We have proven technologies, frameworks &  methods to provide quality maintenance & support services. We also ensure that your data is stored securely. 
            </p>
            <span className="single-top-border"></span>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Big Data Analytics & Insight Services</span>Make business decisions better with precise big data analytics & insights. Doodle provides analytics & insights which have business value. Our AI-driven big data solutions with advanced analytics tools provide analysis while adapting to the changing business needs. Our analysis and insights are based on KPI and provide maximum ROI. 
            </p>
            <span className="single-top-border"></span>
          </div>
          <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
            <img
              className="serviceimg-normal"
              src="/images/services-test-img.png"
              alt=""
            />
            <img
              className="serviceimg-hover"
              src="/images/services-test-img-hover.png"
              alt=""
            />
            <p className="font-roboto">
              <span>Big Data Security</span>Guard both data & analytics against malicious activities.  We understand the significance of data security. Big data security issues are sensitive and attacks can happen at any minute. We will cover the security aspects of all the components of big data like sorted data or the data source. No matter what is the origin of the attack either online or offline, we will keep your data secure.
            </p>
            <span className="single-top-border"></span>
          </div>
        </div>
      </div>
  </section>








      <section className="section-white pt-5 pb-5">
        <div className="container">
          <div className="row section-title-top mb-5 pt-5" data-aos="fade-down">
            <div className="col-lg-5 text-left">
              <h2>
                Emerging <span className="highlight-orange">Technologies</span>{" "}
                <br />
                for Big Data
              </h2>
              <p className="color-7">
                Doodle Inc. has earned global recognition in custom software
                development. With a variety of modern technologies and
                approaches, we are serving our clients with dependable
                solutions.
              </p>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
              <div className="tech-item tech-color-1">
                <img src="/images/tech-test-img-01.png" alt="" />
                <p>
                  <span>Artificial Intelligence</span>AI-based algorithms &
                  automated operation for scalable solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
              <div className="tech-item tech-color-2">
                <img src="/images/tech-test-img-02.png" alt="" />
                <p>
                  <span>Internet of Things</span>IoT devices to make a
                  real-time, efficient, and robust infrastructure.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="tech-item tech-color-3">
                <img src="/images/tech-test-img-03.png" alt="" />
                <p>
                  <span>Cloud Computing</span>Flexible, scalable & integrated
                  business solution.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="tech-item tech-color-4">
                <img src="/images/tech-test-img-04.png" alt="" />
                <p>
                  <span>Big Data</span>Increased business intelligence and
                  dependable data analysis.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3 push-right">
              <div className="tech-item tech-color-5">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-05.png"
                  alt=""
                />
                <p>
                  <span>BlockChain</span>Secured data, automated key operations,
                  detailed database, reduces cost, and increases efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="tech-item tech-color-6">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-06.png"
                  alt=""
                />
                <p>
                  <span>Augmented Reality</span>Greater & interactive user
                  experience with increased efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="tech-item tech-color-7">
                <img
                  className="tech-item__icon-size"
                  src="/images/tech-test-img-07.png"
                  alt=""
                />
                <p>
                  <span>Virtual Reality</span>Simulated user experience that can
                  be applied in education, entertainment, business, and many
                  other sectors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
     

      <ToolsAndTechnologies />
      <TellUsAbout />
      {/* <FAQ />
      <RelatedBlogArticles /> */}
      <Footer />
    </div>
  );
}
export default BiBigData;
