import { getAdsType } from '../actionTypes';
import { GET_ADS } from '../actionTypes/typeNames';

const adsReducerDefaultState = {
    loaded: false,
    cdn_path: 'https://s3-ap-southeast-1.amazonaws.com/www.thedoodleinc.com/uploads/',
    items: [],
};

export default (state = adsReducerDefaultState, action) => {
    
    switch (action.type) {
        case GET_ADS:
            return {
                ...state,
                loaded: true,
                items: [...action.payload],
            };
        default:
            return state;
    }
}
