import qs from 'qs';
import axios from 'axios';
import { ajaxConfig, objectToQueryString } from './../components/utils'
import { getAdsType } from "../actionTypes";


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['crossDomain'] = true;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// Actions

export const getAdsAction = () => {
    const endPoint = 'https://41rio69uff.execute-api.ap-southeast-1.amazonaws.com/stg_one/adblog';
    
    return dispatch => fetch(endPoint)
        .then(res => res.json())
        .then(data => dispatch(getAdsType(data)));
    
}
