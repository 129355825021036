import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SideVideo from './video/SideVideo'
import HeadlineSlider from './slider/HeadlineSlider'
import MenuSlider from './slider/MenuSlider'
import HomeLeftSlider from './slider/HomeLeftSlider'
import { GetHomeEn, GetHomeBn } from './../actions/homeAction';
import AOS from 'aos';
import Newsletter from './newsletter/Newsletter';
import Footer from './Footer';



class Industries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoName: ''
    }
  }
  componentDidMount = () => {
    // var element = document.getElementById("blogPage");
    // element.classList.remove("active");

    this.props.GetHomeEn().then(res => {
      //console.log(res[0].emp_name);

    })
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  }
  render() {
    return (
      <div>
      {/*-Banner section-*/}
      <section className="banner" style={{ background: 'url(images/services-banner.png) no-repeat bottom', backgroundSize: 'cover' }}>
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">Industries</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
        
      <section className="center-section pt-3 pb-3 section-black">
          <div className="container">
            <div className="row pt-3 pb-3 text-center">
              <div className="col-lg-5 ml-auto">
                <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                <div className="iso-icon">
                  <img src="images/ISO_icon01.png" alt="..." />
                </div>
                <div className="iso-icon">
                  <img src="images/ISO_icon02.png" alt="..." />
                </div>
              </div>
              <div className="col-lg-5 mobile-hide-only mr-auto">
                <div className="counter-item">
                  <img src="images/circle-img01.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>


      <section className="section-gray pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-lg-5 pr-sm-0 text-left">
                <h2>Expertise</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/blog6.jpg" alt="..." />
              </div>
            </div>
        </div>
      </section>
      
      <section className="center-section pt-5 pb-5 section-transparent">
        <div className="container">
          <div className="row mt-5 mb-5 text-block-2">
            <div className="col-lg-6 col-sm-12 h-block h-block-left">
              <img className="max-img" src="images/blog6.jpg" alt="..." />
              <div className="p-5 h-block-text text-right" data-aos="fade-right">
                <h3 className="font-weight-semibold font-asap"><span className="highlight-orange">Custom healthcare</span> solutions</h3>
                <p className="color-6 font-roboto font-weight-normal">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 h-block h-block-right">
              <img className="max-img" src="images/blog6.jpg" alt="..." />
              <div className="p-5 h-block-text text-left" data-aos="fade-left">
                <h3 className="font-weight-semibold font-asap"><span className="highlight-orange">Integration</span> with third-party systems</h3>
                <p className="color-6 font-roboto font-weight-normal">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
          </div>
        </div>
        <span className="effect-bg-top-left-round">
          <svg width={31} height={31} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4906 2.8739C21.5695 0.79387 18.0176 -0.208957 14.44 0.036291C10.8623 0.281539 7.48044 1.75969 4.8705 4.21888C2.26055 6.67807 0.584038 9.96615 0.126617 13.5229C-0.330805 17.0796 0.459166 20.6849 2.36193 23.7244C4.26469 26.764 7.16252 29.0498 10.5617 30.1923C13.9608 31.3348 17.6509 31.2633 21.0033 29.9901C24.3556 28.7169 27.1628 26.3207 28.9465 23.2098C30.7302 20.0989 31.3801 16.4657 30.7853 12.9293L27.1298 13.5441C27.5823 16.2347 27.0879 18.999 25.7308 21.366C24.3736 23.7329 22.2378 25.556 19.6872 26.5248C17.1365 27.4935 14.3289 27.5478 11.7427 26.6786C9.15645 25.8093 6.95165 24.0702 5.50394 21.7575C4.05623 19.4449 3.45518 16.7018 3.80321 13.9957C4.15124 11.2896 5.42681 8.78786 7.41258 6.9168C9.39835 5.04573 11.9715 3.92108 14.6935 3.73449C17.4155 3.54789 20.118 4.31089 22.3405 5.89347L24.4906 2.8739Z" fill="#FF8135" fillOpacity="0.2" />
          </svg>
        </span>
      </section>


      <section className="section-gray pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-5 text-left">
                <h2><span className="highlight-orange">Healthcare</span> solutions</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
              <div className="col-lg-5 mt-lg-0 mt-sm-5 text-center">
                <img className="max-img" src="images/blog5.jpg" alt="..." />
              </div>
            </div>
        </div>
      </section>
      

      
      <section className="center-section pt-5 pb-5 section-white">
        <div className="container">
            <div className="row section-title-top pt-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-12 pb-3 text-left">
                <h3>Digital patient experience</h3>
              </div>
            </div>
            <div className="row justify-content-center" data-aos="fade-down">
              <div className="col-lg-4 pr-4 text-left">
                <img className="max-img" src="images/blog5.jpg" alt="..." />
              </div>
              <div className="col-lg-8 mt-lg-0 mt-sm-5 justify-content-center text-left">
                <div className="row">
                  <div className="col-12 col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0 col- pl-4 pr-4">
                    <h4>Consumer health applications</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0 col- pl-4 pr-4">
                    <h4>Patient portals</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>



      <section className="section-white pt-5 pb-5 box-shadow">
        <div className="container">
            <div className="row section-title-top pt-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-12 pb-3 text-left">
                <h3>Clinical & health management</h3>
              </div>
            </div>
            <div className="row pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-4 pr-4 text-left">
                <img className="max-img" src="images/blog4.jpg" alt="..." />
              </div>
              <div className="col-lg-8 mt-lg-0 mt-sm-5 justify-content-center text-left">
                <div className="row">
                  <p className="pl-4 mt-5 mt-lg-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                  <div className="col-12 col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0 col- pl-4 pr-4">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text of the
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0 col- pl-4 pr-4">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>



      <section className="section-white pt-5 pb-5 box-shadow">
        <div className="container">
            <div className="row section-title-top pt-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-12 pb-3 text-left">
                <h3>Medical practice management & operations</h3>
              </div>
            </div>
            <div className="row pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-4 pr-4 text-left">
                <img className="max-img" src="images/blog3.jpg" alt="..." />
              </div>
              <div className="col-lg-8 mt-lg-0 mt-sm-5 justify-content-center text-left">
                <div className="row">
                  <p className="pl-4 mt-5 mt-lg-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                  <div className="col-12 col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0 col- pl-4 pr-4">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0 col- pl-4 pr-4">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      
      

      <section className="section-gray pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-7 mt-5 pt-5 pr-5 text-left">
                <h2><span className="highlight-orange">Digital</span> healthcare</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
              </div>
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/blog2.jpg" alt="..." />
              </div>
            </div>
        </div>
      </section>


      
      <section className="center-section pt-0 section-white">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12 mb-5 mb-lg-0 pl-sm-3 pl-lg-0 pr-lg-0 pr-sm-3 h-block1 h-block1-left">
              <img src="images/corporate.jpg" alt="" />
              <div className="pr-5 mt-4 mt-lg-0 float-left h-block1-text text-left" data-aos="fade-left">
                <span className="box-block-count font-size-50">01.</span>
                <h3 className="font-weight-semibold mt-3 mb-3 font-asap">Telemedicine</h3>
                <p className="color-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
            <div className="col-md-12 mt-lg-0 mb-5 mb-lg-0 pl-sm-3 pl-lg-0 pr-lg-0 pr-sm-3 h-block1 h-block1-right">
              <img src="images/corporate1.jpg" alt="" />
              <div className="pl-5 mt-4 mt-lg-0 float-right h-block1-text text-right" data-aos="fade-right">
                <span className="box-block-count font-size-50">02.</span>
                <h3 className="font-weight-semibold mt-3 mb-3 font-asap">Virtual & augmented reality</h3>
                <p className="color-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
            <div className="col-md-12 pl-sm-3 pl-lg-0 pr-lg-0 pr-sm-3 h-block1 h-block1-left">
              <img src="images/corporate2.jpg" alt="" />
              <div className="pr-5 mt-4 mt-lg-0 float-left h-block1-text text-left" data-aos="fade-left">
                <span className="box-block-count font-size-50">03.</span>
                <h3 className="font-weight-semibold mt-3 mb-3 font-asap">Medical image management & analysis</h3>
                <p className="color-6">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
          </div>
        </div>
        <span className="effect-bg-top-left" />
      </section>


      


      <section className="section-gray pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-5 text-left">
                <h2><span className="highlight-orange">Mobile</span> health</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/blog5.jpg" alt="..." />
              </div>
            </div>
        </div>
      </section>
      

      
      <section className="center-section pt-5 pb-5 section-white">
        <div className="container">
            <div className="row justify-content-center" data-aos="fade-down">
              <div className="col-lg-12 justify-content-center text-left">
                <div className="row">
                  <div className="col-12 col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0 pt-5 pb-5 pl-4 pr-4" style={{ background: 'url(images/application-doctor.jpg) no-repeat bottom right', backgroundSize: '30%' }}>
                    <h4>Mobile applications for doctors</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                      <li className="list-group-item pl-0 pr-0">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-6 mt-4 mt-lg-0 mb-4 mb-lg-0 pt-5 pb-5 pl-4 pr-4" style={{ background: 'url(images/application-patient.jpg) no-repeat bottom right', backgroundSize: '30%' }}>
                    <h4>Mobile applications for patients</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum is simply dummy text
                      </li>
                      <li className="list-group-item pl-0 pr-0"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi mr-3 bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                          <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/>
                        </svg> Lorem Ipsum
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>


      

      
      <section className="center-section section-black pt-0 pb-0 box-shadow"  style={{ background: 'url(images/contact-bg.png)' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 bg-black ex-pt">
              <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
                <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                  <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon01.png" alt="..." />
                  </div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon02.png" alt="..." />
                  </div>
                </div>
                <div className="col-lg-12 mr-auto">
                  <div className="counter-item">
                    <img src="images/circle-img01.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 bg-white ex-pt">
              <div className="row p-5 pl-lg-5 section-title-top" data-aos="fade-down">
                <div className="col-lg-12 pl-lg-5 text-left">
                  <h2>Tell Us About <span className="highlight-orange">Your Project</span></h2>
                </div>
                <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                  <form className="row">
                    <div className="form-group  mb-4 col-lg-6">
                      <label>Name</label>
                      <input type="text" className="form-control form-control-lg" placeholder="John Smith" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>E-mail</label>
                      <input type="email" className="form-control form-control-lg" placeholder="example@provider.com" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Phone</label>
                      <input type="phone" className="form-control form-control-lg" placeholder="+1 112 222 333" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Company</label>
                      <input type="text" className="form-control form-control-lg" placeholder="Ex: Pexel Inc." />
                    </div>
                    <div className="form-group mb-4 col-lg-12">
                      <label>Messages...</label>
                      <textarea className="form-control" placeholder="Hello..."></textarea>
                    </div>
                    <div className="form-group col-lg-12">
                      <button type="submit" className="btn btn-primary btn-lg"><span>Send Now</span></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*----------Footer---------*/}
      <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Industries)
