import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import homeReducer from '../reducers/homeReducer';
import adsReducer from '../reducers/adsReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__||compose;
// const composeEnhancers = compose; 

export default () => {
    const store = createStore(combineReducers({
        home : homeReducer,
        ads : adsReducer
    }), composeEnhancers(applyMiddleware(thunk)));

    return store;
};
