import React, { Component } from 'react';
import { validateEmail, getCookie, setCookie } from "../utils";
import { connect } from "react-redux";
import { saveSubscriberInfoAction } from "../../actions/homeAction";
import classes from './Newsletter.module.scss';

class NewsletterPopup extends Component {
  
  state = {
    email: '',
    name: '',
    profession: 0,
    errorMessage: '',
    saved: false,
    
    showPopup: false,
    cookieState: false
  };
  
  popShowOffset = 30; // %
  cookieName = 'subscription_pop';
  

  
  saveSubscriberInfo = e => {
    
    e.preventDefault();
    
    const { name, email, profession } = this.state;
    
    const emailValid = validateEmail(email);
    const nameValid = (name.length);
  
    let errorMsg = '';
    
    if (!nameValid) {
      errorMsg = 'Name field is required';
    }
    
    if (!email.length) {
      errorMsg = 'Email field is required';
      
    } else if (!emailValid) {
      errorMsg = 'Invalid email address';
    }
    
    
    this.setState({
      errorMessage: errorMsg
    });
  
    if (errorMsg.length <= 0) {
    
      const data = {name, email, profession};
    
      this.props.saveSubscribersData(data)
        .then(data => this.setState({
          name: '',
          email: '',
          profession: 0,
          errorMessage: '',
          saved: true
        }))
      // .catch(err => saved = false)
      ;
    
    }
    
  };
  
  emailChangeHandler = ({target}) => {

    this.setState({
      email: target.value
    })
  };

  nameChangeHandler = ({target}) => {
    this.setState({
      name: target.value
    })
  };
  
  professionChangeHandler = ({target}) => {
    this.setState({
      profession: target.value
    })
  };
  
  componentDidMount() {
    
    // Enable scroll event listening
    this.addScrollEvent();
    
    const cookie = getCookie(this.cookieName, this.props.cookies);
    
  
    // if cookie is not set or expired
    if (cookie) {
      
      this.setState({
        cookieState: true
      });
    }
  }
  
  addScrollEvent = () => {
    if (!this.state.showPopup) {
      window.addEventListener('scroll', this.handlePopupShow, {passive:true});
    }
  };
  
  removeScrollEvent = () => {
    window.removeEventListener('scroll', this.handlePopupShow, false);
  };
  
  calculateScrollHeigh = () => {
  
  };
  
  handlePopupShow = ({target}) => {
    const { scrollTop, scrollHeight } = target.scrollingElement;
  
    let desiredScrollPosition = scrollHeight * this.popShowOffset / 100;
    
    if (scrollTop > desiredScrollPosition && !this.state.cookieState) {
      this.setState({
        showPopup: true,
        cookieState: true
      }, () => this.removeScrollEvent())
    }
  }
  
  closePopup = () => {
  
    // set cookie again
    setCookie(this.cookieName, this.props.cookies, 1);
    
    this.setState({
      showPopup: false
    });
  }
  
  render() {
    
    const {
      name,
      email,
      showPopup,
      shownPopup,
      errorMessage,
      saved
    } = this.state;
    
  
    return ( showPopup && !shownPopup &&
      <div className="newsletter">
        <div className="popup-body">
          <button type="button" className="close pr-3" onClick={this.closePopup}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="row">
            <div className="left-side-popup col-md-6">
              <img src="images/work-slide3.jpg" alt="img" />
            </div>
            <div className="right-side-popup col-md-6 pl-5 pr-5 pt-5 text-center">
              <img src="images/new-logo-doodle.png" alt="Doodle" />
              <p className="mt-5 color-0 mb-4 font-size-14">Discover The Latest Technology News, Updates, Tips and
                Hacks Right To Your Inbox. Subscribe Now!</p>
  
              <span className={classes.errorMessage}>{errorMessage}</span>
              {
                saved && <span className={classes.successMessagePopup}>Your subscription has been saved</span>
              }
              
              <form onSubmit={this.saveSubscriberInfo}>
                <div className="input-group mb-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    value={name}
                    onChange={this.nameChangeHandler}
                    required
                  />
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    value={email}
                    onChange={this.emailChangeHandler}
                    required
                  />
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    onChange={this.professionChangeHandler}
                    value="1"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">Entrepreneur</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    onChange={this.professionChangeHandler}
                    value="2"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">Professional</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    onChange={this.professionChangeHandler}
                    value="3"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">Learner</label>
                </div>
                <div className="w-100 d-none d-md-block" />
                <button type="submit" className="btn btn-lg mt-4 mb-5 subscribe-btn pl-5 pr-5 btn-primary">
                  <span>Subscribe</span></button>
                <p className="color-6 font-size-14 line-height-20">Privacy Policy: We hate spam and promise to keep
                  your email address safe</p>
              </form>
            </div>
          </div>
        </div>
        <div className="newsletter-overlay"></div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  saveSubscribersData: data => dispatch(saveSubscriberInfoAction(data))
});

export default connect(null, mapDispatchToProps)(NewsletterPopup);
