import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SideVideo from './video/SideVideo'
import HeadlineSlider from './slider/HeadlineSlider'
import MenuSlider from './slider/MenuSlider'
import HomeLeftSlider from './slider/HomeLeftSlider'
import { GetHomeEn, GetHomeBn } from './../actions/homeAction';
import AOS from 'aos';
import Newsletter from './newsletter/Newsletter';
import Footer from './Footer';



class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoName: ''
    }
  }
  componentDidMount = () => {
    // var element = document.getElementById("blogPage");
    // element.classList.remove("active");

    this.props.GetHomeEn().then(res => {
      //console.log(res[0].emp_name);

    })
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  }
  render() {
    return (
      <div>
      {/*-Banner section-*/}
      <section className="banner" style={{ background: 'url(images/services-banner.png) no-repeat bottom', backgroundSize: 'cover' }}>
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">Enterprise Software Development</h1>
              </div>
              <p className="text-white">Services / <span className="dark-text">Enterprise Software Development</span></p>
            </div>
          </div>
          {/* <a className="mt-5 arrow-down mb-5" href="#makingtheworl">
            <svg width={20} height={37} viewBox="0 0 20 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1.63028C5.40498 1.63028 1.66667 5.28699 1.66667 9.78168V27.2186C1.66667 31.7133 5.40498 35.37 10 35.37C14.595 35.37 18.3333 31.7133 18.3333 27.2186V9.78168C18.3333 5.28699 14.595 1.63028 10 1.63028ZM10 0C15.5229 0 20 4.37941 20 9.78168V27.2186C20 32.6208 15.5229 37.0003 10 37.0003C4.47715 37.0003 0 32.6208 0 27.2186V9.78168C0 4.37941 4.47715 0 10 0Z" fill="white" />
              <path d="M10 15.3106C13.1218 15.3106 15.6525 12.8352 15.6525 9.78153C15.6525 6.7279 13.1218 4.25244 10 4.25244C6.87824 4.25244 4.34753 6.7279 4.34753 9.78153C4.34753 12.8352 6.87824 15.3106 10 15.3106Z" fill="white" />
            </svg>
          </a> */}
        </div>
      </section>
        
      <section className="center-section pt-3 pb-3 section-black">
          <div className="container">
            <div className="row pt-3 pb-3 text-center">
              <div className="col-lg-5 ml-auto">
                <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                <div className="iso-icon">
                  <img src="images/ISO_icon01.png" alt="..." />
                </div>
                <div className="iso-icon">
                  <img src="images/ISO_icon02.png" alt="..." />
                </div>
              </div>
              <div className="col-lg-5 mobile-hide-only mr-auto">
                <div className="counter-item">
                  <img src="images/circle-img01.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>

      <section className="section-drak-gray pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top mb-5 pt-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-12 text-center">
                <h2>Enterprise <span className="highlight-orange">Software</span> Development Services </h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
              </div>
            </div>
            <div className="row ml-0 mr-0 services-boxs mb-5">
              <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
                <img className="serviceimg-normal" src="/images/services-test-img.png" />
                <img className="serviceimg-hover" src="/images/services-test-img-hover.png" />
                <p className="font-roboto"><span>Custom Enterprise Software Development</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
                <span className="single-top-border"></span>
              </div>
              <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
                <img className="serviceimg-normal" src="/images/services-test-img.png" />
                <img className="serviceimg-hover" src="/images/services-test-img-hover.png" />
                <p className="font-roboto"><span>Enterprise Mobility Services</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
                <span className="single-top-border"></span>
              </div>
              <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
                <img className="serviceimg-normal" src="/images/services-test-img.png" />
                <img className="serviceimg-hover" src="/images/services-test-img-hover.png" />
                <p className="font-roboto"><span>Digital Transformation Services</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
                <span className="single-top-border"></span>
              </div>
              <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
                <img className="serviceimg-normal" src="/images/services-test-img.png" />
                <img className="serviceimg-hover" src="/images/services-test-img-hover.png" />
                <p className="font-roboto"><span>Data Management</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
                <span className="single-top-border"></span>
              </div>
              <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
                <img className="serviceimg-normal" src="/images/services-test-img.png" />
                <img className="serviceimg-hover" src="/images/services-test-img-hover.png" />
                <p className="font-roboto"><span>Software Integration</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
                <span className="single-top-border"></span>
              </div>
              <div className="col-lg-4 col-sm-6 e-item pt-5 pl-4 pb-5 pr-4">
                <img className="serviceimg-normal" src="/images/services-test-img.png" />
                <img className="serviceimg-hover" src="/images/services-test-img-hover.png" />
                <p className="font-roboto"><span>Legacy Application Modernization</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
                <span className="single-top-border"></span>
              </div>
            </div>
        </div>
      </section>
      <section className="section-white pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top mb-5 pt-5" data-aos="fade-down">
              <div className="col-lg-5 text-left">
                <h2>Emerging <span className="highlight-orange">Technologies</span> <br/>for Enterprise </h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
                <div className="tech-item tech-color-1">
                  <img src="/images/tech-test-img-01.png" />
                  <p><span>Artificial Intelligence</span>Lorem Ipsum is simply dummy text of the printing and </p>
                </div>
              </div>
              <div className="col-lg-3 mb-sm-4 mb-lg-0 col-sm-6">
                <div className="tech-item tech-color-2">
                  <img src="/images/tech-test-img-02.png" />
                  <p><span>Internet of Things</span>Lorem Ipsum is simply dummy text of the printing and </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="tech-item tech-color-3">
                  <img src="/images/tech-test-img-03.png" />
                  <p><span>Cloud Computing</span>Lorem Ipsum is simply dummy text of the printing </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="tech-item tech-color-4">
                  <img src="/images/tech-test-img-04.png" />
                  <p><span>Big Data</span>Lorem Ipsum is simply dummy text of the printing </p>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section className="center-section section-white box-shadow">
          <div className="container">
            <div className="row section-title-top justify-content-center" data-aos="fade-down">
              <div className="col-lg-12 text-center">
                <h2>Software Development <span className="highlight-orange">Lifecycle</span></h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

</p>
              </div>
            </div>
            <div className="row mt-5 pt-4">
              <div className="col-lg-4 d-inline-table text-center">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        1. Plan <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>

                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        2. Design <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        3.  Develop <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        4.  Test <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingSix">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        5.  Deploy <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingSeven">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        6.  Review <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingEight">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        7.  Launch <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 mt-lg-0 mt-sm-5 d-inline-table lifecycle text-center">
                <img src="/images/lifecycle.png" />
                <div className="step01">
                  <span className="stepleft">Deploy</span>
                  <span className="stepright">Test</span>
                </div>
                <div className="step02">
                  <span className="stepleft">Review</span>
                  <span className="stepright">Develop</span>
                </div>
                <div className="step03">
                  <span className="stepleft">Plan</span>
                  <span className="stepcenter">Design</span>
                  <span className="stepright">Launch</span>
                </div>
              </div>
            </div>
          </div>
      </section>
      
      <section className="center-section section-white  box-shadow">
        <div className="container">
          <div className="row section-title-top justify-content-center" data-aos="fade-down">
            <div className="col-lg-12 text-center">
              <h2><span className="highlight-orange">Tools & Technologies</span> We Use</h2>
              <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br /> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

</p>
            </div>
          </div>
          <div className="row mt-5 tools-sec">
            <div className="col-lg-6 col-sm-6 tools-2nd border-right-2 border-bottom-2 tools-color-02 border-radius-lt">
              <div className="tools-title">
                <img src="/images/small-watch.png" />
                <p>Languages</p>
              </div>
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">Java</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">Python</span>
                <span className="tools-btn-tag">Groovy</span>
                <span className="tools-btn-tag">Java</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">Objective C</span>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 tools-2nd tools-color-01 border-bottom-2 border-radius-rt">
              <div className="tools-title">
                <img src="/images/small-watch.png" />
                <p>Frameworks</p>
              </div>
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">Java</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">Python</span>
                <span className="tools-btn-tag">Groovy</span>
                <span className="tools-btn-tag">Java</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">C#</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 border-bottom-2 tools-color-01 border-radius-lb m-border-radius-lb">
              <div className="tools-title">
                <img src="/images/small-watch.png" />
                <p>Mobile</p>
              </div>
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">Objective C</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 m-border-right-2 tools-color-02 border-bottom-2">
              <div className="tools-title">
                <img src="/images/small-watch.png" />
                <p>Web</p>
              </div>
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">Objective C</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd border-right-2 tools-color-03 border-bottom-2 m-show-border-radius-lb">
              <div className="tools-title">
                <img src="/images/small-watch.png" />
                <p>Database Management</p>
              </div>
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">Objective C</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 tools-2nd tools-color-04 border-bottom-2 border-radius-rb">
              <div className="tools-title">
                <img src="/images/small-watch.png" />
                <p>Cloud</p>
              </div>
              <div className="tools-tag mt-4">
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">Objective C</span>
                <span className="tools-btn-tag">C/C++</span>
                <span className="tools-btn-tag">C#</span>
                <span className="tools-btn-tag">Objective C</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section className="center-section section-black pt-0 pb-0 box-shadow"  style={{ background: 'url(images/contact-bg.png)' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 bg-black ex-pt">
              <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
                <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                  <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon01.png" alt="..." />
                  </div>
                  <div className="iso-icon">
                    <img src="images/ISO_icon02.png" alt="..." />
                  </div>
                </div>
                <div className="col-lg-12 mr-auto">
                  <div className="counter-item">
                    <img src="images/circle-img01.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 bg-white ex-pt">
              <div className="row p-5 pl-lg-5 section-title-top" data-aos="fade-down">
                <div className="col-lg-12 pl-lg-5 text-left">
                  <h2>Tell Us About <span className="highlight-orange">Your Project</span></h2>
                </div>
                <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                  <form className="row">
                    <div className="form-group  mb-4 col-lg-6">
                      <label>Name</label>
                      <input type="text" className="form-control form-control-lg" placeholder="John Smith" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>E-mail</label>
                      <input type="email" className="form-control form-control-lg" placeholder="example@provider.com" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Phone</label>
                      <input type="phone" className="form-control form-control-lg" placeholder="+1 112 222 333" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Company</label>
                      <input type="text" className="form-control form-control-lg" placeholder="Ex: Pexel Inc." />
                    </div>
                    <div className="form-group mb-4 col-lg-12">
                      <label>Messages...</label>
                      <textarea className="form-control" placeholder="Hello..."></textarea>
                    </div>
                    <div className="form-group col-lg-12">
                      <button type="submit" className="btn btn-primary btn-lg"><span>Send Now</span></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section className="center-section section-white box-shadow">
          <div className="container">
            <div className="row section-title-top" data-aos="fade-down">
              <div className="col-lg-5 text-left">
                <h2>F.A.Q.</h2>
                <p className="color-7 pr-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
              <div className="col-lg-7 faq-cls">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne1">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                        Lorem Ipsum is simply dummy text of the?
 <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>

                    <div id="collapseOne1" className="collapse" aria-labelledby="headingOne1" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo1">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1">
                        Lorem Ipsum is simply dummy text of the? <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo1" className="collapse" aria-labelledby="headingTwo1" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree1">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                        Lorem Ipsum is simply dummy text of the? <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree1" className="collapse" aria-labelledby="headingThree1" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive1">
                      <h2 className="mb-0">
                        <button className="btn pl-0 pr-0 btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive1" aria-expanded="false" aria-controls="collapseFive1">
                        Lorem Ipsum is simply dummy text of the? <i class="fas fa-angle-down"></i>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseFive1" className="collapse" aria-labelledby="headingFive1" data-parent="#accordionExample">
                      <div className="card-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      
      
      <section className="center-section section-white box-shadow">
        <div className="container">
          <div className="row section-title-top" data-aos="fade-down">
            <div className="col-lg-12 text-center">
              <h2>Related Blog Articles</h2>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img.png" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">Lorem Ipsum is simply dummy text</h3>
              </a>
            </div>
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img1.png" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">Lorem Ipsum is simply dummy text</h3>
              </a>
            </div>
            <div className="col-lg-4 text-left">
              <a className="blog-link" href="#">
                <div className="image-box-blog">
                  <img src="/images/r-blog-img2.png" />
                </div>
                <p className="ctg-cls-blog mt-3">Engineering</p>
                <h3 className="title-cls-blog">Lorem Ipsum is simply dummy text</h3>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/*----------Footer---------*/}
      <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Services)
