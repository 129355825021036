import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SideVideo from './video/SideVideo'
import HeadlineSlider from './slider/HeadlineSlider'
import MenuSlider from './slider/MenuSlider'
import HomeLeftSlider from './slider/HomeLeftSlider'
import { GetHomeEn, GetHomeBn } from './../actions/homeAction';
import AOS from 'aos';
import Newsletter from './newsletter/Newsletter';
import Footer from './Footer';



class Certifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoName: ''
    }
  }
  componentDidMount = () => {
    // var element = document.getElementById("blogPage");
    // element.classList.remove("active");

    this.props.GetHomeEn().then(res => {
      //console.log(res[0].emp_name);

    })
    AOS.init({
      // initialise with other settings
      //duration : 2000
    });
  }
  render() {
    return (
      <div>
      {/*-Banner section-*/}
      <section className="banner" style={{ background: 'url(images/services-banner.png) no-repeat bottom', backgroundSize: 'cover' }}>
        <div className="services-banner-container">
          <div className="banner-title-section row">
            <div className="col-lg-12 text-center">
              <div className="text-wrapper">
                <h1 className="text-center services-banner-title text-white mt-laptop-0 pt-2">Award and Certifications</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
        
      <section className="center-section pt-3 pb-3 section-black">
          <div className="container">
            <div className="row pt-3 pb-3 text-center">
              <div className="col-lg-5 ml-auto">
                <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
               
              </div>
              <div className="col-lg-5 mobile-hide-only mr-auto">
                <div className="counter-item">
                  <img src="images/circle-img01.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                  </div>
                </div>
                <div className="counter-item">
                  <img src="images/circle-img02.png" alt="..." />
                  <div className="cn_01">
                    <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>


      <section className="section-gray pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/microsoft-certified-partner.png" alt="..." />
              </div>
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-lg-5 pr-sm-0 text-left">
                <h2>Microsoft Gold Partner</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
        </div>
      </section>

      <section className="section-white pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-lg-5 pr-sm-0 text-left">
                <h2>Microsoft Gold Partner</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/microsoft-certified-partner.png" alt="..." />
              </div>
            </div>
        </div>
      </section>


      <section className="section-gray pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/microsoft-certified-partner.png" alt="..." />
              </div>
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-lg-5 pr-sm-0 text-left">
                <h2>Microsoft Gold Partner</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
        </div>
      </section>

      <section className="section-white pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-lg-5 pr-sm-0 text-left">
                <h2>Microsoft Gold Partner</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/microsoft-certified-partner.png" alt="..." />
              </div>
            </div>
        </div>
      </section>


      <section className="section-gray pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/microsoft-certified-partner.png" alt="..." />
              </div>
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-lg-5 pr-sm-0 text-left">
                <h2>Microsoft Gold Partner</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
            </div>
        </div>
      </section>

      <section className="section-white pt-5 pb-5">
        <div className="container">
            <div className="row section-title-top pt-5 pb-5 justify-content-center" data-aos="fade-down">
              <div className="col-lg-7 mt-lg-5 mt-sm-0 pt-lg-5 pt-sm-0 pr-lg-5 pr-sm-0 text-left">
                <h2>Microsoft Gold Partner</h2>
                <p className="color-7">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
              </div>
              <div className="col-lg-5 text-center">
                <img className="max-img" src="images/microsoft-certified-partner.png" alt="..." />
              </div>
            </div>
        </div>
      </section>
      

      

      
      <section className="center-section section-black pt-0 pb-0 box-shadow"  style={{ background: 'url(images/contact-bg.png)' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 bg-black ex-pt">
              <div className="row pt-sm-0 pt-lg-5 mt-sm-0 mt-lg-5 pb-3 text-center">
                <div className="col-lg-12 mt-lg-5 mt-sm-0 mb-5">
                  <div className="award-text"><span className="award-type">Award</span> <span className="award-and">&</span> Certifications</div>
                  
                </div>
                <div className="col-lg-12 mr-auto">
                  <div className="counter-item">
                    <img src="images/circle-img01.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_01">9</span><span className="c_text_01">YEARS</span>IN OPERATION</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">100s</span><span className="c_text_01">OF CUSTOMERS</span>WORLDWIDE</p>
                    </div>
                  </div>
                  <div className="counter-item">
                    <img src="images/circle-img02.png" alt="..." />
                    <div className="cn_01">
                      <p><span className="c_number_02">4.9/5</span><span className="c_text_01">CUSTOMERS</span>RATING</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 bg-white ex-pt">
              <div className="row p-5 pl-lg-5 section-title-top" data-aos="fade-down">
                <div className="col-lg-12 pl-lg-5 text-left">
                  <h2>Tell Us About <span className="highlight-orange">Your Project</span></h2>
                </div>
                <div className="contact-form col-lg-12 pb-0 pl-lg-5 border-0">
                  <form className="row">
                    <div className="form-group  mb-4 col-lg-6">
                      <label>Name</label>
                      <input type="text" className="form-control form-control-lg" placeholder="John Smith" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>E-mail</label>
                      <input type="email" className="form-control form-control-lg" placeholder="example@provider.com" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Phone</label>
                      <input type="phone" className="form-control form-control-lg" placeholder="+1 112 222 333" />
                    </div>
                    <div className="form-group mb-4 col-lg-6">
                      <label>Company</label>
                      <input type="text" className="form-control form-control-lg" placeholder="Ex: Pexel Inc." />
                    </div>
                    <div className="form-group mb-4 col-lg-12">
                      <label>Messages...</label>
                      <textarea className="form-control" placeholder="Hello..."></textarea>
                    </div>
                    <div className="form-group col-lg-12">
                      <button type="submit" className="btn btn-primary btn-lg"><span>Send Now</span></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*----------Footer---------*/}
      <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  GetHomeEn: () => dispatch(GetHomeEn()),
  GetHomeBn: () => dispatch(GetHomeBn()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Certifications)
