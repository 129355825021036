import React from "react";

function UsesTechnologies() {
  return (
    <section className="section-white addskill pb-5">
      <div className="container">
        <div className="row section-title-top mb-5 pt-5" data-aos="fade-down">
          <div className="col-lg-12 text-left">
            <h2>Backend</h2>
            <p className="color-7">
              Tackle development difficulties by enhancing the backend
              performance of the application. Enjoy easy synchronization of
              different features and steady communication with relational or No
              SQL database
            </p>
            <div className="tags_btn mb-4 mt-4">
              <button type="button" className="btn btn-light tag-btn">
                JAVA
              </button>
              <button type="button" className="btn btn-light tag-btn">
                NodeJs
              </button>
              <button type="button" className="btn btn-light tag-btn">
                PHP
              </button>
              <button type="button" className="btn btn-light tag-btn">
                Python
              </button>
              <button type="button" className="btn btn-light tag-btn">
                Swift
              </button>
              <button type="button" className="btn btn-light tag-btn">
                Kotlin
              </button>
              <button type="button" className="btn btn-light tag-btn">
                Objective C
              </button>
              <button type="button" className="btn btn-light tag-btn">
                Larvel
              </button>
              <button type="button" className="btn btn-light tag-btn">
                CodeIgniter
              </button>
              <button type="button" className="btn btn-light tag-btn">
                Django
              </button>
              <button type="button" className="btn btn-light tag-btn">
                Express
              </button>
            </div>
            <ul
              className="list-group row ml-0 mr-0 d-list-img-1 mid-img-block list-group-horizontal aos-init aos-animate"
              data-aos="fade-up"
            >
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/java.png" alt="js" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/nodeJS.png" alt="php" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/php-codeigniter.png" alt="codeigniter" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/python.png" alt="C#" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/swift.png" alt="java" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/Kotlin.png" alt="php" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/objectiveC.png" alt="python" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/Laravel.png" alt="C#" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/Codeigniter.png" alt="php" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/Django.png" alt="python" />
              </li>
              <li className="list-group-item text-center pt-3 pb-3">
                <img src="images/technologies/Expressjs.png" alt="C#" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
export default UsesTechnologies;
