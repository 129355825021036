import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

const Pagination = ({ blogPerPage, totalBLogs, paginate, active }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalBLogs / blogPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav aria-label="...">
      <ul className="pagination pagination-sm">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={active == number ? "page-item active" : "page-item"}
          >
            {active == number ? (
              <span className="page-link">
                {number}
                <span className="sr-only">(current)</span>
              </span>
            ) : (
                <Link
                  onClick={() => paginate(number)}
                  className="page-link"
                  href="!#"
                >
                  {number}
                </Link>
              )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default Pagination;
